import styled from "styled-components";

interface StylesheetProps {
  isMobile: boolean;
  isBlocked: boolean;
}

const className = "vehicle-block__form";

const VehicleBlockForm = styled.div.attrs(
  (props) => ({ ...props, className, id: className })
)<StylesheetProps>`

  .form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
  }

  .container-vehicle-block-form__justify {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22rem;
  }

  .container-vehicle-block-form__password {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
  }

  .container-vehicle-block-form__slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    width: 20rem;

    .vehicle-block-form-slider__icons {
      display: flex;
      justify-content: space-between;
      width: 22rem;
    }
  }

  .MuiSlider-thumb {
    color: ${(props) => (props.isBlocked ? "#00AD00" : "#F00")};
    border: 4px solid currentColor;
  }

  .MuiSlider-rail {
    color: #BEBEBE;
    height: 3px;
  }

  .MuiSlider-track {
    color: ${(props) => (props.isBlocked ? "#00AD00" : "#F00")};
    height: 3px;
  }

  .vehicle-block-form__instruction {
    display: flex;
    line-height: 1.4;
    align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
    gap: 0.2rem;

    .strong {
      color: ${(props) => (props.isBlocked ? "#00AD00" : "#F00")};
    }
  }

  .block_button {
    color: white;
    background-color: ${(props) => (props.isBlocked ? "#00AD00" : "#FF0000")};
    border-radius: 8px;

    svg {
      color: inherit;
    }

    :disabled {
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
    }
  }

`;

export { VehicleBlockForm };
