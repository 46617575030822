import {
  GroupsModuleMessages,
  TranslationGroupsModuleMessages
} from "../interfaces";

export const messages: TranslationGroupsModuleMessages = {

  [GroupsModuleMessages.group]: "Grupo",
  [GroupsModuleMessages.name]: "Nombre",
  [GroupsModuleMessages.noNameRegistered]: "Sin nombre registrado",
  [GroupsModuleMessages.active]: "Activo",
  [GroupsModuleMessages.inactive]: "Inactivo",
  [GroupsModuleMessages.externalId]: "ID Externo",
  [GroupsModuleMessages.registrationDate]: "Fecha de registro",
  [GroupsModuleMessages.updatedDate]: "Fecha de actualización",
  [GroupsModuleMessages.code]: "Código",
  [GroupsModuleMessages.deleteConfirmationText]: "¿Estás seguro de que deseas eliminar el grupo?",
  [GroupsModuleMessages.deleteRegionalError]: "Couldn't delete the region",

  [GroupsModuleMessages.deleteGroupSuccess]: "Grupo eliminado exitosamente",
  [GroupsModuleMessages.updateGroupSuccess]: "Grupo actualizado exitosamente",
  [GroupsModuleMessages.updateGroupError]: "No se puede actualizar el grupo",
  [GroupsModuleMessages.readGroupSuccess]: "Grupo(s) retornado(s) con éxito",
  [GroupsModuleMessages.noGroupFound]: "No se encontró ningún grupo",
  [GroupsModuleMessages.createGroupSuccess]: "Grupo creado con éxito",
  [GroupsModuleMessages.createGroupError]: "No se puede crear el grupo"

};
