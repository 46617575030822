import { Reducer } from "redux";
import { AlertsTypes, AlertsState } from "./alerts.type";

const INITIAL_STATE: AlertsState = {
  all: {
    list: [],
    loading: true
  },
  filtered: {
    list: [],
    loading: true
  }
};

const reducer: Reducer<AlertsState> = (state = INITIAL_STATE, action) => {

  switch (action.type) {
  case AlertsTypes.SET_ALERTS:
    return { ...INITIAL_STATE, ...action.payload };
  case AlertsTypes.REMOVE_ALERTS:
    return {
      ...INITIAL_STATE,
      ...action.payload,
      list: state.all.list.filter((item) => !action?.payload?.list.map((cv) => cv.id_alert).includes(item.id_alert))
    };
  case AlertsTypes.SET_ALERTS_FILTERED:
    return { ...state, filtered: action.payload };
  default:
    return state;
  }
};

export default reducer;
