import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  .MuiAccordionSummary-root {
    .Mui-expanded {
      .accordion-summary-chips {
        visibility: hidden !important;
        opacity: 0 !important;
        position: absolute;
        top: 30px;
      }
    }
  }
`;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  transition: all 0.1s ease-in-out;
  position: relative;
  visibility: visible;

  .MuiChip-colorSuccess {
    background-color: ${(props) => props.theme.colors.secondary} !important;
  }
`;
const AccordionDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;

  .form-control_values {
    flex-grow: 1;
  }

  .MuiFormGroup-root {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    overflow: auto;

    &.Mui-error {
      border: 1px solid red !important;
    }
  }
`;

export { Container, Chips, AccordionDetail };
