import { Reducer } from "redux";
import { ScreenState, ScreenTypes } from "./screen.type";

const INITIAL_STATE: ScreenState = {
  width: 0,
  height: 0,
  platform: undefined,
  size: undefined
};

const reducer: Reducer<ScreenState> = (state = INITIAL_STATE, action) => {

  switch (action.type) {
  case ScreenTypes.SET_SCREEN_SIZE:
    return { ...INITIAL_STATE, ...action.payload };
  default:
    return state;
  }
};

export default reducer;
