import { RegionalsModuleMessages, TranslationRegionalsModuleMessages } from "../interfaces";

export const messages: TranslationRegionalsModuleMessages = {

  [RegionalsModuleMessages.name]: "Nombre",
  [RegionalsModuleMessages.noNameRegistered]: "Sin nombre registrado",
  [RegionalsModuleMessages.active]: "Activo",
  [RegionalsModuleMessages.inactive]: "Inactivo",
  [RegionalsModuleMessages.externalId]: "ID Externo",
  [RegionalsModuleMessages.registrationDate]: "Fecha de registro",
  [RegionalsModuleMessages.updatedDate]: "Fecha de actualización",
  [RegionalsModuleMessages.code]: "Código",
  [RegionalsModuleMessages.deleteConfirmationText]: "¿Estás seguro de que deseas eliminar la regional?",
  [RegionalsModuleMessages.deleteRegionalError]: "No se puede eliminar la regional",

  [RegionalsModuleMessages.updateRegionalSuccess]: "Regional actualizada exitosamente",
  [RegionalsModuleMessages.updateRegionalError]: "No se puede actualizar la regional",
  [RegionalsModuleMessages.readRegionalSuccess]: "Regional(s) retornada(s) exitosamente",
  [RegionalsModuleMessages.noRegionalFound]: "No se encontró ninguna regional",
  [RegionalsModuleMessages.createRegionalSuccess]: "Regional creada con éxito",
  [RegionalsModuleMessages.createRegionalError]: "No se puede crear regional",
  [RegionalsModuleMessages.deleteRegionalSuccess]: "Regional eliminada exitosamente"

};
