import { DataTableMessages, TranslationDataTableMessages } from "../interfaces";

export const messages: TranslationDataTableMessages = {
  [DataTableMessages.status]: "Status",
  [DataTableMessages.actions]: "Actions",
  [DataTableMessages.buttonsAddNew]: "Add new",
  [DataTableMessages.buttonsExport]: "Export",
  [DataTableMessages.buttonsPrint]: "Print",
  [DataTableMessages.buttonsRefresh]: "Refresh",
  [DataTableMessages.emptyTable]: "No records found",
  [DataTableMessages.info]: "Showing _START_ to _END_ of _TOTAL_ records",
  [DataTableMessages.infoEmpty]: "Showing 0 to 0 of 0 records",
  [DataTableMessages.infoFiltered]: "(Filtered from _MAX_ records)",
  [DataTableMessages.lengthMenu]: "_MENU_ records per page",
  [DataTableMessages.loadingRecords]: "Loading...",
  [DataTableMessages.processing]: "Processing...",
  [DataTableMessages.zeroRecords]: "No records found",
  [DataTableMessages.search]: "Search...",
  [DataTableMessages.searchPlaceholder]: "Filter data on screen...",
  [DataTableMessages.filterTitle]: "Filters",
  [DataTableMessages.filterApplied]: "Filters applied to",
  [DataTableMessages.filterNoneApplied]: "No filters applied",
  [DataTableMessages.filterCorrespondence]: "Matching all these filters",
  [DataTableMessages.filterAdd]: "Add filter",
  [DataTableMessages.filterRemoveAll]: "Remove all",
  [DataTableMessages.filterApply]: "Apply filter",
  [DataTableMessages.filterNewFilterField]: "New filter field",
  [DataTableMessages.filterOperatorEquals]: "Equals",
  [DataTableMessages.filterOperatorNotEquals]: "Not equals",
  [DataTableMessages.filterOperatorContains]: "Contains",
  [DataTableMessages.filterOperatorNotContains]: "Not contains",
  [DataTableMessages.filterOperatorStartsWith]: "Starts with",
  [DataTableMessages.filterOperatorGreaterThan]: "Greater than",
  [DataTableMessages.filterOperatorLessThan]: "Less than",
  [DataTableMessages.filterOperatorGreaterThanOrEqual]: "Greater than or equal",
  [DataTableMessages.filterOperatorLessThanOrEqual]: "Less than or equal",
  [DataTableMessages.filterProperty]: "Property",
  [DataTableMessages.filterOperator]: "Operator",
  [DataTableMessages.filterValue]: "Value",
  [DataTableMessages.moreRecords]: "Load more records",
  [DataTableMessages.buttonsViewRoles]: "Roles",
  [DataTableMessages.reportDriverRolesLabel]: "driver_report_roles"
};
