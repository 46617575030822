import { Reducer } from "redux";
import { VehiclesStatesState, VehiclesStatesTypes } from "./vehicles-states.type";

const INITIAL_STATE: VehiclesStatesState = {
  all: {
    list: {
      "Em local confiavel": [],
      "Na usina": [],
      "Carregando": [],
      "A caminho": [],
      "Em obra": [],
      "Descarregando": [],
      "Retornando": []
    },
    loading: true
  },
  filtered: {
    list: {
      "Em local confiavel": [],
      "Na usina": [],
      "Carregando": [],
      "A caminho": [],
      "Em obra": [],
      "Descarregando": [],
      "Retornando": []
    },
    loading: true
  }
};

const reducer: Reducer<VehiclesStatesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case VehiclesStatesTypes.SET_VEHICLES_STATES:
    return { ...INITIAL_STATE, ...action.payload };
  case VehiclesStatesTypes.SET_VEHICLES_STATES_ALL:
    return { ...state, all: action.payload };
  case VehiclesStatesTypes.SET_VEHICLES_STATES_FILTERED:
    return { ...state, filtered: action.payload };
  default:
    return state;
  }
};

export default reducer;
