export enum ManagementMessages {
  allowedChangeParams = "management.allowedChangeParams",
  allowedChangeVehicles = "management.allowedChangeVehicles",
  allowedChangeDrivers = "management.allowedChangeDrivers",
  allowedChangeLocations = "management.allowedChangeLocations",
  functionalityGroup = "management.functionalityGroup",
  alertVisualizationGroup = "management.alertVisualizationGroup",
  dataTableName = "management.dataTableName",
  dataTableRegisterDate = "management.dataTableRegisterDate",
  dataTableLastAccess = "management.dataTableLastAccess",
  dataTableEmail = "management.dataTableEmail",
  dataTableCode = "management.dataTableCode",
  detailsActiveUser = "management.detailsActiveUser",
  detailsDailyReport = "management.detailsDailyReport",
  detailsWeeklyReport = "management.detailsWeeklyReport",
  detailsFullname = "management.detailsFullname",
  detailsEmail = "management.detailsEmail",
  detailsAdmin = "management.detailsAdmin",
  detailsAuthorizedPlants = "management.detailsAuthorizedPlants",
  detailsPassword = "management.detailsPassword",
  detailsConfirmPassword = "management.detailsConfirmPassword",
  detailsTelegramId = "management.detailsTelegramId",
  detailsPermissions = "management.detailsPermissions",
  detailsResendEmail = "management.detailsResendEmail",
  detailsTelegramIdInfo = "management.detailsTelegramIdInfo",
  detailsTelegramError = "management.detailsTelegramError",
  detailsHeaderRegister = "management.detailsHeaderRegister",
  deleteConfirmationText = "management.deleteConfirmationText",
  deleteConfirmationTitle = "management.deleteConfirmationTitle",
  validationTitle = "management.validationTitle",
  validationText = "management.validationText",
  validationConfirmation = "management.validationConfirmation",
  formEmailRegistered = "management.formEmailRegistered",
  formRequiredName = "management.formRequiredName",
  formRequiredEmail = "management.formRequiredEmail",
  formValidEmail = "management.formValidEmail",
  formRequiredPlant = "management.formRequiredPlant",
  formValidPassword = "management.formValidPassword",
  formValidConfirmPassword = "management.formValidConfirmPassword",
  formEqualsPassword = "management.formEqualsPassword",
  detailsDriverRegistrationPlant = "management.detailsDriverRegistrationPlant"
}

export type TranslationManagementMessages = {
  [key in ManagementMessages]: string;
}
