import * as dateFns from "date-fns";
import * as Yup from "yup";
import * as _ from "lodash";
import * as documentValidator from "cpf-cnpj-validator";

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";
import InputMask from "react-input-mask";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControlLabel, FormLabel, Grid, 
  Radio, 
  RadioGroup, 
  Tab, 
  Tabs} from "@material-ui/core";

import { Checkbox, TextField } from "unform-material-ui";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { Driver } from "@shared/interfaces/driver.interface";
import { DriversRoles } from "@shared/interfaces/drivers-roles.interface";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Hardware } from "@shared/interfaces/hardware.interface";
import { HardwareTypes } from "@shared/constants/hardware-types.enum";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import utils from "@utils/useful-functions";
import { useSelector } from "react-redux";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  DriverModuleMessages,
  GlobalMessages,
  LocationsModuleMessages,
  ManagementMessages,
  MenuMessages,
  ToastMessages
} from "@shared/languages/interfaces";
import { useAuth } from "@hooks/useAuth";
import api from "../../../services/api";
import getValidationErrors from "../../../hooks/getValidationErrors";
import { useToast } from "../../../hooks/useToast";

import Header from "../../../components/Header";
import ButtonTable from "../../../components/Button/ButtonTable";
import DialogConfirmAction from "../../../components/Dialog/ConfirmAction";

import { Container, ContainerModalFormDriver, ContainerModalFormDriverRoles } from "./styles";
import TabPanel from "@components/TabPanel";
import usePersistedState from "src/hooks/usePersistedState";
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { Group } from "@shared/entities/reg_groups.entity";
import { Regional } from "@shared/entities/reg_regionals.entity";
import LimitedChipsField from "src/atoms/LimitedChipsField";
import { User } from "@shared/interfaces/user.interface";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Plant } from "@shared/entities/reg_plants.entity";

interface ValidateFields { cpf: boolean; re: boolean; }
interface ValidateFieldsFormDriverRoles { description: boolean; }

const Drivers: React.FC = () => {

  /* region Hooks */
  const { addToast } = useToast();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const { screen } = useSelector((screen) => screen);
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  /* endregion Hooks */

  /* region States */
  const [loadingDriverList, setLoadingDriverList] = useState(false);
  const [loadingValidateFieldCpf, setLoadingValidateFieldCpf] = useState(false);
  const [loadingValidateFieldRe, setLoadingValidateFieldRe] = useState(false);
  const [openOptionsIbuttons, setOpenOptionsIbuttons] = useState(false);
  const [optionsIbuttons, setOptionsIbuttons] = useState<Hardware[]>([] as Hardware[]);
  const loadingOptionsIbuttons = openOptionsIbuttons && optionsIbuttons.length === 0;
  const [activeOptionIbutton, setActiveOptionIbutton] = useState<Hardware | null>(null);

  const [drivers, setDrivers] = useState<Driver[]>([] as Array<Driver>);
  const [driverDetails, setDriverDetails] = useState<Driver>({} as Driver);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [openModalFormDriver, setOpenModalFormDriver] = useState(false);
  
  const [modalType, setModalType] = useState<"details" | "update" | "register" >("details");

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);

  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(true);
  const [validateFields, setValidateFields] = useState<ValidateFields>({ cpf: false, re: false });
  
  const [modalRolesType, setModalRolesType] = useState<"details" | "update" | "register" >("details");
  const [openModalFormDriverRoles, setOpenModalFormDriverRoles] = useState(false);
  const [loadingDriverRolesList, setLoadingDriverRolesList] = useState(false);
  const [driversRoles, setDriversRoles] = useState<DriversRoles[]>([] as Array<DriversRoles>);
  const [driverRolesDetails, setDriverRolesDetails] = useState<DriversRoles>({} as DriversRoles);
  const [tableDriverRoles, setTableDriverRoles] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableDriverRolesClickedRow, setTableDriverRolesClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);
  const [validateFieldsDriverRoles, setValidateFieldsDriverRoles] = useState<ValidateFieldsFormDriverRoles>({ description: false });
  const [loadingValidateFieldDescription, setLoadingValidateFieldDescription] = useState(false);
  const [openDialogDriverRolesConfirmDelete, setOpenDialogDriverRolesConfirmDelete] = useState(false);
  const [activeDriverRolesCheckbox, setActiveDriverRolesCheckbox] = useState<boolean | undefined>(true);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [dynamicTabs, setDynamicTabs] = useState([
    { label: t(DriverModuleMessages.detailsDriverRolesTitle), disableRipple: true },
    { label: t(DriverModuleMessages.newDriversRoles), disableRipple: true }
  ]);
  const [openOptionsDriversRoles, setOpenOptionsDriversRoles] = useState(false);
  const [optionsDriversRoles, setOptionsDriversRoles] = useState<DriversRoles[]>([]);
  const [loadingOptionsDriversRoles, setLoadingOptionsDriversRoles] = useState(false);
  const [activeOptionDriversRoles, setActiveOptionDriversRoles] = useState<DriversRoles | null>(null);

  const [openOptionsGroup, setOpenOptionsGroup] = useState(false);
  const [optionsGroup, setOptionsGroup] = useState<Group[]>([]);
  const [loadingOptionsGroup, setLoadingOptionsGroup] = useState(false);
  const [activeOptionGroup, setActiveOptionGroup] = useState<Group | null>(null);

  const [openOptionsRegional, setOpenOptionsRegional] = useState(false);
  const [optionsRegional, setOptionsRegional] = useState<Regional[]>([]);
  const [loadingOptionsRegional, setLoadingOptionsRegional] = useState(false);
  const [activeOptionRegional, setActiveOptionRegional] = useState<Regional | null>(null);

  const [openOptionsUser, setOpenOptionsUser] = useState(false);
  const [optionsUser, setOptionsUser] = useState<User[]>([]);
  const [loadingOptionsUser, setLoadingOptionsUser] = useState(false);
  const [activeOptionUser, setActiveOptionUser] = useState<User[] | null>(null);

  const [activeOwnership, setActiveOwnership] = useState<"companyDriver" | "thirdPartyDriver">("companyDriver");
  
  const [openOptionsPlants, setOpenOptionsPlants] = useState(false);
  const [optionsPlants, setOptionsPlants] = useState<Plant[]>([]);
  const [loadingOptionsPlants, setLoadingOptionsPlants] = useState(false);
  const [activeOptionsPlants, setActiveOptionsPlants] = useState<Plant | null>(null);
  /* endregion States */
  /* region Form Validation */
  const formRef = useRef<FormHandles>(null);
  const formRefRoles = useRef<FormHandles>(null);
  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateCpf: async (cpf: string) => {

      setValidateFields({ ...validateFields, cpf: false });

      // CPF completed
      if (cpf.length === 14 && cpf !== driverDetails.cpf) {

        try {

          setLoadingValidateFieldCpf(true);

          // Verify if CPF is valid
          if (!documentValidator.cpf.isValid(cpf)) formRef.current?.setFieldError("cpf", t(DriverModuleMessages.invalidCPF));
          else {

            // Verify if CPF is already registered
            const { data } = await api.get(`drivers/verify-unique-field/cpf/${cpf}`);

            if (data.status === "alert") formRef.current?.setFieldError("cpf", t(DriverModuleMessages.invalidUniqueCPF));
            else formRef.current?.setFieldError("cpf", "");

          }

          setValidateFields({ ...validateFields, cpf: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldCpf(false);
        }

      } else formRef.current?.setFieldError("cpf", "");

    },
    validateRe: async (re: string) => {

      setValidateFields({ ...validateFields, re: false });

      // Validate re just have 3 or more characters in on blur
      if (re.trim().length >= 3 && re.trim() !== driverDetails.re) {

        try {

          setLoadingValidateFieldRe(true);

          // Verify if RE is already registered
          const { data } = await api.get(`drivers/verify-unique-field/re/${re.trim()}`);

          if (data.status === "alert") formRef.current?.setFieldError("description", t(DriverModuleMessages.invalidUniqueRE));
          else formRef.current?.setFieldError("re", "");

          setValidateFields({ ...validateFields, re: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldRe(false);
        }
      }

    },
    validateForm: async (formData: Driver, 
      action: "details" | "register" | "update" ) => {

      try {

        // Define the validation types
        let schema;

        // Revalidate unique fields (If not validate yet)
        if ("cpf" in formData && !validateFields.cpf && formData.cpf.trim().length) await validations.validateCpf(formData.cpf);
        if ("re" in formData && !validateFields.re && formData.re.trim().length) await validations.validateRe(formData.re);

        schema = Yup.object().shape({
          name: Yup.string().trim().required(t(DriverModuleMessages.formRequiredName)),
          cpf: Yup.string().trim().test("validateCpf", formRef.current?.getFieldError("cpf"),
            () => !(formRef.current?.getFieldError("cpf")?.length))
            .min(14, t(DriverModuleMessages.formIncompleteDocument))
            .required(t(DriverModuleMessages.formRequiredDocument)),
          re: Yup.string().trim().test("validateRe", formRef.current?.getFieldError("re"),
            () => !(formRef.current?.getFieldError("re")?.length)),
          description: Yup.string().trim().test("validateDescription", formRef.current?.getFieldError("description"),
            () => !(formRef.current?.getFieldError("description")?.length))
        });

        // Register or update driver (According action selected)
        if (action === "register") await createDriver(formData as Driver);
        else await updateDriver(formData as Driver);

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  const validationsRoles = {

    validateFieldError: (fieldName: string) => {
      if (formRefRoles.current?.getFieldError(fieldName)?.length) formRefRoles.current?.setFieldError(fieldName, "");
    },
    validateDescription: async (description: string) => {

      setValidateFieldsDriverRoles({ ...validateFieldsDriverRoles, description: false });

      // Validate description just have 3 or more characters in on blur
      if (description.trim().length >= 3 && description.trim() !== driverRolesDetails.description) {

        try {

          setLoadingValidateFieldDescription(true);

          // Verify if description is already registered
          const { data } = await api.get(`driversRoles/verify-unique-field/description/${description.trim()}`);

          if (data.status === "alert") {
            
            formRefRoles.current?.setFieldError("description", t(DriverModuleMessages.invalidUniqueRoleDescription));

            return false;

          } else {

            setValidateFieldsDriverRoles({ ...validateFieldsDriverRoles, description: true });

            return true;

          }

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldDescription(false);
        }
      }

      return true;
    },
    validateForm: async (formData: DriversRoles, 
      action: "register" | "update" | "details" ) => {

      try {

        // Define the validation types
        let schema;
        
        schema = Yup.object().shape({
          description: Yup.string().trim().test("validateDescription", formRefRoles.current?.getFieldError("description"),
            () => !(formRefRoles.current?.getFieldError("description")?.length))
        });

        if ("description" in formData && !validateFieldsDriverRoles.description &&
          formData.description && formData.description.trim().length) {

          const isValidDescription = await validationsRoles.validateDescription(formData.description);

          if (!isValidDescription) {

            formRefRoles.current?.setErrors({ description: t(DriverModuleMessages.invalidUniqueDescription) });

            return;
            
          }
        }
        if (action === "register") await createDriverRole(formData as DriversRoles);
        else await updateDriverRole(formData as DriversRoles);

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

      } catch (error) {
        formRefRoles.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Form Validation */
  /* region Constants */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-driver-list-details",
      callback: (rowData: Driver) => handleDriverDetails(rowData)
    },
    {
      ref: ".modules-driver-list-delete",
      callback: (rowData: Driver) => handleDriverDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Nome
      title: t(DriverModuleMessages.fullname),
      data: (driver: Driver) => driver.name,
      filterable: true,
      defaultContent: "",
      propertyName: "name"
    },
    { // RE
      title: t(DriverModuleMessages.re),
      data: (driver: Driver) => driver.re || "",
      filterable: true,
      defaultContent: "",
      propertyName: "re"
    },
    { // CPF
      title: t(DriverModuleMessages.document),
      data: (driver: Driver) => driver.cpf,
      filterable: true,
      defaultContent: "",
      propertyName: "cpf"
    },
    { // CNH
      title: t(DriverModuleMessages.licenseNumber),
      data: (driver: Driver) => driver.license_number || "",
      filterable: true,
      defaultContent: "",
      propertyName: "license_number"
    },
    { // Data de expiração
      title: t(DriverModuleMessages.licenseExpiredDate),
      data: (driver: Driver) => driver.license_expired_date,
      render: (data, type) => (data
        ? utils.formatDateIfHave(new Date(data), "fullDate")
        : ""),
      filterable: true,
      defaultContent: "",
      propertyName: "license_expired_date"
    },
    { // Ibutton
      title: t(DriverModuleMessages.iButton),
      data: (driver: Driver) => driver.hardware?.dev_id || "",
      filterable: false,
      defaultContent: "",
      propertyName: "dev_id"
    },
    { // Ativo
      title: t(DataTableMessages.status),
      data: (driver: Driver) => (
        ReactDOMServer.renderToString(
          <b style={{ color: driver.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {driver.active ? t(GlobalMessages.active).toUpperCase() : t(GlobalMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      filterable: true,
      propertyName: "active"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-driver-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-driver-list-delete"><DeleteForeverIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleCreateDriver()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readDrivers()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_motoristas_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsViewRoles),
      key: "addRole",
      callback: () => FhandleCreateDriverRole()
    }
  ];

  const dataTableDriverRolesActions: DataTableActions[] = [
    {
      ref: ".modules-driver-roles-list-details",
      callback: (rowData: DriversRoles) => handleDriverRoleDetails(rowData)
    },
    {
      ref: ".modules-driver-roles-list-delete",
      callback: (rowData: DriversRoles) => handleDriverRoleDelete(rowData)
    }
  ];
  const dataTableDriverRolesColumns: DataTableColumns[] = [
    { // Registration date driver role
      title: t(DriverModuleMessages.roleRegistrationDate),
      data: (driverRole: DriversRoles) => dateFns.format(new Date(driverRole.registration_date), "dd/MM/yyyy HH:mm:ss"),
      filterable: true,
      defaultContent: "",
      propertyName: "registration_date"
    },
    { // Description driver role
      title: t(DriverModuleMessages.roleDescription),
      data: (driverRole: DriversRoles) => driverRole.description,
      filterable: true,
      defaultContent: "",
      propertyName: "description"
    },
    { // Active
      title: t(DataTableMessages.status),
      data: (driverRole: DriversRoles) => (
        ReactDOMServer.renderToString(
          <b style={{ color: driverRole.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {driverRole.active ? t(GlobalMessages.active).toUpperCase() : t(GlobalMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      filterable: true,
      propertyName: "active"
    },
    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-driver-roles-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-driver-roles-list-delete"><DeleteForeverIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableDriverRolesButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => handleNewRole()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readDrivers()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `${t(DataTableMessages.reportDriverRolesLabel)}_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];

  /* endregion Constants */

  /* region Functions */
  // Actions drivers list
  const handleDriverDetails = useCallback((driver: Driver) => {

    setDriverDetails(driver);
    setActiveCheckbox(driver.active);
    setValidateFields({ cpf: true, re: true });

    // Optional fields
    if (driver?.hardware) setActiveOptionIbutton(driver.hardware);
    else setActiveOptionIbutton(null);

    if (driver?.drivers_roles) setActiveOptionDriversRoles(driver.drivers_roles);
    else setActiveOptionDriversRoles(null);
    
    if (driver?.id_group_fk) {

      let findGroup: Group | undefined = optionsGroup.find((option: Group) => option.id_group === driver.id_group_fk);

      if (findGroup)
        setActiveOptionGroup(findGroup);
    } else setActiveOptionGroup(null);
    
    if (driver?.id_regional_fk) {

      let findRegional: Regional | undefined = optionsRegional.find((option: Regional) => option.id_regional === driver.id_regional_fk);
      
      if (findRegional)
        setActiveOptionRegional(findRegional);      
    } else setActiveOptionRegional(null);

    if(driver?.responsible_emails) {

      let findUser: User | undefined = optionsUser.find( (option: User) => driver.responsible_emails.split(";").includes(option.email) );

      if (findUser)
        setActiveOptionUser([findUser]);
    } else setActiveOptionUser(null);

    setActiveOwnership("companyDriver");

    if (driver?.id_plant_fk) {

      let findPlant: Plant | undefined = optionsPlants.find((option: Plant) => option.id_plant === driver.id_plant_fk);
      
      if (findPlant)
        setActiveOptionsPlants(findPlant);
    } else setActiveOptionsPlants(null);

    setTimeout(() => {
      setModalType("details");
      setOpenModalFormDriver(true);
    }, 100);

  }, [driverDetails, optionsGroup, optionsRegional, optionsUser, activeOptionGroup, activeOptionRegional, activeOptionUser]);
  const handleDriverDelete = useCallback((driver: Driver) => {

    setDriverDetails(driver);

    setOpenDialogConfirmDelete(true);

  }, []);

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setDriverDetails({} as Driver);
    setValidateFields({ cpf: false, re: false });
    setActiveOptionIbutton(null);
    setActiveOptionDriversRoles({} as DriversRoles);

    setOpenModalFormDriver(false);

  }, []);

  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType || modalRolesType) {
    case "details": return detailValue;
    case "update": return updateValue;
    case "register": return registerValue;
    default: return "S/N";
    }
  }, [modalType]);

  /** Get all drivers data
   */
  const readDrivers = useCallback(async () => {

    try {

      // Get all drivers
      setLoadingDriverList(true);
      const { data } = await api.get("drivers/read");

      if (data.status === "success") setDrivers(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  }, [addToast, t]);

  /** Create a driver
   * @param driver Driver to create
   */
  const createDriver = useCallback(async (driver: Driver) => {

    try {

      let insertDriver = { ...driver };

      if (activeOptionDriversRoles?.id_driver_role)
        insertDriver.id_driver_role_fk = activeOptionDriversRoles?.id_driver_role;

      if (activeOptionGroup?.id_group)
        insertDriver.id_group_fk = activeOptionGroup?.id_group;

      if (activeOptionRegional?.id_regional)
        insertDriver.id_regional_fk = activeOptionRegional?.id_regional;

      if (activeOptionUser)
        insertDriver.responsible_emails = activeOptionUser.reduce((acc, user, index) => {
          return acc + (index ? ";" : "") + user.email;
        }, "");

      if (activeOwnership)
        insertDriver.ownership = activeOwnership;

      // add 3 hours in license expired date
      if (insertDriver.license_expired_date)
        insertDriver = { ...insertDriver, license_expired_date: dateFns.addHours(new Date(insertDriver.license_expired_date), 3) };

      // add 3 hours in cnh emission date
      if (insertDriver.cnh_emission_date)
        insertDriver = { ...insertDriver, cnh_emission_date: dateFns.addHours(new Date(insertDriver.cnh_emission_date), 3) };

      if (activeOptionsPlants && activeOptionsPlants["id_plant"])
        insertDriver.id_plant_fk = activeOptionsPlants["id_plant"];

      // Remove properties without value
      Object.keys(insertDriver).forEach((key) => {
        if (insertDriver[key].length <= 0) delete insertDriver[key];
      });

      // Set active Ibutton option
      if (insertDriver.hardware) insertDriver.hardware = activeOptionIbutton as Hardware;

      // Create a driver
      setLoadingDriverList(true);
      const { data } = await api.post("drivers/create", insertDriver);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertDriver = data.result;

        readDrivers();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  }, [addToast, table, drivers, readDrivers, handleCloseModalForm, activeOptionIbutton, t, 
    activeOptionRegional, activeOptionDriversRoles, activeOptionGroup, activeOptionUser, activeOwnership, activeOptionsPlants
  ]);

  /*
   * Open modal to create new driver
   * */
  const FhandleCreateDriver = useCallback(() => {

    setActiveOptionGroup(null);
    setActiveOptionRegional(null);
    setModalType("register");
    setOpenModalFormDriver(true);
    setActiveOwnership("companyDriver");
  }, [optionsGroup, optionsRegional, activeOptionGroup, activeOptionRegional]);
  /** Update a driver
   * @param driver Driver to update
   */
  const updateDriver = useCallback(async (driver: Driver) => {

    try {

      let updateDriver = { ...driver };

      if (activeOptionDriversRoles?.id_driver_role)
        updateDriver.id_driver_role_fk = activeOptionDriversRoles?.id_driver_role;

      if (activeOptionGroup?.id_group)
        updateDriver.id_group_fk = activeOptionGroup?.id_group;

      if (activeOptionRegional?.id_regional)
        updateDriver.id_regional_fk = activeOptionRegional?.id_regional;

      if (activeOptionUser)
        updateDriver.responsible_emails = activeOptionUser.reduce((acc, user, index) => {
          return acc + (index ? ";" : "") + user.email;
        }, "");

      if (activeOwnership)
        updateDriver.ownership = activeOwnership;

      // Set Ibutton active option
      if (updateDriver.hardware) updateDriver.hardware = activeOptionIbutton as Hardware;

      // In case of optional field with no value
      if (!updateDriver.hardware) updateDriver.hardware = { ...{}, id_hardware: "" } as Hardware;
      if (!updateDriver.external_id) updateDriver.external_id = null;

      // add 3 hours in license expired date
      if (updateDriver.license_expired_date)
        updateDriver = { ...updateDriver, license_expired_date: dateFns.addHours(new Date(updateDriver.license_expired_date), 3) };

      // add 3 hours in cnh emission date
      if (updateDriver.cnh_emission_date)
        updateDriver = { ...updateDriver, cnh_emission_date: dateFns.addHours(new Date(updateDriver.cnh_emission_date), 3) };

      if (activeOptionsPlants && activeOptionsPlants["id_plant"])
        updateDriver.id_plant_fk = activeOptionsPlants["id_plant"];

      // Update a driver
      setLoadingDriverList(true);
      const { data } = await api.patch(`drivers/update/${driverDetails.id_driver}`, updateDriver);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setDriverDetails(Object.assign(driverDetails, updateDriver));

        // Update row in table with updated driver data
        if (table.row(tableClickedRow).node()) table.row(tableClickedRow).data(driverDetails);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  }, [addToast, table, tableClickedRow, driverDetails, handleCloseModalForm, activeOptionIbutton, t,
    activeOptionRegional, activeOptionDriversRoles, activeOptionGroup, activeOptionUser, activeOwnership, activeOptionsPlants
  ]);

  /** Delete selected driver
   * @param driver Driver to delete
   */
  const deleteDriver = useCallback(async (driver: Driver) => {

    try {

      setLoadingDriverList(true);
      const { data } = await api.delete(`drivers/delete/${driver.id_driver}`);

      if (data.status === "success") {

        setOpenDialogConfirmDelete(false); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        table.row(tableClickedRow).remove(); // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverList(false);
    }

  },
  // eslint-disable-next-line
    [table, tableClickedRow]);

  const handleDriverRoleDetails = useCallback((driverRole: DriversRoles) => {

    setDriverRolesDetails(driverRole);
    setActiveDriverRolesCheckbox(driverRole.active);
    setValidateFieldsDriverRoles({ description: true });

    setModalRolesType("details");
    setSelectedTab(1);

    setDynamicTabs([
      { label: t(DriverModuleMessages.detailsDriverRolesTitle), disableRipple: true },
      { label: t(DriverModuleMessages.detailsDriverRole), disableRipple: true }
    ]);

  }, [selectedTab, dynamicTabs]);

  const handleDriverRoleUpdate = useCallback((driverRole: DriversRoles) => {

    setDriverRolesDetails(driverRole);
    setActiveDriverRolesCheckbox(driverRole.active);
    setValidateFieldsDriverRoles({ description: true });

    setModalRolesType("update");

    setDynamicTabs([
      { label: t(DriverModuleMessages.detailsDriverRolesTitle), disableRipple: true },
      { label: t(DriverModuleMessages.updateDriverRole), disableRipple: true }
    ]);

  }, [selectedTab, dynamicTabs]);

  // Actions close dialogs and modals
  const handleCloseModalFormDriverRoles = useCallback(() => {

    setDriverRolesDetails({} as DriversRoles);
    setValidateFieldsDriverRoles({ description: false });
    
    setSelectedTab((selectedTab) => {

      if (selectedTab === 0) {

        setOpenModalFormDriverRoles(false);
        handleCloseTabNewRole();
      } else {

        return 0;
      }

      return selectedTab;
    });

  }, []);

  /*
   * Open modal to create new driver role
   * */
  const FhandleCreateDriverRole = () => {
    setModalRolesType("register");
    setOpenModalFormDriverRoles(true);
  };

  /** Get all driver roles data
   */
  const readDriverRoles = useCallback(async () => {

    try {

      // Get all drivers
      setLoadingDriverRolesList(true);
      const { data } = await api.get("driversRoles/read");

      if (data.status === "success") setDriversRoles(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverRolesList(false);
    }

  }, [addToast, t]);

  /** Create a driver role
   * @param driverRole Driver role to create
   */
  const createDriverRole = useCallback(async (driverRole: DriversRoles) => {

    try {

      let insertDriverRole = { ...driverRole };

      // Remove properties without value
      Object.keys(insertDriverRole).forEach((key) => {

        if (insertDriverRole[key].length <= 0) delete insertDriverRole[key];
      });

      // Verifique se a descrição já está registrada
      const { data: uniqueCheck } = await api.get(`driversRoles/verify-unique-field/description/${insertDriverRole.description.trim()}`);

      if (uniqueCheck.status === "alert") {

        addToast({ type: "info", title: t(ToastMessages.alert), description: uniqueCheck.message });

        return;
      }

      // Create a driver role
      setLoadingDriverRolesList(true);

      const { data } = await api.post("driversRoles/create", insertDriverRole);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
        insertDriverRole = data.result;

        readDriverRoles();
        setSelectedTab(0);
      } else {

        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverRolesList(false);
    }

  }, [addToast, tableDriverRoles, driversRoles, readDriverRoles, handleCloseModalForm, t]);

  /** Update a driver role
   * @param driver Driver role to update
   */
  const updateDriverRole = useCallback(async (driverRole: DriversRoles) => {

    try {

      const updateDriverRole = { ...driverRole };

      // Update a driver role
      setLoadingDriverRolesList(true);
      const { data } = await api.patch(`driversRoles/update/${driverRolesDetails.id_driver_role}`, updateDriverRole);

      if (data.status === "success") {

        setSelectedTab(0);
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setDriverRolesDetails(Object.assign(driverRolesDetails, updateDriverRole));

        readDriverRoles();
        setDynamicTabs([
          { label: t(DriverModuleMessages.detailsDriverRolesTitle), disableRipple: true },
          { label: t(DriverModuleMessages.newDriversRoles), disableRipple: true }
        ]);

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverRolesList(false);
    }

  }, [selectedTab, addToast, tableDriverRoles, tableDriverRolesClickedRow, driverRolesDetails, handleCloseModalForm, t]);

  const handleDriverRoleDelete = useCallback((driverRole: DriversRoles) => {

    setDriverRolesDetails(driverRole);

    setOpenDialogDriverRolesConfirmDelete(true);

  }, []);

  /** Delete selected driver role
   * @param driver Driver role to delete
   */
  const deleteDriverRole = useCallback(async (driversRoles: DriversRoles) => {

    try {

      setLoadingDriverRolesList(true);
      const { data } = await api.delete(`driversRoles/delete/${driversRoles.id_driver_role}`);

      if (data.status === "success") {

        setOpenDialogDriverRolesConfirmDelete(false); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        readDriverRoles();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingDriverRolesList(false);
      setOpenDialogDriverRolesConfirmDelete(false);
    }

  },
  // eslint-disable-next-line
    [tableDriverRoles, tableDriverRolesClickedRow]);

  const handleNewRole = useCallback(() => {
    
    setValidateFieldsDriverRoles({ description: false });
    setSelectedTab(1);
    setModalRolesType("register");
  }, [validateFieldsDriverRoles, dynamicTabs, modalRolesType, selectedTab]);

  const handleCloseTabNewRole = () => {

    setValidateFieldsDriverRoles({ description: false });
    setSelectedTab(0);
  };

  const handleTabChange = useCallback((event, newValue) => {

    setSelectedTab(newValue);
    setModalRolesType("register");

    setTimeout(() => {

      formRef.current?.setFieldValue("description", "");
    }, 0);

    setDynamicTabs([
      { label: t(DriverModuleMessages.detailsDriverRolesTitle), disableRipple: true },
      { label: t(DriverModuleMessages.newDriversRoles), disableRipple: true }
    ]);
    
  }, [selectedTab, dynamicTabs, modalRolesType, selectedTab]);

  /* endregion Functions */
  /* region Effects */
  // Get Ibutton hardware options
  useEffect(() => {
    if (!openOptionsIbuttons) setOptionsIbuttons([]);
    
  }, [openOptionsIbuttons]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsIbuttons) return undefined;

    (async () => {

      try {

        // Get all hardwares
        const { data } = await api.get("hardwares/read");

        if (data.status === "success") {
          // Filter to Ibutton only
          if (active) setOptionsIbuttons(data.result.filter((hardware: Hardware) => hardware.type.description === HardwareTypes.IBUTTON));

        } else {
          setOpenOptionsIbuttons(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsIbuttons(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsIbuttons]);

  // Get Drivers roles options
  useEffect(() => {

    let active = true;

    if (!openOptionsDriversRoles) {

      setOptionsDriversRoles([]);

      return;
    }

    setLoadingOptionsDriversRoles(true);

    (async () => {
      try {
        const { data } = await api.get("driversRoles/read");

        if (data.status === "success") {
          if (active) {
            setOptionsDriversRoles(data.result.filter((driverRole: DriversRoles) => driverRole.active));
          }
        } else {
          setOpenOptionsDriversRoles(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      } catch (error: any) {
        setOpenOptionsDriversRoles(false);
        if (!error.response) {
          addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        } else {
          addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        }
      } finally {
        if (active) {
          setLoadingOptionsDriversRoles(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [openOptionsDriversRoles, addToast, i18n.language]);

  // Get Group options
  useEffect(() => {
  
    let active = true;
  
    const fetchGroups = async () => {
      setLoadingOptionsGroup(true);
  
      try {
        const { data } = await api.get("groups/read");
  
        if (data.status === "success") {
          if (active) {
            setOptionsGroup(data.result.filter((group: Group) => group.active));
          }
        } else {
          setOpenOptionsGroup(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      } catch (error: any) {
        setOpenOptionsGroup(false);
        if (!error.response) {
          addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        } else {
          addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        }
      } finally {
        if (active) {
          setLoadingOptionsGroup(false);
        }
      }
    };
  
    fetchGroups();
  
    return () => {
      active = false;
    };
  }, [openOptionsGroup, addToast, i18n.language]);

  // Get Regional options
  useEffect(() => {

    let active = true;

    const fetchRegional = async () => {

      setLoadingOptionsRegional(true);
        
      try {
        const { data } = activeOptionGroup?.id_group ?
          await api.get(`regionals/readByGroup?id=${activeOptionGroup?.id_group}`) :
          await api.get("regionals/read");

        if (data.status === "success") {
          if (active) {
            setOptionsRegional(data.result.filter((regional: Regional) => regional.active));
          }
        } else {
          setOpenOptionsRegional(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      } catch (error: any) {
        setOpenOptionsRegional(false);
        if (!error.response) {
          addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        } else {
          addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        }
      } finally {
        if (active) {
          setLoadingOptionsRegional(false);
        }
      }
    };

    fetchRegional();

    return () => {
      active = false;
    };
  }, [openOptionsRegional, addToast, i18n.language]);

  // Get User emails options
  useEffect(() => {

    let active = true;

    const fetchUser = async () => {

      setLoadingOptionsUser(true);

      try {
        const { data } = await api.get("users/read", {
          params: {
            linkedPlants: true,
            lastSentAlert: true
          }
        });

        if (data.status === "success") {
          if (active) {
            setOptionsUser(data.result);
          }
        } else {
          setOpenOptionsUser(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      } catch (error: any) {
        setOpenOptionsUser(false);
        if (!error.response) {
          addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        } else {
          addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        }
      } finally {
        if (active) {
          setLoadingOptionsUser(false);
        }
      }
    };

    fetchUser();

    return () => {
      active = false;
    };
  }, [openOptionsUser, addToast, i18n.language]);

  // Plants options
  useEffect(() => {

    let active = true;

    const fetchPlant = async () => {

      setLoadingOptionsPlants(true);

      try {

        // Get all locations with type = 'Usina'
        const { data } = await api.get("plants/read");

        if (data.status === "success") {
          if (active) setOptionsPlants(data.result);
        } else {
          setOpenOptionsPlants(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsPlants(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      } finally {

        if (active) setLoadingOptionsPlants(false);
      }
    };

    fetchPlant();

    return () => { active = false; };

  }, [openOptionsPlants, addToast, i18n.language]);

  // Used to disable and change text when is searching for data
  useEffect(() => {

    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingDriverList ? "Buscando motoristas..." : "Pesquisar";

      if (loadingDriverList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingDriverList]);

  // Get all drivers on first render and when language changes
  useEffect(() => {
    setDrivers([]);

    readDrivers().then();
  }, []);

  // Get all drivers roles on first render and when language changes
  useEffect(() => {
    setDriversRoles([]);

    readDriverRoles().then();

  }, []);

  /* endregion Effects */

  return (
    <>
      <Header title={t(MenuMessages.drivers)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loadingDriverList} />
        {
          !loadingDriverList && (
            <NewDataTable
              title={t(MenuMessages.drivers).toLowerCase()}
              filters
              data={drivers}
              columns={dataTableColumns}
              actions={dataTableActions}
              settings={dataTableSettings}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
              returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
            />
          )
        }
        <DialogConfirmAction
          open={openDialogConfirmDelete}
          onClose={() => setOpenDialogConfirmDelete(false)}
          title={t(DriverModuleMessages.deleteConfirmation)}
          actions={[
            { text: t(GlobalMessages.no), action: () => setOpenDialogConfirmDelete(false) },
            { text: t(GlobalMessages.yes), action: () => deleteDriver(driverDetails) }
          ]}
        >
          {t(DriverModuleMessages.deleteConfirmationText)} <br /><br />
          { t(DriverModuleMessages.fullname) }: {driverDetails.name} <br />
          { t(DriverModuleMessages.deleteConfirmationHolder) } { t(DriverModuleMessages.document) }: {driverDetails.cpf}?
        </DialogConfirmAction>
        <ContainerModalFormDriver id="modalFormDriver">
          <Dialog
            open={openModalFormDriver}
            onClose={handleCloseModalForm}
            scroll="paper"
            container={document.getElementById("modalFormDriver")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">
                  {defineValueAccordingModalType(driverDetails.name, driverDetails.name, t(DriverModuleMessages.detailsTitle))}
                </div>
                <div className="subtitle">{defineValueAccordingModalType(driverDetails.cpf, driverDetails.cpf, "")}</div>
              </div>
              <div className="actions">
                {modalType === "details"
                  && (
                    <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                  )}
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData, modalType)}>
                <DialogContentText tabIndex={-1} component="div">
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={6} lg={6} className="checkboxes">
                      <FormControlLabel
                        className="r-checkbox"
                        control={(
                          <Checkbox
                            onChange={(event) => setActiveCheckbox(event.target.checked)}
                            checked={activeCheckbox}
                            name="active"
                            disabled={modalType === "details"}
                          />
                        )}
                        label={t(DriverModuleMessages.detailsActive)}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} className="checkboxes">
                      <FormLabel id="demo-radio-buttons-group-label">{t(DriverModuleMessages.typeDriver)}</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={activeOwnership}
                        name="ownership"
                        onChange={(event) => setActiveOwnership(event.target.value as "companyDriver" | "thirdPartyDriver")}
                      >
                        <FormControlLabel 
                          disabled={modalType === "details"} 
                          value="companyDriver" 
                          control={<Radio />} 
                          label={t(DriverModuleMessages.companyDriverLabel)} 
                        />
                        <FormControlLabel 
                          disabled={modalType === "details"} 
                          value="thirdPartyDriver" 
                          control={<Radio />} 
                          label={t(DriverModuleMessages.thirdPartyDriverLabel)} 
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <InputMask
                        mask="999.999.999-99"
                        maskChar=""
                        defaultValue={defineValueAccordingModalType(driverDetails.cpf, driverDetails.cpf, "")}
                        onChange={() => validations.validateCpf(formRef.current?.getFieldValue("cpf"))}
                        disabled={modalType === "details"}
                      >
                        {() => (
                          <TextField
                            className="cpf"
                            label={t(DriverModuleMessages.document)}
                            margin="dense"
                            variant="outlined"
                            name="cpf"
                            InputLabelProps={{ 
                              shrink: driverDetails.cpf !== null 
                            }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {loadingValidateFieldCpf ? <CircularProgress color="inherit" size={20} /> : null}
                                </>
                              )
                            }}
                            disabled={modalType === "details"}
                            helperText={t(GlobalMessages.required)}
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="re"
                        label={t(DriverModuleMessages.re)}
                        margin="dense"
                        variant="outlined"
                        name="re"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("re").length > 0 ? true : undefined }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {loadingValidateFieldRe ? <CircularProgress color="inherit" size={20} /> : null}
                            </>
                          )
                        }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(driverDetails.re, driverDetails.re, "")}
                        onBlur={() => validations.validateRe(formRef.current?.getFieldValue("re"))}
                        onChange={() => validations.validateFieldError("re")}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        className="name"
                        label={t(DriverModuleMessages.fullname)}
                        margin="dense"
                        variant="outlined"
                        name="name"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("name").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(driverDetails.name, driverDetails.name, "")}
                        onChange={() => validations.validateFieldError("name")}
                      />
                    </Grid>
                    {user.admin && (
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          label={t(LocationsModuleMessages.externalId)}
                          margin="dense"
                          variant="outlined"
                          name="external_id"
                          InputLabelProps={{ shrink: formRef.current?.getFieldValue("external_id").length > 0 ? true : undefined }}
                          disabled={modalType === "details" || !user.super_admin}
                          defaultValue={defineValueAccordingModalType(driverDetails.external_id, driverDetails.external_id, "")}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={7} lg={7}>
                      <TextField
                        className="licenseNumber"
                        label={t(DriverModuleMessages.detailsDocument)}
                        margin="dense"
                        variant="outlined"
                        name="license_number"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("license_number").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(driverDetails.license_number, driverDetails.license_number, "")}
                      />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                      <TextField
                        className="licenseExpiredDate"
                        label={t(DriverModuleMessages.licenseExpiredDate)}
                        name="license_expired_date"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        disabled={modalType === "details"}
                        defaultValue={
                          !_.isEmpty(driverDetails) && driverDetails.license_expired_date
                          && defineValueAccordingModalType(
                            dateFns.format(new Date(driverDetails.license_expired_date), "yyyy-MM-dd"),
                            dateFns.format(new Date(driverDetails.license_expired_date), "yyyy-MM-dd"),
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        label={t(DriverModuleMessages.driverLicenseRestriction)}
                        margin="dense"
                        variant="outlined"
                        name="driver_license_restrictions"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("driver_license_restrictions").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(driverDetails.driver_license_restrictions, 
                          driverDetails.driver_license_restrictions, "")}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        label={t(DriverModuleMessages.cnhEmissionDate)}
                        name="cnh_emission_date"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        disabled={modalType === "details"}
                        defaultValue={
                          !_.isEmpty(driverDetails) && driverDetails.cnh_emission_date
                          && defineValueAccordingModalType(
                            dateFns.format(new Date(driverDetails.cnh_emission_date), "yyyy-MM-dd"),
                            dateFns.format(new Date(driverDetails.cnh_emission_date), "yyyy-MM-dd"),
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Autocomplete
                        open={openOptionsIbuttons}
                        onOpen={() => setOpenOptionsIbuttons(true)}
                        onClose={() => setOpenOptionsIbuttons(false)}
                        getOptionSelected={(option, value) => option.dev_id === value.dev_id}
                        getOptionLabel={(option) => `${option.dev_id
                          ? `${option.dev_id}` : ""}${option?.driver?.name ? ` - ${option.driver.name}` : ""}`}
                        getOptionDisabled={(option) => !_.isEmpty(option.driver)}
                        options={optionsIbuttons.sort((a, b) => {
                          if (a.driver && b.driver) {
                            return a.driver.name.localeCompare(b.driver.name);
                          }
                          if (a.driver && !b.driver) {
                            return 1;
                          }
                          if (!a.driver && b.driver) {
                            return -1;
                          }

                          return 0;
                        })}
                        disabled={modalType === "details"}
                        loading={loadingOptionsIbuttons}
                        groupBy={(option) => (option.driver ? t(DriverModuleMessages.detailsUsedGroup) : t(DriverModuleMessages.detailsUsedGroup))}
                        onChange={(event, value) => setActiveOptionIbutton(value)}
                        defaultValue={
                          !_.isEmpty(driverDetails?.hardware)
                          && defineValueAccordingModalType(driverDetails?.hardware, driverDetails?.hardware, "")
                        }
                        value={activeOptionIbutton}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="hardware"
                            label={t(DriverModuleMessages.detailsIButtonCode)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: driverDetails?.hardware !== null
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsIbuttons ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Autocomplete
                        open={openOptionsDriversRoles}
                        onOpen={() => setOpenOptionsDriversRoles(true)}
                        onClose={() => setOpenOptionsDriversRoles(false)}
                        getOptionSelected={(option, value) => option.id_driver_role === value.id_driver_role}
                        getOptionLabel={(option: DriversRoles) => `${option.id_driver_role ? `${option.description}` : ""}`}
                        options={optionsDriversRoles.sort((a, b) => a.description.localeCompare(b.description))}
                        disabled={modalType === "details"}
                        loading={loadingOptionsDriversRoles}
                        onChange={(event, value) => setActiveOptionDriversRoles(value as DriversRoles)}
                        defaultValue={
                          !_.isEmpty(driverDetails?.drivers_roles)
                          && defineValueAccordingModalType(driverDetails?.drivers_roles, driverDetails?.drivers_roles, "")
                        }
                        value={activeOptionDriversRoles}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="driver_role"
                            label={t(DriverModuleMessages.driverRole)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: driverDetails?.drivers_roles !== null
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsDriversRoles ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Autocomplete
                        open={openOptionsGroup}
                        onOpen={() => setOpenOptionsGroup(true)}
                        onClose={() => setOpenOptionsGroup(false)}
                        getOptionSelected={(option, value) => option.id_group === value.id_group}
                        getOptionLabel={(option: Group) => `${option.id_group ? `${option.name}` : ""}`}
                        options={optionsGroup.sort((a, b) => a.name.localeCompare(b.name))}
                        disabled={modalType === "details"}
                        loading={loadingOptionsGroup}
                        onChange={(event, value) => {

                          setActiveOptionGroup(value as Group);
                          formRef.current?.setFieldValue("regional", "");
                          setDriverDetails({ ...driverDetails, id_regional_fk: "" }); 
                          setOptionsRegional([]);
                          setActiveOptionRegional(null);
                        }}
                        defaultValue={
                          !_.isEmpty(driverDetails?.group)
                          && defineValueAccordingModalType(driverDetails?.group, driverDetails?.group, "")
                        }
                        value={activeOptionGroup}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="group"
                            label={t(DriverModuleMessages.driverGroup)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: driverDetails?.group !== null
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsGroup ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Autocomplete
                        open={openOptionsRegional}
                        onOpen={() => setOpenOptionsRegional(true)}
                        onClose={() => setOpenOptionsRegional(false)}
                        getOptionSelected={(option, value) => option.id_regional === value.id_regional}
                        getOptionLabel={(option: Regional) => `${option.id_regional ? `${option.name}` : ""}`}
                        options={optionsRegional.sort((a, b) => a.name.localeCompare(b.name))}
                        disabled={modalType === "details" || formRef.current?.getFieldValue("group").length === 0}
                        loading={loadingOptionsRegional}
                        onChange={(event, value) => setActiveOptionRegional(value as Regional)}
                        defaultValue={
                          !_.isEmpty(driverDetails?.regional)
                          && defineValueAccordingModalType(driverDetails?.regional, driverDetails?.regional, "")
                        }
                        value={activeOptionRegional}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="regional"
                            label={t(DriverModuleMessages.driverRegional)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: driverDetails?.regional !== null
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsRegional ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Autocomplete
                        multiple
                        fullWidth
                        renderTags={(value, getTagProps) => (
                          <LimitedChipsField
                            data={value}
                            limit={1}
                            getTagProps={getTagProps}
                            labelsFormat={(user: User) => user.email ?? ""}
                            keysFormat={(user: User) => user.email ?? ""}
                          />
                        )}
                        limitTags={1}
                        disableCloseOnSelect
                        open={openOptionsUser}
                        onOpen={() => setOpenOptionsUser(true)}
                        onClose={() => setOpenOptionsUser(false)}
                        onChange={(event, value) => setActiveOptionUser(value)}
                        getOptionSelected={(option: User, value) => option.email === value.email}
                        getOptionLabel={(option: User) => option.email}
                        options={optionsUser.sort((a, b) => (b.email ? -b.email.localeCompare(a.email as string) : 0))}
                        disabled={modalType === "details"}
                        loading={loadingOptionsUser}
                        defaultValue={activeOptionUser !== null ? defineValueAccordingModalType(activeOptionUser, activeOptionUser, []) : []}
                        renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              name="undefined"
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                            />
                            {option.email + " (" + option.name + ")"}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="emails"
                            label={t(DriverModuleMessages.responsibleEmails)}
                            margin="dense"
                            variant="outlined"
                            helperText={t(GlobalMessages.required)}
                            InputLabelProps={{
                              shrink: activeOptionUser !== null
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsUser ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Autocomplete
                        open={openOptionsPlants}
                        onOpen={() => setOpenOptionsPlants(true)}
                        onClose={() => setOpenOptionsPlants(false)}
                        getOptionSelected={(option, value) => option.id_plant === value.id_plant}
                        getOptionLabel={(option) => option.name as string}
                        options={optionsPlants.sort((a, b) => (b.name ? -b.name.localeCompare(a.name as string) : 0))}
                        disabled={modalType === "details"}
                        loading={loadingOptionsPlants}
                        onChange={(event, value) => setActiveOptionsPlants(value)}
                        defaultValue={defineValueAccordingModalType(activeOptionsPlants, activeOptionsPlants, [])}
                        value={activeOptionsPlants}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="plants"
                            label={t(ManagementMessages.detailsDriverRegistrationPlant)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: activeOptionsPlants !== null
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsPlants ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </Form>
            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                  modalType === "details"
                    ? <></>
                    : modalType === "register"
                      ? (
                        <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>
                          {t(GlobalMessages.register)}
                        </Button>
                      )
                      : (
                        <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>
                          {t(GlobalMessages.save)}
                        </Button>
                      )
                }
              </div>
              <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalFormDriver>
        
        <DialogConfirmAction
          open={openDialogDriverRolesConfirmDelete}
          onClose={() => setOpenDialogDriverRolesConfirmDelete(false)}
          title={t(DriverModuleMessages.deleteConfirmation)}
          actions={[
            { text: t(GlobalMessages.no), action: () => setOpenDialogDriverRolesConfirmDelete(false) },
            { text: t(GlobalMessages.yes), action: () => deleteDriverRole(driverRolesDetails) }
          ]}
        >
          {t(DriverModuleMessages.deleteConfirmationDriverRoleText)}
        </DialogConfirmAction>

        <ContainerModalFormDriverRoles id="modalFormDriverRole">

          <Dialog
            open={openModalFormDriverRoles}
            onClose={handleCloseModalFormDriverRoles}
            scroll="paper"
            container={document.getElementById("modalFormDriverRole")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">
                  {t(DriverModuleMessages.detailsDriverRolesTitle)}
                </div>
              </div>
              <div className="actions">
                {modalRolesType === "details"
                  && (
                    // <Button disableRipple onClick={() => setModalType("updateRole")}><EditIcon /></Button>
                    <Button disableRipple onClick={() => handleDriverRoleUpdate(driverRolesDetails)}>
                      <EditIcon />
                    </Button>
                  )}
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">

              <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
                <Tabs
                  value={selectedTab}
                  className="tabContainer"
                  onChange={(event, newValue) => handleTabChange(event, newValue)}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: theme.colors.primary
                    }
                  }}
                >
                  {dynamicTabs.map((tab, index) => (
                    <Tab
                      key={tab.label}
                      label={tab.label}
                      disableRipple={tab.disableRipple}
                    />
                  ))}
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                  
                  <Loading loading={loadingDriverRolesList} />
                  {
                    !loadingDriverRolesList && (
                      <NewDataTable
                        title={t(MenuMessages.driversRoles).toLowerCase()}
                        filters
                        data={driversRoles}
                        columns={dataTableDriverRolesColumns}
                        actions={dataTableDriverRolesActions}
                        settings={dataTableSettings}
                        buttons={dataTableDriverRolesButtons}
                        returnTable={(tableDriverRoles) => setTableDriverRoles(tableDriverRoles.order([[0, "desc"]]))}
                        returnClickedRow={(clickedRowDriverRoles) => setTableDriverRolesClickedRow(clickedRowDriverRoles)}
                        showPaging={false}
                      />
                    )
                  }

                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  
                  <Form className="form" ref={formRefRoles} onSubmit={(formData) => validationsRoles.validateForm(formData, modalRolesType)}>
                    <DialogContentText tabIndex={-1} component="div">
                      <Grid container spacing={1}>
                        <Grid item xs={9} md={9} lg={9}>
                          <TextField
                            className="re"
                            label={t(DriverModuleMessages.roleDescription)}
                            margin="dense"
                            variant="outlined"
                            name="description"
                            InputLabelProps={{ shrink: formRefRoles.current?.getFieldValue("description").length > 0 ? true : undefined }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {loadingValidateFieldDescription ? <CircularProgress color="inherit" size={20} /> : null}
                                </>
                              )
                            }}
                            disabled={modalRolesType === "details"}
                            defaultValue={driverRolesDetails.description}
                            onBlur={() => validationsRoles.validateDescription(formRefRoles.current?.getFieldValue("description"))}
                            onChange={() => validationsRoles.validateFieldError("description")}
                          />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3} className="checkboxes">
                          <FormControlLabel
                            className="r-checkbox"
                            control={(
                              <Checkbox
                                onChange={(event) => setActiveDriverRolesCheckbox(event.target.checked)}
                                checked={activeDriverRolesCheckbox}
                                name="active"
                                disabled={modalRolesType === "details"}
                              />
                            )}
                            label={t(DriverModuleMessages.detailsDriverRoleActive)}
                          />
                        </Grid>
                        
                      </Grid>
                    </DialogContentText>
                  </Form>

                </TabPanel>
                
              </Container>

            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                  modalRolesType === "details" || selectedTab === 0
                    ? <></>
                    : modalRolesType === "register" || modalRolesType === "update"
                      ? (
                        modalRolesType === "register"
                          ?
                          <Button disableRipple type="submit" color="primary" onClick={() => formRefRoles.current?.submitForm()}>
                            {t(GlobalMessages.register)}
                          </Button>
                          :
                          <Button disableRipple type="submit" color="primary" onClick={() => formRefRoles.current?.submitForm()}>
                            {t(GlobalMessages.updateRegister)}
                          </Button>
                      )
                      : (
                        <Button disableRipple type="submit" color="primary" onClick={() => formRefRoles.current?.submitForm()}>
                          {t(GlobalMessages.save)}
                        </Button>
                      )
                }
              </div>
              {
                selectedTab === 0
                  ? (
                    <Button disableRipple onClick={() => handleCloseModalFormDriverRoles()} color="primary">{t(GlobalMessages.close)}</Button>
                  )
                  : (
                    <Button disableRipple onClick={() => handleCloseModalFormDriverRoles()} color="primary">{t(GlobalMessages.back)}</Button>
                  )
              }
            </DialogActions>
          </Dialog>
        </ContainerModalFormDriverRoles>
      </Container>
    </>
  );
};

export default Drivers;
