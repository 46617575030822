import React, { useCallback, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";

// region Libraries
import * as Yup from "yup";
import { PublicClientApplication } from "@azure/msal-browser";
import i18n from "i18next";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, LoginMessages, YupMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Atoms
import Logo from "@atoms/FleetLogo";
import InputPassword from "@atoms/Login/InputPassword";
import InputEmail from "@atoms/Login/InputEmail";
import ButtonLoginFleet from "src/atoms/Login/ButtonLoginFleet";
import ButtonLoginMicrosoft from "@atoms/Login/ButtonLoginMicrosoft";
import DividerWithText from "@atoms/DividerWithText";
// endregion Atoms
// region Components
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import LanguageIcon from "@components/LanguageIcon";
// endregion Components
// region Constants
import { AdminUserEmails } from "@shared/constants/user.enum";
// endregion Constants
// region Interface
import { AuthStateResponse } from "@shared/interfaces/auth.interface";
// endregion Interface
// region Unform
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
// endregion Unform
// region Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
import { useParams } from "@hooks/useParams";
import getValidationErrors from "@hooks/getValidationErrors";
// endregion Hooks
// region Services
import { socket } from "@services/websocketContext";
import api from "@services/api";
// endregion Services
// region Utils
import utils, { StandardReturnObject } from "@utils/useful-functions";
// endregion Utils
// region Styles
import * as Styled from "./styles";
// endregion Styles

// Create expire session function to avoid code repetition, including the dialog
const handleUnauthorized = () => {

  const expireSession = () => {

    localStorage.removeItem("@Fleet:token");
    localStorage.removeItem("@Fleet:currentSurvey");
    localStorage.removeItem("@Fleet:google");

    window.location.href = "/";

    socket.emit("logout");

  };

  ReactDOM.render(
    <DialogConfirmAction
      open={true}
      title={i18n.t(LoginMessages.expiredSession)}
      onClose={() => ""}
      actions={[
        { text: i18n.t(LoginMessages.goToLoginPage), action: () => expireSession() }
      ]}
    >
      {i18n.t(LoginMessages.expiredSessionInstructions)}
    </DialogConfirmAction>,
    document.getElementById("extra")
  );
};

// Listen to invalid token event from server and redirect to login page
socket.on("invalidToken", (token: string) => {

  // Call handleUnauthorized function only if user is logged in
  if (localStorage.getItem("@Fleet:token") && token !== null) {

    handleUnauthorized();

    // Remove listener to avoid multiple calls
    socket.off("invalidToken");
  }
});

// Exception to all errors of API (Axios)
api.interceptors.response.use((response) => response, (error) => {

  // If occurs unauthorized error, show dialog and redirect to login page
  if (error.response && error.response.data.statusCode === 401) {

    handleUnauthorized();

    return;
  }

  throw error;
});

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {

  // region Hooks
  const { addToast } = useToast();
  const { signIn } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation();
  const { getParamsObj } = useParams();
  // endregion Hooks
  // region States
  const [loading, setLoading] = useState(false);
  // endregion States
  // region Functions

  /**
   * Handle submit form to log in in Fleet directly
   */
  const handleFleetLogin = useCallback(
    async (formData: SignInFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define form rules
        const schema = Yup.object().shape({
          email: Yup.string().trim().required(t(YupMessages.emailRequired)).email(t(YupMessages.invalidEmail)),
          password: Yup.string().required(t(YupMessages.passwordRequired)).min(6, t(YupMessages.passwordMinLength))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        setLoading(true);

        // Validate type of login just for non-support admin user
        if (formData.email !== AdminUserEmails.ADMIN_SUPPORT){

          // Validate if azure_sso_enabled is true
          const params = await getParamsObj(); // Get client params

          // Check if azure_sso_enabled is true
          // If true, show message explaining that login has to be done via SSO
          if (utils.convertToBoolean(params.azure_sso_enabled)) {

            addToast({ type: "info", title: t(LoginMessages.ssoRequiredForLogin) });

            return;
          }
        }

        // Request login access
        await signIn({ email: formData.email, password: formData.password });

        history.push("/main");

      } catch (error: any) {

        // Err of form validation according shape
        if (error instanceof Yup.ValidationError) {

          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        utils.handleStandardError(error, t, addToast);

      } finally {
        setLoading(false);
      }

    }, [addToast, history, signIn, t, getParamsObj]
  );

  /**
   * Handle Microsoft login
   */
  const handleMicrosoftLogin = useCallback(async () => {

    try {

      setLoading(true);

      const params = await getParamsObj(); // Get client params

      if (utils.convertToBoolean(params.azure_sso_enabled)) {

        // Get params to log in with Microsoft
        const clientId = params.azure_client_id;
        const tenantId = params.azure_tenant_id;

        // Check if clientId and tenantId are valid
        if (!clientId || !tenantId) {

          utils.handleStandardError(null, t, addToast);

          return;
        }

        // Define the login request configuration
        const loginRequest = {
          scopes: ["openid", "profile", "User.Read"]
        };

        // Create MSAL config / instance
        const msalConfig = {
          auth: {
            clientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri: window.location.origin
          }
        };
        const msalInstance = new PublicClientApplication(msalConfig);

        msalInstance.initialize().then(() => {
          msalInstance.loginPopup(loginRequest)
            .then(async (loginResponse) => {
              const requestObj = {
                "name": loginResponse.account.name,
                "email": loginResponse.account.username,
                "external_id": loginResponse.account.homeAccountId
              };
              const { data }: { data: StandardReturnObject<AuthStateResponse>} = await api.post("auth/loginWithSSO", requestObj);

              if (data.status === "success") {
                await signIn ({ email: "", password: "" }, data.result);
                history.push("/main");
              } else addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
            }).catch(() => {
              addToast({ type: "error", title: t(LoginMessages.ssoLoginFailed) });
            });
        }).catch(() => {
          addToast({ type: "error", title: t(LoginMessages.ssoLoginFailed) });
        });

      } else {
        addToast({ type: "info", title: t(LoginMessages.ssoAccessNotEnabled) });
      }

    } catch (error) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoading(false);
    }

  }, [addToast, getParamsObj, t, history, signIn]);

  // endregion Functions

  return (
    <Styled.Container>
      <Styled.Content>
        <Logo />
        <Form ref={formRef} onSubmit={handleFleetLogin}>
          <InputEmail name="email" />
          <InputPassword name="password" />
          <ButtonLoginFleet type="submit" loading={loading}>
            {t(LoginMessages.login)}
          </ButtonLoginFleet>
          <Styled.ForgotPassword>
            <Link to="/forgot-password">
              {t(LoginMessages.forgotMyPassword)}
            </Link>
          </Styled.ForgotPassword>
          <DividerWithText>{t(LoginMessages.loginOptionsText)}</DividerWithText>
          <ButtonLoginMicrosoft loading={loading} onClick={handleMicrosoftLogin} disabled={loading}>
            {t(LoginMessages.loginWithMicrosoft)}
          </ButtonLoginMicrosoft>
        </Form>
      </Styled.Content>
      <LanguageIcon />
    </Styled.Container>
  );
};

export default SignIn;
