/* eslint-disable camelcase, max-classes-per-file */
import {
  Column,
  CreateDateColumn,
  Entity,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";

@Entity("reg_drivers_roles")
export class DriversRoles {

  @PrimaryGeneratedColumn("uuid")
    id_driver_role: string = "";

  @Column({ type: "varchar", nullable: false })
    description: string = "";

  @Column({ type: "boolean", nullable: false, default: true })
    active: boolean = false;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date: Date = new Date();

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date: Date = new Date();

}
