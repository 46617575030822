import React, { useEffect, useMemo, useRef, useState } from "react";

// region Imports - External libraries
import _ from "lodash";
import * as dateFns from "date-fns";
import Loading from "react-fullscreen-loading";
// endregion Imports - External libraries
// region Imports - Shared
import { VehicleStatesTypesID } from "@shared/constants/vehicle-states-types.enum";
import { Travel } from "@shared/interfaces/travel.interface";
import { TravelFilterOptionsInterface } from "@shared/interfaces/travel-filter-options.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
import { Regional } from "@shared/entities/reg_regionals.entity";
import { Group } from "@shared/entities/reg_groups.entity";
import { VehicleType } from "@shared/interfaces/vehicle-type.interface";
import { Plant } from "@shared/entities/reg_plants.entity";
// endregion Imports - Shared
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Organisms
import GenericQueryFilter, {
  IDateRangeOptions,
  IMultipleSelectionOptions,
  IOnFilterReturn
} from "@organisms/GenericQueryFilter";
// endregion Imports - Organisms

// region Interfaces
interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (
    numberOfFilteredOptions: number,
    travelData: Travel[],
    loadMore: boolean,
    startDate: string,
    finishDate: string
  ) => void;
  loadMoreToggle: boolean;
}
// endregion Interfaces

const QueryFilterTravelHistory: React.FC<IProps> = ({ open, onClose, onFilter, loadMoreToggle }) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks

  // region useState
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [optionsVehicleTypeFilter, setOptionsVehicleTypeFilter] = useState<VehicleType[]>([] as Array<VehicleType>);
  const [optionsDocumentFilter, setOptionsDocumentFilter] = useState<string[]>([] as Array<string>);
  const [optionsConstructionFilter, setOptionsConstructionFilter] = useState<string[]>([] as Array<string>);
  const [optionsClientFilter, setOptionsClientFilter] = useState<string[]>([] as Array<string>);
  const [optionsPlantFilter, setOptionsPlantFilter] = useState<Plant[]>([] as Array<Plant>);
  const [optionsRegionalFilter, setOptionsRegionalFilter] = useState<Regional[]>([] as Array<Regional>);
  const [optionsGroupFilter, setOptionsGroupFilter] = useState<Group[]>([] as Array<Group>);

  const [loadingConstructionFilter, setLoadingConstructionFilter] = useState(false);
  const [loadingClientFilter, setLoadingClientFilter] = useState(false);
  const [loadingVehicleFilter, setLoadingVehicleFilter] = useState(true);
  const [loadingVehicleTypeFilter, setLoadingVehicleTypeFilter] = useState(true);
  const [loadingDocumentFilter, setLoadingDocumentFilter] = useState(true);
  const [loadingPlantFilter, setLoadingPlantFilter] = useState(true);
  const [loadingRegionalFilter, setLoadingRegionalFilter] = useState(true);
  const [loadingGroupFilter, setLoadingGroupFilter] = useState(true);
  const [loadingTravels, setLoadingTravels] = useState(false);

  // region Constants
  const paramNameStartDate = "startDate";
  const paramNameEndDate = "finishDate";
  const paramNameConstruction = "constructionNames";
  const paramNameClient = "clients";
  const paramNamePlant = "idPlants";
  const paramNameGroups = "idGroups";
  const paramNameRegional = "idRegionals";
  const paramNameVehicle = "idVehicles";
  const paramNameVehicleType = "idVehicleTypes";
  const paramNameDocumentNumber = "numNf";
  const recordLimitPerFetch = 200;
  const paramValueNotIntegrated = "onlyNotIntegrated";

  const constructionFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingConstructionFilter,
    labelName: t(FilterMessages.optionConstructions),
    paramName: paramNameConstruction,
    values: optionsConstructionFilter.map((construction) => ({ value: construction, label: construction })),
    refreshOptions: {
      refresh: (currentValues) => {
        const startDate = currentValues.find((item) => item.paramName === paramNameStartDate)?.paramValue as string;
        const endDate = currentValues.find((item) => item.paramName === paramNameEndDate)?.paramValue as string;

        if (!startDate || !endDate) return;

        updateConstructionClientsAndDocumentsOptionsFilters(startDate, endDate).then();
      },
      dependentParamNames: [paramNameStartDate, paramNameEndDate]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loadingConstructionFilter, optionsConstructionFilter, t]);

  const documentFilterOptions: IMultipleSelectionOptions = useMemo (() => ({
    isLoadingValues: loadingDocumentFilter,
    labelName: t(FilterMessages.optionNumDoc),
    paramName: paramNameDocumentNumber,
    values: optionsDocumentFilter.map((numDoc) =>
      (numDoc === paramValueNotIntegrated ? {value: numDoc, label: t(FilterMessages.noNumDoc) } : { value: numDoc, label: numDoc }))
  }), [loadingDocumentFilter, optionsDocumentFilter, t]);

  const clientFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingClientFilter,
    labelName: t(FilterMessages.optionClients),
    paramName: paramNameClient,
    values: optionsClientFilter.map((client) => ({ value: client, label: client }))
  }), [loadingClientFilter, optionsClientFilter, t]);

  const plantFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingPlantFilter,
    labelName: t(FilterMessages.optionPlants),
    paramName: paramNamePlant,
    values: optionsPlantFilter.map(
      (plant) => ({
        value: plant!.location!.id_location!,
        label: plant.name!
      })
    )
  }), [loadingPlantFilter, optionsPlantFilter, t]);

  const regionalFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingRegionalFilter,
    labelName: t(FilterMessages.optionRegionals),
    paramName: paramNameRegional,
    values: optionsRegionalFilter.map(
      (regional) => ({
        value: regional.id_regional!,
        label: `${regional.code} - ${regional.name}`
      })
    )
  }), [loadingRegionalFilter, optionsRegionalFilter, t]);

  const groupFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingGroupFilter,
    labelName: t(FilterMessages.optionGroups),
    paramName: paramNameGroups,
    values: optionsGroupFilter.map(
      (group) => ({
        value: group.id_group!,
        label: `${group.code} - ${group.name}`
      })
    )
  }), [loadingGroupFilter, optionsGroupFilter, t]);

  const vehicleFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleFilter,
    labelName: t(FilterMessages.optionVehicles),
    paramName: paramNameVehicle,
    // eslint-disable-next-line max-len
    values: optionsVehicleFilter.map((vehicle) => ({ value: vehicle.id_vehicle, label: `${vehicle.code} - ${vehicle.description}` }))
  }), [loadingVehicleFilter, optionsVehicleFilter, t]);

  const vehicleTypeFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleTypeFilter,
    labelName: t(FilterMessages.optionVehicleTypes),
    paramName: paramNameVehicleType,
    values: optionsVehicleTypeFilter.map((vehicleType) => ({ value: vehicleType.id_vehicle_type!, label: vehicleType.description }))
  }), [loadingVehicleTypeFilter, optionsVehicleTypeFilter, t]);

  const defaultStartDate = dateFns.subDays(new Date(), 1);

  defaultStartDate.setHours(0, 0, 0, 0);

  const defaultEndDate = new Date();

  defaultEndDate.setHours(23, 59, 59, 999);

  const dateRangeOptions: IDateRangeOptions = {
    labelName: t(FilterMessages.dateRangeLabel),
    paramNameStartDate,
    paramNameEndDate,
    defaultValues: {
      startDate: utils.convertDateToISOWithTimezone(defaultStartDate),
      endDate: utils.convertDateToISOWithTimezone(defaultEndDate)
    },
    maxRange: "7D"
  };

  const initialParamsFilter: IParamQueryValues[] = [
    { paramName: paramNameStartDate, paramValue: utils.convertDateToISOWithTimezone(defaultStartDate) },
    { paramName: paramNameEndDate, paramValue: utils.convertDateToISOWithTimezone(defaultEndDate) }
  ];
  // endregion Constants
  // region Refs
  const paramsFilterRef = useRef<IParamQueryValues[]>(initialParamsFilter);
  const offsetRef = useRef<number|undefined>(undefined);
  // endregion Refs
  // region Functions

  /**
   * Load construction and client filter options according to the selected date range.
   */
  const updateConstructionClientsAndDocumentsOptionsFilters = async (startDate: string, finishDate: string) => {

    setLoadingConstructionFilter(true);
    setLoadingClientFilter(true);
    setLoadingDocumentFilter(true);

    try {

      const { data } = await api.get(
        "travels/get-travel-filter-options", {
          params: {
            startDate,
            finishDate,
            finished: true
          }
        }
      );

      if (data.status === "success") {
        const travelFilterOptions = data.result as TravelFilterOptionsInterface;

        setOptionsClientFilter(travelFilterOptions.clients);
        setOptionsConstructionFilter(travelFilterOptions.constructionNames);

        if (travelFilterOptions?.documentsNumbers) {
          let options = travelFilterOptions.documentsNumbers;

          options.unshift(paramValueNotIntegrated);
          setOptionsDocumentFilter(options);
        }

        return;
      }
      setOptionsClientFilter([]);
      setOptionsConstructionFilter([]);
      setOptionsDocumentFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingConstructionFilter(false);
      setLoadingClientFilter(false);
      setLoadingDocumentFilter(false);
    }
  };

  /**
   * Load vehicle options from the API.
   */
  const loadVehicleOptions = async () => {

    try {

      // Get all vehicles
      const { data } = await api.get("vehicles/read", { params: { linkedHardware: true, onlyLabel: true } });

      if (data.status === "success") setOptionsVehicleFilter(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingVehicleFilter(false);
    }
  };

  /**
   * Load vehicle type options from the API.
   */
  const loadVehicleTypeOptions = async () => {
    try {

      // Get all alert types
      const { data } = await api.get("vehicles/types/read", { params: { translate: true } });

      if (data.status === "success") {
        setOptionsVehicleTypeFilter(data.result);

        return;
      }

      setOptionsVehicleTypeFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingVehicleTypeFilter(false);
    }
  };

  /**
   * Load plant options from the API.
   */
  const loadPlantOptions = async () => {
    try {

      // Get all plants
      const { data } = await api.get("plants/read", { params: { linkedLocation: true } });

      if (data.status === "success") setOptionsPlantFilter(data.result);
      else setOptionsPlantFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingPlantFilter(false);
    }
  };

  /**
   * Load regional options from the API.
   */
  const loadRegionalOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("regionals/read");

      if (data.status === "success") setOptionsRegionalFilter(data.result as Regional[]);
      else setOptionsRegionalFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingRegionalFilter(false);
    }
  };

  /**
   * Load group options from the API.
   */
  const loadGroupOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("groups/read");

      if (data.status === "success") setOptionsGroupFilter(data.result as Group[]);
      else setOptionsGroupFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingGroupFilter(false);
    }
  };

  const handleFilter = async (paramsData: IParamQueryValues[], loadMore: boolean) => {

    setLoadingTravels(true);

    paramsFilterRef.current = paramsData;

    if (loadMore) offsetRef.current! += recordLimitPerFetch;
    else offsetRef.current = 0;

    try {

      let paramWithoutDocumentNumber = _.cloneDeep(paramsData);

      const foundParam = paramWithoutDocumentNumber.some(
        (param) => param.paramName === paramNameDocumentNumber && param.paramValue.includes(paramValueNotIntegrated)
      );

      let params = paramWithoutDocumentNumber.find(
        (item) => (item.paramName === paramNameDocumentNumber && item.paramValue.includes(paramValueNotIntegrated))
      );

      if (Array.isArray(params?.paramValue)) {
        params!.paramValue = params!.paramValue.filter(value => value !== paramValueNotIntegrated);
      }

      const mappedParams = paramWithoutDocumentNumber.reduce((acc, param) => {
        acc[param.paramName] = param.paramValue;

        return acc;
      }, {} as { [key: string]: string | string[] });

      const { data } = await api.post("travels/get-filtered-by", {
        ...mappedParams,
        vehicleStates: VehicleStatesTypesID.A_CAMINHO,
        finished: true,
        offset: offsetRef.current,
        limit: recordLimitPerFetch,
        onlyNotIntegrated: foundParam
      });

      const validParamsData = paramWithoutDocumentNumber.filter((item) => !_.isEmpty(item.paramValue));

      const validParamsDataWithoutDate = paramWithoutDocumentNumber.filter(
        (item) => item.paramName !== paramNameStartDate && item.paramName !== paramNameEndDate
      );

      if (data.status !== "success") addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      const startDateValue = validParamsData.find((item) => item.paramName === paramNameStartDate)?.paramValue as string;
      const finishDateValue = validParamsData.find((item) => item.paramName === paramNameEndDate)?.paramValue as string;

      onFilter(
        validParamsDataWithoutDate.length,
        data.result ?? [] as Travel[],
        loadMore,
        startDateValue,
        finishDateValue
      );

      return { status: "success" } as IOnFilterReturn;
    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      return { status: "error" } as IOnFilterReturn;
    } finally {
      setLoadingTravels(false);
    }
  };
  // endregion Functions
  // region Effects

  // Load options from the API
  useEffect(() => {

    Promise.all([
      loadVehicleOptions(),
      loadVehicleTypeOptions(),
      updateConstructionClientsAndDocumentsOptionsFilters(
        utils.convertDateToISOWithTimezone(defaultStartDate),
        utils.convertDateToISOWithTimezone(defaultEndDate)
      ),
      loadPlantOptions(),
      loadRegionalOptions(),
      loadGroupOptions()
    ]).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (offsetRef.current === undefined) {
      offsetRef.current = 0;

      handleFilter(paramsFilterRef.current, false).then();

      return;
    }

    handleFilter(paramsFilterRef.current, true).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreToggle]);
  // endregion Effects

  return (
    <>
      <Loading loading={loadingTravels} />
      <GenericQueryFilter
        open={open}
        onClose={onClose}
        applicationTypeID={FilterApplicationTypesID.TRAVEL_HISTORY}
        onFilter={(paramsData) => handleFilter(paramsData, false)}
        multipleSelectionOptions={[
          constructionFilterOptions,
          clientFilterOptions,
          vehicleFilterOptions,
          vehicleTypeFilterOptions,
          documentFilterOptions,
          plantFilterOptions,
          regionalFilterOptions,
          groupFilterOptions
        ]}
        dateRangeOptions={dateRangeOptions}
      />
    </>
  );
};

export default QueryFilterTravelHistory;
