export enum DataTableMessages {
  status = "dataTable.status",
  actions = "dataTable.actions",
  buttonsAddNew = "buttons.addNew",
  buttonsExport = "buttons.export",
  buttonsPrint = "buttons.print",
  buttonsRefresh = "buttons.reload",
  emptyTable = "dataTable.emptyTable",
  info = "dataTable.info",
  infoEmpty = "dataTable.infoEmpty",
  infoFiltered = "dataTable.infoFiltered",
  lengthMenu = "dataTable.lengthMenu",
  loadingRecords = "dataTable.loadingRecords",
  processing = "dataTable.processing",
  zeroRecords = "dataTable.zeroRecords",
  search = "dataTable.search",
  searchPlaceholder = "dataTable.searchPlaceholder",
  filterTitle = "dataTable.filterTitle",
  filterApplied = "dataTable.filterApplied",
  filterNoneApplied = "dataTable.filterNoneApplied",
  filterCorrespondence = "dataTable.filterCorrespondence",
  filterRemoveAll = "dataTable.filterRemoveAll",
  filterAdd = "dataTable.filterAdd",
  filterApply = "dataTable.filterApply",
  filterNewFilterField = "dataTable.filterNewFilterField",
  filterOperatorEquals = "dataTable.filterOperatorEquals",
  filterOperatorNotEquals = "dataTable.filterOperatorNotEquals",
  filterOperatorContains = "dataTable.filterOperatorContains",
  filterOperatorNotContains = "dataTable.filterOperatorNotContains",
  filterOperatorStartsWith = "dataTable.filterOperatorStartsWith",
  filterOperatorGreaterThan = "dataTable.filterOperatorGreaterThan",
  filterOperatorLessThan = "dataTable.filterOperatorLessThan",
  filterOperatorGreaterThanOrEqual = "dataTable.filterOperatorGreaterThanOrEqual",
  filterOperatorLessThanOrEqual = "dataTable.filterOperatorLessThanOrEqual",
  filterProperty = "dataTable.filterProperty",
  filterOperator = "dataTable.filterOperator",
  filterValue = "dataTable.filterValue",
  moreRecords = "dataTable.moreRecords",
  buttonsViewRoles = "buttons.buttonsViewRoles",
  reportDriverRolesLabel = "dataTable.reportDriverRolesLabel"
}

export type TranslationDataTableMessages = {
  [key in DataTableMessages]: string;
}
