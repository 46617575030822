import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { HardwareTypes, HardwareTypesID } from "@shared/constants/hardware-types.enum";
import { HardwareModels } from "@shared/constants/hardware-models.enum";

import { Hardware, HardwareType } from "@shared/interfaces/hardware.interface";
import { SimCard } from "@shared/interfaces/sim-card.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { Driver } from "@shared/interfaces/driver.interface";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import * as Yup from "yup";
import * as dateFns from "date-fns";
import * as _ from "lodash";

import { Checkbox, Select, TextField } from "unform-material-ui";

import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import useTranslation from "src/translations/useTranslation";
import { DataTableMessages, GlobalMessages, HardwareModuleMessages, ToastMessages } from "@shared/languages/interfaces";
import ButtonTable from "../../../../components/Button/ButtonTable";
import DialogConfirmAction from "../../../../components/Dialog/ConfirmAction";

import api from "../../../../services/api";
import utils from "../../../../utils/useful-functions";
import getValidationErrors from "../../../../hooks/getValidationErrors";
import { useToast } from "../../../../hooks/useToast";

import { ContainerModalFormHardware } from "./styles";

interface ValidateFields {devId: boolean;}

const HardwaresList: React.FC = () => {

  const { addToast } = useToast();
  const { t, i18n } = useTranslation();

  /* region States */
  const [optionsTypesHardware, setOptionsTypesHardware] = useState<HardwareType[]>([] as HardwareType[]);
  const [optionsVehicles, setOptionsVehicles] = useState<Vehicle[]>([] as Vehicle[]);
  const [optionsSimCards, setOptionsSimCards] = useState<SimCard[]>([] as SimCard[]);
  const [optionsDrivers, setOptionsDrivers] = useState<Driver[]>([] as Driver[]);

  const [activeOptionTypeHardware, setActiveOptionTypeHardware] = useState<HardwareType>({} as HardwareType);
  const [activeOptionVehicle, setActiveOptionVehicle] = useState<Vehicle>({} as Vehicle);
  const [activeOptionSimCard, setActiveOptionSimCard] = useState<SimCard>({} as SimCard);
  const [activeOptionDriver, setActiveOptionDriver] = useState<Driver>({} as Driver);

  const [openOptionsTypesHardware, setOpenOptionsTypesHardware] = useState(false);
  const [openOptionsVehicles, setOpenOptionsVehicles] = useState(false);
  const [openOptionsSimCards, setOpenOptionsSimCards] = useState(false);
  const [openOptionsDrivers, setOpenOptionsDrivers] = useState(false);

  const loadingOptionsTypesHardware = openOptionsTypesHardware && optionsTypesHardware.length === 0;
  const loadingOptionsVehicles = openOptionsVehicles && optionsVehicles.length === 0;
  const loadingOptionsSimCards = openOptionsSimCards && optionsSimCards.length === 0;
  const loadingOptionsDrivers = openOptionsDrivers && optionsDrivers.length === 0;
  const [loadingHardwareList, setLoadingHardwareList] = useState(false);
  const [loadingValidateFieldDevId, setLoadingValidateDevId] = useState(false);

  const [hardwares, setHardwares] = useState<Hardware[]>([] as Array<Hardware>);
  const [hardwareDetails, setHardwareDetails] = useState<Hardware>({} as Hardware);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [openModalFormHardware, setOpenModalFormHardware] = useState(false);

  const [modalType, setModalType] = useState<"details" | "update" | "register">("details");

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>(
    {} as JQuery<HTMLTableRowElement>
  );

  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(true);
  const [blockCapableCheckbox, setBlockCapableCheckbox] = useState<boolean>(false);
  const [validateFields, setValidateFields] = useState<ValidateFields>({ devId: false });
  /* endregion States */
  /* region Forms */
  const formRef = useRef<FormHandles>(null);

  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: Hardware, action: "details" | "register" | "update") => {

      try {

        // Revalidate unique fields (If not validate yet)
        if (!validateFields.devId && formData.dev_id.trim().length) await validations.validateDevId(formData.dev_id);

        // Define the validation types
        const schema = Yup.object().shape({
          type: Yup.string().trim().required(t(HardwareModuleMessages.hardwareFormTypeRequired)),
          brand: Yup.string().trim().required(t(HardwareModuleMessages.hardwareFormBrandRequired)),
          model: Yup.string().trim().required(t(HardwareModuleMessages.hardwareFormModelRequired)),
          dev_id: Yup.string().trim().test("validateDevId", formRef.current?.getFieldError("dev_id"),
            () => !(formRef.current?.getFieldError("dev_id")?.length))
            .required(t(HardwareModuleMessages.hardwareFormDevIdRequired)),
          purchase_date: Yup.string().trim().required(t(HardwareModuleMessages.hardwareFormPurchaseDateRequired))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update hardware (According action selected)
        if (action === "register") await createHardware(formData);
        else await updateHardware(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    },
    validateDevId: async (devId: string) => {

      setValidateFields({ ...validateFields, devId: false });

      // Validate dev id just have 3 or more characters in on blur
      if (devId.trim().length >= 3 && devId.trim() !== hardwareDetails.dev_id) {

        try {

          setLoadingValidateDevId(true);

          // Verify if DEV ID is already registered
          const { data } = await api.get(`hardwares/verify-unique-field/devid/${devId.trim()}`);

          if (data.status === "alert") formRef.current?.setFieldError("dev_id", t(HardwareModuleMessages.hardwareFormDevIdAlreadyExists));
          else formRef.current?.setFieldError("dev_id", "");

          setValidateFields({ ...validateFields, devId: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else {
            addToast(
              { type: "error", title: error.response.data.backend, description: error.response.data.message }
            );
          }
        } finally {
          setLoadingValidateDevId(false);
        }
      }
    }
  };
  /* endregion Forms */
  /* region Constants */

  // REVIEW Change fixed values to database get values
  // Some dropdowns values, we used a fixed values
  const fixedValues = {
    Ibutton: {
      brand: t(GlobalMessages.other),
      model: t(HardwareModuleMessages.model)
    },
    OtherTypes: {
      brands: ["Suntech", "Auto Sender", "Fleet", "Queclink"],
      models: [
        HardwareModels["ST 300HD"],
        HardwareModels["ST 310U"],
        HardwareModels["ST 310UC2"],
        HardwareModels["ST 310U EVT"],
        HardwareModels["ST 340UR"],
        HardwareModels["ST 4315U EVT"],
        HardwareModels["ST 4315U"],
        HardwareModels["ST 4305"],
        HardwareModels["ST 8310U EVT"],
        HardwareModels["ST 8310U"],
        HardwareModels["ST 8300"],
        HardwareModels["2684"],
        HardwareModels["0721"],
        HardwareModels["RAS_1"],
        HardwareModels["GV350MG"],
        HardwareModels["GV350CEU"]
      ] as string[]
    }
  };

  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-hardware-list-details",
      callback: (rowData: Hardware) => handleHardwareDetails(rowData)
    },
    {
      ref: ".modules-hardware-list-delete",
      callback: (rowData: Hardware) => handleHardwareDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Dev ID
      title: t(HardwareModuleMessages.devId),
      data: (Hardware: Hardware) => Hardware.dev_id,
      defaultContent: "",
      filterable: true,
      propertyName: "dev_id"
    },
    { // Tipo de hardware
      title: t(HardwareModuleMessages.type),
      data: (Hardware: Hardware) => Hardware.type.description,
      filterable: true,
      propertyName: "type.description"
    },
    { // Fornecedor
      title: t(HardwareModuleMessages.brand),
      data: (Hardware: Hardware) => Hardware.brand,
      filterable: true,
      propertyName: "brand"
    },
    { // Data de registro
      title: t(HardwareModuleMessages.registrationDate),
      data: (hardware: Hardware) => hardware.registration_date,
      render: (data, type) => (data
        ? utils.formatDateIfHave(new Date(data), "fullDate")
        : ""),
      filterable: true,
      defaultContent: "",
      propertyName: "registration_date"
    },
    { // Modelo
      title: t(HardwareModuleMessages.model),
      data: (Hardware: Hardware) => Hardware.model,
      filterable: true,
      propertyName: "model"
    },
    { // Ativo
      title: t(DataTableMessages.status),
      data: (Hardware: Hardware) => (
        ReactDOMServer.renderToString(
          <b style={{ color: Hardware.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>{Hardware.active
            ? t(GlobalMessages.active).toUpperCase() : t(GlobalMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      filterable: true,
      propertyName: "active"
    },
    { // Faz bloqueio
      title: t(HardwareModuleMessages.doBlock),
      data: (Hardware: Hardware) => (
        ReactDOMServer.renderToString(
          <b style={{
            color: Hardware.block_capable ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)"
          }}
          >{Hardware.block_capable ? t(GlobalMessages.yes).toUpperCase() : t(GlobalMessages.no).toUpperCase()}
          </b>
        )
      ),
      width: "150px",
      filterable: true,
      propertyName: "block_capable"
    },
    { // Veículo
      title: t(HardwareModuleMessages.vehicle),
      data: (Hardware: Hardware) => Hardware?.vehicle?.code || t(HardwareModuleMessages.hardwareDataTableNoLinkedVehicle),
      filterable: true,
      propertyName: "vehicle.code"
    },
    { // Chip
      title: t(HardwareModuleMessages.simCard),
      data: (Hardware: Hardware) => Hardware?.sim_card?.caller_id || t(HardwareModuleMessages.hardwareDataTableNoLinkedSimCard),
      filterable: true,
      propertyName: "sim_card.caller_id"
    },
    { // Motorista
      title: t(HardwareModuleMessages.driver),
      data: (Hardware: Hardware) => Hardware?.driver?.name || t(HardwareModuleMessages.hardwareDataTableNoLinkedDriver),
      filterable: true,
      propertyName: "driver.name"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable
              className="action-button modules-hardware-list-details"
            ><FormatListBulletedIcon />
            </ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable
              className="action-button modules-hardware-list-delete"
            ><DeleteForeverIcon />
            </ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleCreateHardware()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readHardwares()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_hardwares_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */

  /* region Functions */
  // Actions hardwares list
  const handleHardwareDetails = useCallback((hardware: Hardware) => {

    setHardwareDetails(hardware);

    // NOT NULL fields
    setActiveCheckbox(hardware.active);
    setBlockCapableCheckbox(hardware.block_capable);
    setActiveOptionTypeHardware(hardware.type);

    // Optional fields
    if (hardware?.vehicle) setActiveOptionVehicle(hardware.vehicle);
    if (hardware?.sim_card) setActiveOptionSimCard(hardware.sim_card);
    if (hardware?.driver) setActiveOptionDriver(hardware.driver);

    setValidateFields({ devId: true });

    setModalType("details");
    setOpenModalFormHardware(true);

  }, []);
  const handleHardwareDelete = useCallback((hardware: Hardware) => {

    setHardwareDetails(hardware);
    setOpenDialogConfirmDelete(true);

  }, []);

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setHardwareDetails({} as Hardware);
    setActiveOptionTypeHardware({} as HardwareType);
    setActiveOptionSimCard({} as SimCard);
    setActiveOptionVehicle({} as Vehicle);
    setActiveOptionDriver({} as Driver);

    setValidateFields({ devId: false });
    setOpenModalFormHardware(false);
    setBlockCapableCheckbox(false);

  }, []);

  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType) {
    case "details":
      return detailValue;
    case "update":
      return updateValue;
    case "register":
      return registerValue;
    default:
      return "S/N";
    }
  }, [modalType]);

  /** Get all hardwares data
   */
  const readHardwares = useCallback(async () => {

    try {

      // Get all hardwares
      setLoadingHardwareList(true);
      const { data } = await api.get("hardwares/read");

      if (data.status === "success") setHardwares(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingHardwareList(false);
    }

  }, [addToast, t]);

  /** Create a hardware
   * @param hardware Hardware to create
   */
  const createHardware = useCallback(async (hardware: Hardware) => {

    try {

      const insertHardware = { ...hardware };

      // Remove properties without value
      Object.keys(insertHardware).forEach((key) => {
        if (insertHardware[key].length <= 0) delete insertHardware[key];
      });

      if (insertHardware.type) insertHardware.type = activeOptionTypeHardware;
      if (insertHardware.vehicle) insertHardware.vehicle = activeOptionVehicle;
      if (insertHardware.sim_card) insertHardware.sim_card = activeOptionSimCard;
      if (insertHardware.driver) insertHardware.driver = activeOptionDriver;

      if (insertHardware.type.id_hardware_type === HardwareTypesID.IBUTTON) {

        // Convert Dev ID to Ibutton code and set into object
        insertHardware.ibutton_code = utils.convertDevIDToIbuttonCode(insertHardware.dev_id);
      }

      // Create a hardware
      setLoadingHardwareList(true);
      const { data } = await api.post("hardwares/create", insertHardware);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertHardware.id_hardware = data.result.id_hardware;

        if (!hardwares.length) readHardwares();
        else {

          // Add inserted hardware in the table
          table.row.add(insertHardware);
          table.draw();
        }

        // Search the inserted hardware and list
        table.search(hardware.dev_id).draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingHardwareList(false);
    }

  }, [
    addToast, table, hardwares, readHardwares, handleCloseModalForm,
    activeOptionTypeHardware, activeOptionSimCard, activeOptionVehicle, activeOptionDriver, t
  ]);

  /*
   * Open modal to create new hardware
   * */
  const FhandleCreateHardware = () => {
    setModalType("register");
    setOpenModalFormHardware(true);
  };

  /** Update a hardware
   * @param hardware Hardware to update
   */
  const updateHardware = useCallback(async (hardware: Hardware) => {

    try {

      const updateHardware = { ...hardware };

      if (updateHardware.type) updateHardware.type = activeOptionTypeHardware;
      if (updateHardware.vehicle) updateHardware.vehicle = activeOptionVehicle;
      if (updateHardware.sim_card) updateHardware.sim_card = activeOptionSimCard;
      if (updateHardware.driver) updateHardware.driver = activeOptionDriver;

      // Optional fields when not pass nothing
      if (!updateHardware.vehicle) updateHardware.vehicle = { ...{}, id_vehicle: "" } as Vehicle;
      if (!updateHardware.sim_card) updateHardware.sim_card = { ...{}, id_sim_card: "" } as SimCard;
      if (!updateHardware.driver) updateHardware.driver = { ...{}, id_driver: "" } as Driver;

      if (updateHardware.type.id_hardware_type === HardwareTypesID.IBUTTON) {
        // Convert Dev ID to Ibutton code and set into object
        updateHardware.ibutton_code = utils.convertDevIDToIbuttonCode(updateHardware.dev_id);
      }

      // Update a hardware
      setLoadingHardwareList(true);
      const { data } = await api.patch(`hardwares/update/${hardwareDetails.id_hardware}`, updateHardware);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setHardwareDetails(Object.assign(hardwareDetails, updateHardware));

        // Update row in table with updated hardware data
        table.row(tableClickedRow).data(hardwareDetails);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingHardwareList(false);
    }

  }, [
    addToast, table, tableClickedRow, hardwareDetails, handleCloseModalForm,
    activeOptionTypeHardware, activeOptionSimCard, activeOptionVehicle, activeOptionDriver, t
  ]);

  /** Delete selected hardware
   * @param hardware Hardware to delete
   */
  const deleteHardware = useCallback(async (hardware: Hardware) => {

    try {

      setLoadingHardwareList(true);
      const { data } = await api.delete(`hardwares/delete/${hardware.id_hardware}`);

      if (data.status === "success") {

        setOpenDialogConfirmDelete(false); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        table.row(tableClickedRow).remove(); // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingHardwareList(false);
    }

  },
  // eslint-disable-next-line
    [table, tableClickedRow]);

  /* endregion Functions */
  /* region Effects */

  // Type hardware options
  useEffect(() => {
    if (!openOptionsTypesHardware) setOptionsTypesHardware([]);
  }, [openOptionsTypesHardware]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsTypesHardware) return undefined;

    (async () => {

      try {

        // Get all hardware types
        const { data } = await api.get("hardwares/types/read");

        if (data.status === "success") {
          if (active) setOptionsTypesHardware(data.result);
        } else {
          setOpenOptionsTypesHardware(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsTypesHardware(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsTypesHardware, addToast, t]);

  // Vehicle options
  useEffect(() => {
    if (!openOptionsVehicles) setOptionsVehicles([]);
  }, [openOptionsVehicles]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsVehicles) return undefined;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read",
          { params: { idHardwareType: activeOptionTypeHardware?.id_hardware_type } });

        if (data.status === "success") {
          if (active) setOptionsVehicles(data.result);
        } else {
          setOpenOptionsVehicles(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsVehicles(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [activeOptionTypeHardware, loadingOptionsVehicles, addToast, t]);

  // Sim card options
  useEffect(() => {
    if (!openOptionsSimCards) setOptionsSimCards([]);
  }, [openOptionsSimCards]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsSimCards) return undefined;

    (async () => {

      try {

        // Get all sim cards
        const { data } = await api.get("simcards/read", { params: { linkedHardware: true } });

        if (data.status === "success") {
          if (active) setOptionsSimCards(data.result);
        } else {
          setOpenOptionsSimCards(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsSimCards(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsSimCards, addToast, t]);

  // Driver options
  useEffect(() => {
    if (!openOptionsDrivers) setOptionsDrivers([]);
  }, [openOptionsDrivers]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsDrivers) return undefined;

    (async () => {

      try {

        // Get all drivers
        const { data } = await api.get("drivers/read");

        if (data.status === "success") {
          if (active) setOptionsDrivers(data.result);
        } else {
          setOpenOptionsDrivers(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsDrivers(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsDrivers, addToast, t]);

  // Used to disable and change text when is searching for data
  useEffect(() => {

    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingHardwareList ? "Buscando hardwares..." : "Pesquisar";

      if (loadingHardwareList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingHardwareList]);

  useEffect(() => {
    readHardwares().then();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);

  /* endregion Effects */

  return (
    <>
      <Loading loading={loadingHardwareList} />
      {
        !loadingHardwareList && (
          <NewDataTable
            title={t(HardwareModuleMessages.hardwareDataTableTitle)}
            filters
            data={hardwares}
            columns={dataTableColumns}
            actions={dataTableActions}
            buttons={dataTableButtons}
            settings={dataTableSettings}
            returnTable={(table) => setTable(table)}
            returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
          />
        )
      }

      <DialogConfirmAction
        open={openDialogConfirmDelete}
        onClose={() => setOpenDialogConfirmDelete(false)}
        title={t(HardwareModuleMessages.hardwareDeleteTitle)}
        actions={[
          { text: t(GlobalMessages.no), action: () => setOpenDialogConfirmDelete(false) },
          { text: t(GlobalMessages.yes), action: () => deleteHardware(hardwareDetails) }
        ]}
      >
        {t(HardwareModuleMessages.hardwareDeleteText)} <br /><br />
        {t(HardwareModuleMessages.type)}: {hardwareDetails?.type?.description} <br />
        {t(HardwareModuleMessages.model)}: {hardwareDetails.model} <br />
        {t(HardwareModuleMessages.devId)}: {hardwareDetails.dev_id}?
      </DialogConfirmAction>
      <ContainerModalFormHardware id="modalFormHardware">
        <Dialog
          open={openModalFormHardware}
          onClose={handleCloseModalForm}
          scroll="paper"
          container={document.getElementById("modalFormHardware")}
        >
          <DialogTitle className="mHeader">
            <div className="content">
              <div className="title">
                {defineValueAccordingModalType(
                  `${hardwareDetails?.type?.description} - ${hardwareDetails.model}`,
                  `${hardwareDetails?.type?.description} - ${hardwareDetails.model}`,
                  t(HardwareModuleMessages.hardwareCreateTitle)
                )}
              </div>
              <div className="subtitle">
                {defineValueAccordingModalType(
                  `${t(HardwareModuleMessages.devId)}: ${hardwareDetails.dev_id}`,
                  `${t(HardwareModuleMessages.devId)}: ${hardwareDetails.dev_id}`,
                  ""
                )}
              </div>
            </div>
            <div className="actions">
              {modalType === "details"
                && (
                  <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                )}
            </div>
          </DialogTitle>
          <DialogContent dividers className="mContent">
            <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData, modalType)}>
              <DialogContentText tabIndex={-1} component="div">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12} className="checkboxes">
                    <FormControlLabel
                      className="r-checkbox"
                      control={(
                        <Checkbox
                          onChange={(event) => setActiveCheckbox(event.target.checked)}
                          checked={activeCheckbox}
                          name="active"
                          disabled={modalType === "details"}
                        />
                      )}
                      label={t(HardwareModuleMessages.hardwareDetailsActive)}
                    />
                    <FormControlLabel
                      className="r-checkbox"
                      control={(
                        <Checkbox
                          onChange={(event) => setBlockCapableCheckbox(event.target.checked)}
                          checked={blockCapableCheckbox}
                          name="block_capable"
                          disabled={modalType === "details" || activeOptionTypeHardware.description
                            !== HardwareTypes.RASTREADOR}
                        />
                      )}
                      label={t(HardwareModuleMessages.doBlock)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      open={openOptionsTypesHardware}
                      onOpen={() => setOpenOptionsTypesHardware(true)}
                      onClose={() => setOpenOptionsTypesHardware(false)}
                      getOptionSelected={(option, value) => option.description === value.description}
                      getOptionLabel={(option) => option.description || ""}
                      options={optionsTypesHardware.sort((a, b) => -b.description.localeCompare(a.description))}
                      disabled={modalType === "details"}
                      loading={loadingOptionsTypesHardware}
                      onChange={(event, value) => {
                        setActiveOptionTypeHardware(value);
                        setActiveOptionVehicle({} as Vehicle);
                        setActiveOptionSimCard({} as SimCard);
                        validations.validateFieldError("type");
                      }}
                      defaultValue={defineValueAccordingModalType(hardwareDetails?.type, hardwareDetails?.type, "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="type"
                          label={t(HardwareModuleMessages.hardwareDetailsType)}
                          margin="dense"
                          variant="outlined"
                          helperText="Obrigatório"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("type").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingOptionsTypesHardware ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Select
                      name="brand"
                      label={t(HardwareModuleMessages.brand)}
                      margin="dense"
                      variant="outlined"
                      disabled={modalType === "details"}
                      onChange={() => validations.validateFieldError("brand")}
                      defaultValue={defineValueAccordingModalType(hardwareDetails.brand, hardwareDetails.brand,
                        activeOptionTypeHardware.description !== HardwareTypes.IBUTTON
                          ? fixedValues.OtherTypes.brands[0]
                          : fixedValues.Ibutton.brand)}
                    >
                      {activeOptionTypeHardware.id_hardware_type !== HardwareTypesID.IBUTTON
                        ? fixedValues.OtherTypes.brands.map(
                          (brand) => <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                        )
                        : <MenuItem value={fixedValues.Ibutton.brand}>{fixedValues.Ibutton.brand}</MenuItem>}
                    </Select>
                    <FormHelperText>{t(GlobalMessages.required)}</FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Select
                      name="model"
                      label={t(HardwareModuleMessages.model)}
                      margin="dense"
                      variant="outlined"
                      disabled={modalType === "details"}
                      onChange={() => validations.validateFieldError("model")}
                      defaultValue={defineValueAccordingModalType(hardwareDetails.model, hardwareDetails.model,
                        activeOptionTypeHardware.id_hardware_type !== HardwareTypesID.IBUTTON
                          ? fixedValues.OtherTypes.models[0]
                          : fixedValues.Ibutton.model)}
                    >
                      {activeOptionTypeHardware.id_hardware_type !== HardwareTypesID.IBUTTON
                        ? fixedValues.OtherTypes.models.map(
                          (model) => <MenuItem key={model} value={model}>{model}</MenuItem>
                        )
                        : <MenuItem value={fixedValues.Ibutton.model}>{fixedValues.Ibutton.model}</MenuItem>}
                    </Select>
                    <FormHelperText>{t(GlobalMessages.required)}</FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      label={t(HardwareModuleMessages.devId)}
                      margin="dense"
                      variant="outlined"
                      name="dev_id"
                      InputLabelProps={{
                        shrink: formRef.current?.getFieldValue("dev_id").length > 0 ? true : undefined
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {loadingValidateFieldDevId ? <CircularProgress color="inherit" size={20} /> : null}
                          </>
                        )
                      }}
                      disabled={modalType === "details"}
                      helperText={t(GlobalMessages.required)}
                      defaultValue={defineValueAccordingModalType(hardwareDetails.dev_id, hardwareDetails.dev_id, "")}
                      onChange={() => validations.validateDevId(formRef.current?.getFieldValue("dev_id"))}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      label={t(HardwareModuleMessages.hardwareDetailsPurchaseDate)}
                      name="purchase_date"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      helperText={t(GlobalMessages.required)}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      disabled={modalType === "details"}
                      onChange={() => validations.validateFieldError("purchase_date")}
                      defaultValue={
                        !_.isEmpty(hardwareDetails?.purchase_date)
                        && defineValueAccordingModalType(
                          dateFns.format(utils.getDateUTC(hardwareDetails.purchase_date), "yyyy-MM-dd"),
                          dateFns.format(utils.getDateUTC(hardwareDetails.purchase_date), "yyyy-MM-dd"),
                          ""
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      open={openOptionsVehicles}
                      onOpen={() => setOpenOptionsVehicles(true)}
                      onClose={() => setOpenOptionsVehicles(false)}
                      getOptionSelected={(option, value) => option.code === value.code}
                      getOptionLabel={(option) => `${option.code ? `${option.code} - ` : ""}${option.license_plate
                        ? `${option.license_plate} - ` : ""}${option.description || ""}`}
                      getOptionDisabled={(option) => option.hardwares.length > 0}
                      options={optionsVehicles.sort((a, b) => -b.type.description.localeCompare(a.type.description)
                        || -b.code.localeCompare(a.code))}
                      groupBy={(option) => option.type.description}
                      disabled={
                        modalType === "details"
                        || _.isEmpty(activeOptionTypeHardware)
                        || activeOptionTypeHardware.description === HardwareTypes.IBUTTON
                      }
                      loading={loadingOptionsVehicles}
                      onChange={(event, value) => setActiveOptionVehicle(value)}
                      defaultValue={
                        !_.isEmpty(hardwareDetails?.vehicle)
                        && defineValueAccordingModalType(hardwareDetails?.vehicle, hardwareDetails?.vehicle, "")
                      }
                      value={activeOptionVehicle}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="vehicle"
                          label={t(HardwareModuleMessages.vehicle)}
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("vehicle").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingOptionsVehicles ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      open={openOptionsSimCards}
                      onOpen={() => setOpenOptionsSimCards(true)}
                      onClose={() => setOpenOptionsSimCards(false)}
                      getOptionSelected={(option, value) => option.caller_id === value.caller_id}
                      getOptionLabel={(option) => `${option.iccid ? `${option.iccid} - ` : ""}${option.caller_id
                      || ""}`}
                      getOptionDisabled={(option) => !_.isEmpty(option.hardware)}
                      options={optionsSimCards.sort((a, b) => -b.iccid.localeCompare(a.iccid)
                        || -b.caller_id.localeCompare(a.caller_id))}
                      groupBy={(option) => option.phone_company}
                      disabled={modalType === "details" || activeOptionTypeHardware?.description
                        !== HardwareTypes.RASTREADOR}
                      loading={loadingOptionsSimCards}
                      onChange={(event, value) => setActiveOptionSimCard(value)}
                      defaultValue={
                        !_.isEmpty(hardwareDetails?.sim_card)
                        && defineValueAccordingModalType(hardwareDetails?.sim_card, hardwareDetails?.sim_card, "")
                      }
                      value={activeOptionSimCard}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="sim_card"
                          label={t(HardwareModuleMessages.simCard)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("sim_card").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingOptionsSimCards ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      open={openOptionsDrivers}
                      onOpen={() => setOpenOptionsDrivers(true)}
                      onClose={() => setOpenOptionsDrivers(false)}
                      getOptionSelected={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => `${option.name ? `${option.name}` : ""}`}
                      getOptionDisabled={(option) => !_.isEmpty(option.hardware)}
                      options={optionsDrivers.sort((a, b) => -b.name.localeCompare(a.name))}
                      disabled={modalType === "details" || activeOptionTypeHardware?.description
                        !== HardwareTypes.IBUTTON}
                      loading={loadingOptionsDrivers}
                      onChange={(event, value) => setActiveOptionDriver(value)}
                      defaultValue={defineValueAccordingModalType(hardwareDetails?.driver, hardwareDetails?.driver, "")}
                      value={activeOptionDriver}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="driver"
                          label={t(HardwareModuleMessages.driver)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("driver").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingOptionsDrivers ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </Form>
          </DialogContent>
          <DialogActions className="mFooter">
            <div className="actions">
              {
                modalType === "details"
                  ? <></>
                  : modalType === "register"
                    ? (
                      <Button
                        disableRipple
                        type="submit"
                        color="primary"
                        onClick={() => formRef.current?.submitForm()}
                      >{t(GlobalMessages.register)}
                      </Button>
                    )
                    : (
                      <Button
                        disableRipple
                        type="submit"
                        color="primary"
                        onClick={() => formRef.current?.submitForm()}
                      >{t(GlobalMessages.apply)}
                      </Button>
                    )
              }
            </div>
            <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModalFormHardware>
    </>
  );
};

export default HardwaresList;
