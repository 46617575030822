import React from "react";
import Loading from "react-fullscreen-loading";

// region imports - External libraries
import { useToast } from "@npm-telluria-tecnologia/telluria-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress, Fab, TextField } from "@material-ui/core";
import { Edit, Delete } from "@mui/icons-material";
// endregion imports - External libraries
// region imports - Components
import ModalFormFilterDescription from "@components/Modal/FormFilterDescription";
// endregion imports - Components
// region imports - Languages
import useTranslation from "src/translations/useTranslation";
import { DualListboxMessages, FilterMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion imports - Languages
// region imports - Utils
import utils, { StandardReturnObject } from "@utils/useful-functions";
// endregion imports - Utils
// region imports - Services
import api from "@services/api";
// endregion imports - Services
// region imports - Shared
import { Filter } from "@shared/entities/reg_filters.entity";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
import { IFilter } from "@shared/interfaces/filter.interface";
// endregion imports - Shared
// region imports - Styles
import { GlobalStyledComponents } from "@styles/global";
import * as Styled from "./styles";
// endregion imports - Styles

// region Interfaces
interface IProps {
  applicationTypeID: FilterApplicationTypesID;
  showControlButtons: boolean;
  selectedFilter: IFilter | null;
  onChange: (filter: IFilter | null, previousDeleted?: boolean) => void;
}

const AutocompleteSavedFilters: React.FC<IProps> = ({
  selectedFilter,
  applicationTypeID,
  onChange,
  showControlButtons
}) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region States
  const [isLoading, setIsLoading] = React.useState(false);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [optionsAutocomplete, setOptionsAutocomplete] = React.useState<IFilter[] | undefined>(undefined);

  const [isLoadingFilters, setIsLoadingFilters] = React.useState(false);
  const [selectedFilterState, setSelectedFilterState] = React.useState<IFilter | null>(null);

  const [currentFilterToRename, setCurrentFilterToRename] = React.useState<IFilter | null>(null);
  // endregion States
  // region Functions
  /**
   * Load filter from API
   */
  const loadFilterOptions = async () => {

    setIsLoadingFilters(true);

    try {

      // Get all saved presets by application type
      const response = await api.get(`filters/get-by-filter-application-type/${applicationTypeID}`);
      const data = response.data as StandardReturnObject<IFilter[]>;

      if (data.status === "success") {
        setOptionsAutocomplete(data.result as IFilter[]);

        return;
      }

      setOptionsAutocomplete([]);

      if (data.status !== "alert") addToast({ type: "error", title: t(ToastMessages.error), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setIsLoadingFilters(false);
    }
  };
  // endregion Functions
  // region Handlers
  const handleCloseModalFormDialog = () => {
    setCurrentFilterToRename(null);
  };

  const handleClickDeleteFilterButton = async () => {
    setIsLoading(true);

    try {

      const response = await api.delete(`/filters/delete/${selectedFilter?.id_filter}`);
      const data = response?.data as StandardReturnObject<Filter>;

      if (data?.status === "success") {
        addToast({ type: "success", title: data.message });
        setSelectedFilterState(null);
        onChange(null, true);
        setOptionsAutocomplete(optionsAutocomplete?.filter((filter) => filter.id_filter !== selectedFilter?.id_filter));

        return;
      }

      addToast({ type: "error", title: data.message });

    } catch (error) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeSavedFilter = (filter: IFilter | null) => {
    onChange(filter);
  };
  // endregion Handlers
  // region Effects

  // Load filter options when component is mounted
  React.useEffect(() => {
    if (!optionsAutocomplete) loadFilterOptions().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update options when selected filter changes
  React.useEffect(() => {
    if (!selectedFilter) {
      setSelectedFilterState(null);

      return;
    }

    if (!optionsAutocomplete) return;

    const filter = optionsAutocomplete?.find((filter) => filter.id_filter === selectedFilter.id_filter);

    if (filter) {
      const hasChanged = JSON.stringify(filter) !== JSON.stringify(selectedFilter);

      if (hasChanged) {
        const optionsAutocompleteWithoutSelected = optionsAutocomplete.filter((filter) => filter.id_filter !== selectedFilter.id_filter);

        setOptionsAutocomplete(
          [
            ...optionsAutocompleteWithoutSelected,
            selectedFilter
          ].sort((a, b) => a.description!.localeCompare(b.description!))
        );
      }
    } else {
      setOptionsAutocomplete([...optionsAutocomplete, selectedFilter].sort((a, b) => a.description!.localeCompare(b.description!)));
    }

    setSelectedFilterState(selectedFilter);
  }, [selectedFilter, optionsAutocomplete]);
  // endregion Effects

  return (
    <Styled.Container showControlButtons>
      <Loading loading={isLoading} />
      <Styled.Field>
        <Autocomplete
          onChange={(_ev, item) => handleChangeSavedFilter(item)}
          value={selectedFilterState}
          onOpen={() => setOpenAutocomplete(true)}
          onClose={() => setOpenAutocomplete(false)}
          open={openAutocomplete}
          options={optionsAutocomplete ?? []}
          loading={isLoadingFilters}
          getOptionSelected={(option, value) => option.id_filter === value.id_filter}
          getOptionLabel={(option) => option.description ?? "NULL"}
          noOptionsText={t(DualListboxMessages.noAvailableOptions)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(FilterMessages.savedFilters)}
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingFilters && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        {showControlButtons && (
          <GlobalStyledComponents.FieldActionButtons>
            <Fab
              color="primary"
              size="small"
              onClick={() => setCurrentFilterToRename(selectedFilter)}
              disabled={!selectedFilter}
            >
              <Edit fontSize="medium" />
            </Fab>
            <Fab
              color="primary"
              size="small"
              onClick={handleClickDeleteFilterButton}
              disabled={!selectedFilter}
            >
              <Delete fontSize="medium" />
            </Fab>
          </GlobalStyledComponents.FieldActionButtons>
        )}
      </Styled.Field>
      <ModalFormFilterDescription
        open={!!currentFilterToRename}
        onClose={handleCloseModalFormDialog}
        filter={currentFilterToRename!}
        onSubmit={handleChangeSavedFilter}
      />
    </Styled.Container>
  );
};

export default AutocompleteSavedFilters;
