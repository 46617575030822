import React, { useEffect } from "react";
import { TextField } from "@libraries/mui";
import {
  FormControl,
  Chip,
  FormGroup
} from "@mui/material";
import KanbanAccordionCard from "../KanbanAccordionCard";
import AccordionSummaryChips from "@atoms/AccordionSummaryChips";
import * as Styled from "./styles";
import { FilterMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
import { ISimpleInputOptions } from "@organisms/GenericQueryFilter";
// region Interfaces
interface IProps {
  label: string;
  onChangeSimpleInput?: (values: string[]) => void;
  initialSelectedOptions?: ISimpleInputOptions[];
}
// endregion Interfaces

const KanbanASimpleInput: React.FC<IProps> = ({
  label,
  onChangeSimpleInput,
  initialSelectedOptions
}) => {
  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks

  // region States
  const [currentInputText, setCurrentInputText] = React.useState<string>("");
  const [selectedOptionsList, setSelectedOptionsList] = React.useState<string[]>([]);
  const [expandedCard, setExpandedCard] = React.useState(false);
  // endregion States

  // region Functions
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInputText(event.target.value);

    return(currentInputText);
  };

  const toggleValue = (paramValue: string) => {
    setSelectedOptionsList((prevValues) => {
      if (prevValues.includes(paramValue)) {
        return prevValues;
      }

      const updatedValues = [...prevValues, paramValue];

      if (onChangeSimpleInput) onChangeSimpleInput(updatedValues);

      return updatedValues;
    });
  };

  /**
   * @param event keyboard event where enter or tab is pressed
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === "Tab") {
      if (currentInputText.trim() !== "") {
        toggleValue(currentInputText.trim());
        setCurrentInputText("");
      }
    }
  };

  const handleDelete = (item: string) => {

    const updatedValues = selectedOptionsList.filter(value => value !== item);

    setSelectedOptionsList(updatedValues);
    if (onChangeSimpleInput) onChangeSimpleInput(updatedValues);
  };
  const handleAccordionCardChange = () => {
    setExpandedCard((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    if (initialSelectedOptions) {
      const [initialValues] = initialSelectedOptions;

      setSelectedOptionsList(initialValues?.values ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // endregion Functions

  return (
    <Styled.Container>
      <KanbanAccordionCard
        onChange={handleAccordionCardChange}
        showExpandIcon
        accordionSummary={(
          <AccordionSummaryChips
            label={ label }
            chips={selectedOptionsList.map((option) => ({ id: option, label: option }))}
            onDeleteChip={handleDelete}
          />
        )}
        expanded={expandedCard}
      >
        <Styled.AccordionDetail>
          {expandedCard && (
            <>
              <TextField
                label={t(FilterMessages.enterTextToFilter)}
                value={currentInputText}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                fullWidth
                variant="outlined"
                style={{ marginTop: "10px" }}
              />

              <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <FormGroup style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <Styled.Chips className="accordion-summary-chips">
                    {selectedOptionsList.map((item, index) => (
                      <Chip
                        key={index}
                        label={item}
                        onDelete={() => handleDelete(item)}
                        style={{
                          fontWeight: "bold",
                          margin: "2px 0px",
                          color: "#FFFFFF",
                          backgroundColor: "#597aa7"
                        }}
                      />
                    ))}
                  </Styled.Chips>
                </FormGroup>
              </FormControl>
            </>
          )}
        </Styled.AccordionDetail>
      </KanbanAccordionCard>
    </Styled.Container>
  );
};

KanbanASimpleInput.defaultProps = {
  onChangeSimpleInput: () => undefined
};

export default KanbanASimpleInput;
