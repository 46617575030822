/* eslint-disable max-len */

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";
import InputMask from "react-input-mask";

// region Libraries
import {
  Button, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import MapIcon from "@material-ui/icons/Map";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Checkbox, TextField } from "unform-material-ui";
import * as _ from "lodash";
import * as Yup from "yup";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages, EventTypesMessages,
  GlobalMessages, LocationsModuleMessages, LocationTypeMessages,
  ToastMessages,
  VehiclesModuleMessages, VehicleTypesMessages,
  YupMessages
} from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { Driver } from "@shared/interfaces/driver.interface";
import { Location } from "@shared/interfaces/location.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { VehicleType } from "@shared/interfaces/vehicle-type.interface";
// endregion Interfaces
// region Components
import { TimeLineItemMap } from "@components/TimeLines/TimeLineItemMap";
import { ContainerModalMapMarker } from "@organisms/ModalFormLocation/styles";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import { MapFixedPointCoord } from "@components/Map";
import ButtonTable from "@components/Button/ButtonTable";
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
// endregion Components
// region Utils
import utils from "@utils/useful-functions";
import { formatHourMeter, formatOdometer } from "@shared/utils/useful-functions";
// endregion Utils
// region Services
import api from "@services/api";
// endregion Services
// region Hooks
import getValidationErrors from "@hooks/getValidationErrors";
import { useToast } from "@hooks/useToast";
import { useAuth } from "@hooks/useAuth";
// endregion Hooks
// region Styles
import { Container, ContainerModalFormVehicle } from "./styles";
// endregion Styles

interface ValidateFields { code: boolean; licensePlate: boolean; }

const VehicleList: React.FC = () => {

  /* region States */
  const [optionsTypesVehicle, setOptionsTypesVehicle] = useState<VehicleType[]>([] as VehicleType[]);
  const [optionsLocationsVehicle, setOptionsLocationsVehicle] = useState<Location[]>([] as Location[]);
  const [optionsDriversVehicle, setOptionsDriversVehicle] = useState<Driver[]>([] as Driver[]);

  const [activeOptionTypeVehicle, setActiveOptionTypeVehicle] = useState<VehicleType>({} as VehicleType);
  const [activeOptionLocationVehicle, setActiveOptionLocationVehicle] = useState<Location>({} as Location);
  const [activeOptionDriverVehicle, setActiveOptionDriverVehicle] = useState<Driver>({} as Driver);

  const [vehicles, setVehicles] = useState<Vehicle[]>([] as Vehicle[]);
  const [vehicleDetails, setVehicleDetails] = useState<Vehicle>({} as Vehicle);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [openModalFormVehicle, setOpenModalFormVehicle] = useState(false);
  const [openOptionsTypesVehicle, setOpenOptionsTypesVehicle] = useState(false);
  const [openOptionsLocationsVehicle, setOpenOptionsLocationsVehicle] = useState(false);
  const [openOptionsDriversVehicle, setOpenOptionsDriversVehicle] = useState(false);
  const [openVehicleMapModal, setOpenVehicleMapModal] = useState(false);

  const loadingOptionsTypesVehicle = openOptionsTypesVehicle && optionsTypesVehicle.length === 0;
  const loadingOptionsLocationsVehicle = openOptionsLocationsVehicle && optionsLocationsVehicle.length === 0;
  const loadingOptionsDriversVehicle = openOptionsDriversVehicle && optionsDriversVehicle.length === 0;
  const [loadingVehicleList, setLoadingVehicleList] = useState(false);
  const [loadingValidateFieldCode, setLoadingValidateFieldCode] = useState(false);
  const [loadingValidateFieldLicensePlate, setLoadingValidateFieldLicensePlate] = useState(false);

  const [modalType, setModalType] = useState<"details" | "update" | "register">("details");

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);

  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(true);
  const [validateFields, setValidateFields] = useState<ValidateFields>({ code: false, licensePlate: false });
  /* endregion States */
  /* region Hooks */
  const { addToast } = useToast();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { t, i18n } = useTranslation();
  /* endregion Hooks */
  /* region Constants */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-vehicle-list-map",
      callback: (rowData: Vehicle) => handleVehicleMap(rowData)
    },
    {
      ref: ".modules-vehicle-list-details",
      callback: (rowData: Vehicle) => handleVehicleDetails(rowData)
    },
    {
      ref: ".modules-vehicle-list-delete",
      callback: (rowData: Vehicle) => handleVehicleDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Code
      title: t(VehiclesModuleMessages.code),
      data: (vehicle: Vehicle) => vehicle.code,
      filterable: true,
      defaultContent: "",
      propertyName: "code"
    },
    { // Tipo
      title: t(VehiclesModuleMessages.vehicleType),
      data: (vehicle: Vehicle) => t(VehicleTypesMessages[vehicle.type.id_vehicle_type]),
      filterable: true,
      defaultContent: "",
      propertyName: "type"
    },
    { // Placa
      title: t(VehiclesModuleMessages.licensePlate),
      data: (vehicle: Vehicle) => vehicle.license_plate,
      filterable: true,
      defaultContent: "",
      propertyName: "license_plate"
    },
    { // Descrição
      title: t(VehiclesModuleMessages.description),
      data: (vehicle: Vehicle) => vehicle.description,
      filterable: true,
      defaultContent: "",
      propertyName: "description"
    },
    { // Ativo
      title: t(VehiclesModuleMessages.status),
      filterable: true,
      defaultContent: "",
      propertyName: "active",
      data: (vehicle: Vehicle) => (
        ReactDOMServer.renderToString(
          <b style={{ color: vehicle.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {vehicle.active ? t(VehiclesModuleMessages.active).toUpperCase() : t(VehiclesModuleMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      toFilterValue: (data: Vehicle) => (data.active ? t(VehiclesModuleMessages.active).toUpperCase() : t(VehiclesModuleMessages.inactive).toUpperCase())
    },
    { // Odometro
      title: t(VehiclesModuleMessages.odometer),
      data: (vehicle: Vehicle) => formatOdometer(vehicle.current_odometer || 0),
      filterable: true,
      defaultContent: "",
      propertyName: "finish_odometer",
      filterTitle: t(VehiclesModuleMessages.odometerInKm),
      toFilterValue: (data: Vehicle) => (data.current_odometer ? data.current_odometer / 1000 : 0)
    },
    { // Horímetro
      title: t(VehiclesModuleMessages.hourMeter),
      data: (vehicle: Vehicle) => formatHourMeter(vehicle.current_hour_meter || 0),
      filterable: true,
      defaultContent: "",
      propertyName: "finish_hour_meter",
      filterTitle: t(VehiclesModuleMessages.hourMeterInHours),
      toFilterValue: (data: Vehicle) => (data.current_hour_meter ? data.current_hour_meter / 60 : 0)
    },
    { // Manutenção
      title: t(VehiclesModuleMessages.maintenance),
      data: (vehicle: Vehicle) => (
        ReactDOMServer.renderToString(
          <b style={{ color: vehicle.maintenance ? "rgb(230, 74, 25)" : "rgb(70, 193, 125)" }}>
            {vehicle.maintenance
              ? t(GlobalMessages.yes).toUpperCase()
              : t(GlobalMessages.no).toUpperCase()}
          </b>
        )
      ),
      filterable: true,
      defaultContent: "",
      propertyName: "maintenance",
      toFilterValue: (data: Vehicle) => (data.maintenance ? t(GlobalMessages.yes).toUpperCase() : t(GlobalMessages.no).toUpperCase())
    },
    { // Alocação original
      title: t(VehiclesModuleMessages.originalAllocation),
      data: (vehicle: Vehicle) => (
        ReactDOMServer.renderToString(
          <>
            {!_.isEmpty(vehicle.location) && !_.isEmpty(vehicle.location?.id_location)
              ? (
                <>
                  <b>{t(LocationTypeMessages[vehicle?.location?.type.id_location_type ?? ""])}:</b> {`${vehicle.location?.name || ""} `}
                </>
              )
              : <></>}
          </>
        )
      ),
      filterable: true,
      defaultContent: "",
      propertyName: "location"
    },
    { // Alocação atual
      title: t(VehiclesModuleMessages.currentAllocation),
      data: (vehicle: Vehicle) => (
        ReactDOMServer.renderToString(
          <>
            {vehicle.current_location
              ? (
                <>
                  <b>{t(LocationTypeMessages[vehicle.current_location.type.id_location_type])}: </b>
                  {
                    vehicle.current_location.name || `${vehicle.current_location.address},`
                    + `${vehicle.current_location.number || ""} - ${vehicle.current_location.district || ""}`
                    + `| ${vehicle.current_location.county} - ${vehicle.current_location.uf}`
                  }
                </>
              )
              : <></>}
          </>
        )
      ),
      filterable: true,
      defaultContent: "",
      propertyName: "current_location"
    },
    { // Evento atual
      title: t(VehiclesModuleMessages.currentEvent),
      data: (vehicle: Vehicle) => (
        ReactDOMServer.renderToString(
          <>
            {vehicle.current_event
              ? <><b>{t(GlobalMessages.type)}:</b> {t(EventTypesMessages[vehicle.current_event.type.id_event_type])}</>
              : <></>}
          </>
        )
      ),
      filterable: true,
      defaultContent: "",
      propertyName: "current_event"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-vehicle-list-map"><MapIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-vehicle-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-vehicle-list-delete"><DeleteForeverIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "200px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleCreateVehicle()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readVehicles()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_veiculos_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */

  /* region Functions */
  // Actions vehicle list
  const handleVehicleDetails = useCallback((vehicle: Vehicle) => {

    // Set active options autocomplete
    if (vehicle.location) setActiveOptionLocationVehicle(vehicle.location);
    setActiveOptionTypeVehicle(vehicle.type);

    // Validate validation fields
    setValidateFields({ code: true, licensePlate: true });

    // Set active according selected vehicle
    setActiveCheckbox(vehicle.active);

    setVehicleDetails(vehicle);
    setModalType("details");
    setOpenModalFormVehicle(true);

  }, []);
  const handleVehicleDelete = useCallback((vehicle: Vehicle) => {

    setVehicleDetails(vehicle);

    setOpenDialogConfirmDelete(true);

  }, []);

  const handleVehicleMap = useCallback((vehicle: Vehicle) => {

    setVehicleDetails(vehicle);
    setOpenVehicleMapModal(true);

  }, []);

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setVehicleDetails({} as Vehicle);
    setValidateFields({ code: false, licensePlate: false });

    setOpenModalFormVehicle(false);

  }, []);

  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateCode: async (code: string) => {

      setValidateFields({ ...validateFields, code: false });

      // Validate re just have 3 or more characters in on blur
      if (code.trim().length >= 3 && code.trim() !== vehicleDetails.code) {

        try {

          setLoadingValidateFieldCode(true);

          // Verify if RE is already registered
          const { data } = await api.get(`vehicles/verify-unique-field/code/${code.trim()}`);

          if (data.status === "alert") formRef.current?.setFieldError("code", t(VehiclesModuleMessages.codeAlreadyRegistered));
          else formRef.current?.setFieldError("code", "");

          setValidateFields({ ...validateFields, code: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldCode(false);
        }
      }
    },
    validateLicensePlate: async (licensePlate: string) => {

      setValidateFields({ ...validateFields, licensePlate: false });

      // License plate completed
      if (licensePlate.trim().length === 7 && licensePlate.trim() !== vehicleDetails.license_plate) {

        try {

          setLoadingValidateFieldLicensePlate(true);

          // Verify if RE is already registered
          const { data } = await api.get(`vehicles/verify-unique-field/license_plate/${licensePlate.trim()}`);

          if (data.status === "alert") formRef.current?.setFieldError("license_plate", t(VehiclesModuleMessages.licensePlateAlreadyRegistered));
          else formRef.current?.setFieldError("license_plate", "");

          setValidateFields({ ...validateFields, licensePlate: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldLicensePlate(false);
        }
      }
    },
    validateForm: async (formData: Vehicle, action: "details" | "register" | "update") => {

      try {

        // Revalidate unique fields (If not validate yet)
        if (!validateFields.code && formData.code.trim().length) await validations.validateCode(formData.code);
        if (!validateFields.licensePlate && formData.license_plate.trim().length) await validations.validateLicensePlate(formData.license_plate);

        // Define the validation types
        const schema = Yup.object().shape({
          code: Yup.string().trim().test("validateCode", formRef.current?.getFieldError("code"),
            () => !(formRef.current?.getFieldError("code")?.length))
            .required(t(YupMessages.codeRequired)),
          license_plate: Yup.string().trim().test("validateLicensePlate", formRef.current?.getFieldError("license_plate"),
            () => !(formRef.current?.getFieldError("license_plate")?.length))
            .min(7, t(YupMessages.incompleteLicensePlate))
            .required(t(YupMessages.licensePlateRequired)),
          type: Yup.string().trim().required(t(YupMessages.typeRequired)),
          description: Yup.string().trim().required(t(YupMessages.descriptionRequired))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update driver (According action selected)
        if (action === "register") await createVehicle(formData);
        else await updateVehicle(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };

  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType) {
    case "details": return detailValue;
    case "update": return updateValue;
    case "register": return registerValue;
    default: return "S/N";
    }
  }, [modalType]);

  /** Get all vehicles data
   */
  const readVehicles = useCallback(async () => {

    try {

      // Get the travels of selected vehicle
      setLoadingVehicleList(true);
      const { data } = await api.get("vehicles/read", { params: { linkedHardware: true } });

      if (data.status === "success") setVehicles(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingVehicleList(false);
    }

  }, [addToast, t]);

  /** Create a vehicle
   * @param vehicle Vehicle to create
   */
  const createVehicle = useCallback(async (vehicle: Vehicle) => {

    try {

      let insertVehicle = { ...vehicle };

      // Remove properties without value
      Object.keys(insertVehicle).forEach((key) => {
        if (insertVehicle[key].length <= 0) delete insertVehicle[key];
      });

      if (insertVehicle.type) insertVehicle.type = activeOptionTypeVehicle;
      if (insertVehicle.location) insertVehicle.location = activeOptionLocationVehicle;
      if (insertVehicle.driver) insertVehicle.driver = activeOptionDriverVehicle;
      if (insertVehicle.maximum_speed_allowed) insertVehicle.maximum_speed_allowed = Number(insertVehicle.maximum_speed_allowed);

      // Create a vehicle
      setLoadingVehicleList(true);
      const { data } = await api.post("vehicles/create", insertVehicle);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertVehicle = { ...insertVehicle, id_vehicle: data.result.id_vehicle };

        if (!vehicles.length) await readVehicles();
        else {

          // Add inserted driver in the table
          table.row.add(insertVehicle);
          table.draw();
        }

        // Search the inserted driver and list
        table.search(vehicle.code).draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingVehicleList(false);
    }

  }, [addToast, readVehicles, table, vehicles, activeOptionTypeVehicle,
    activeOptionLocationVehicle, activeOptionDriverVehicle, handleCloseModalForm, t]);

  /*
   * Open modal to create new vehicle
   * */
  const FhandleCreateVehicle = () => {
    setModalType("register");
    setOpenModalFormVehicle(true);
  };

  /** Update a vehicle
   * @param vehicle Vehicle to update
   */
  const updateVehicle = useCallback(async (vehicle: Vehicle) => {

    try {

      const updateVehicle = { ...vehicle };

      if (updateVehicle.type) updateVehicle.type = activeOptionTypeVehicle;
      if (updateVehicle.location) updateVehicle.location = activeOptionLocationVehicle;
      if (updateVehicle.driver) updateVehicle.driver = activeOptionDriverVehicle;
      if (updateVehicle.maximum_speed_allowed) updateVehicle.maximum_speed_allowed = Number(updateVehicle.maximum_speed_allowed);

      // Optional fields when nothing is sent (Remove location from vehicle)
      if (!updateVehicle.location) updateVehicle.location = { ...{}, id_location: "" } as Location;
      if (!updateVehicle.driver) updateVehicle.driver = { ...{}, id_driver: "" } as Driver;
      if (!updateVehicle.external_id) updateVehicle.external_id = null;

      // Update a driver
      setLoadingVehicleList(true);
      const { data } = await api.patch(`vehicles/update/${vehicleDetails.id_vehicle}`, updateVehicle);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setVehicleDetails(Object.assign(vehicleDetails, updateVehicle));

        // Update row in table with updated driver data
        table.row(tableClickedRow).data(vehicleDetails);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingVehicleList(false);
    }

  }, [activeOptionTypeVehicle, activeOptionLocationVehicle, activeOptionDriverVehicle,
    addToast, table, tableClickedRow, vehicleDetails, handleCloseModalForm, t]);

  /** Delete selected vehicle
   * @param vehicle Vehicle to delete
   */
  const deleteVehicle = useCallback(async (vehicle: Vehicle) => {

    try {

      setLoadingVehicleList(true);
      const { data } = await api.delete(`vehicles/delete/${vehicle.id_vehicle}`);

      if (data.status === "success") {

        setOpenDialogConfirmDelete(false); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        table.row(tableClickedRow).remove(); // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingVehicleList(false);
    }

  }, [addToast, table, tableClickedRow, t]);

  /* endregion Functions */
  /* region Effects */

  // Get all vehicles
  useEffect(() => {
    readVehicles().then();

  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);

  useEffect(() => {

    let active = true;

    if (!loadingOptionsTypesVehicle) return undefined;

    (async () => {

      try {

        // Get all vehicles types
        const { data } = await api.get("vehicles/types/read");

        if (data.status === "success") {

          if (active) {
            setOptionsTypesVehicle(data.result);
          }

        } else {
          setOpenOptionsTypesVehicle(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsTypesVehicle(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsTypesVehicle, addToast, t]);

  // Location vehicle options
  useEffect(() => {
    if (!openOptionsLocationsVehicle) setOptionsLocationsVehicle([]);
  }, [openOptionsLocationsVehicle]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsLocationsVehicle) return undefined;

    (async () => {

      try {

        // Get all locations with type = 'Usina'
        const { data } = await api.get("locations/get-plants");

        if (data.status === "success") {
          if (active) setOptionsLocationsVehicle(data.result);
        } else {
          setOpenOptionsLocationsVehicle(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsLocationsVehicle(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsLocationsVehicle, addToast, t]);

  // Driver vehicle options
  useEffect(() => {
    if (!openOptionsDriversVehicle) setOptionsDriversVehicle([]);
  }, [openOptionsDriversVehicle]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsDriversVehicle) return undefined;

    (async () => {

      try {

        // Get all drivers
        const { data } = await api.get("drivers/read");

        if (data.status === "success") {
          if (active) setOptionsDriversVehicle(data.result);
        } else {
          setOpenOptionsDriversVehicle(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsDriversVehicle(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsDriversVehicle, addToast, t]);

  // Type vehicle options
  useEffect(() => {
    if (!openOptionsTypesVehicle) setOptionsTypesVehicle([]);
  }, [openOptionsTypesVehicle]);

  useEffect(() => {
    if (!openModalFormVehicle) {
      setActiveCheckbox(true);
    }
  }, [openModalFormVehicle]);

  // Used to disable and change text when is searching for data
  useEffect(() => {
    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingVehicleList ? t(VehiclesModuleMessages.searchingVehicles) : t(GlobalMessages.search);

      if (loadingVehicleList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingVehicleList, t]);

  // Update coordinates in order to load vehicle map
  useEffect(() => {
    if (Object.getOwnPropertyNames(vehicleDetails).length > 0) {

      setLatitude(Number(vehicleDetails.hardwares[0].last_latitude));
      setLongitude(Number(vehicleDetails.hardwares[0].last_longitude));
    }
  }, [vehicleDetails]);
  /* endregion Effects */

  return (
    <Container>
      <Loading loading={loadingVehicleList} />
      {
        !loadingVehicleList && (
          <NewDataTable
            title={t(VehiclesModuleMessages.title)}
            data={vehicles}
            filters
            columns={dataTableColumns}
            settings={dataTableSettings}
            actions={dataTableActions}
            buttons={dataTableButtons}
            returnTable={(table) => setTable(table)}
            returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
          />
        )
      }
      <ContainerModalMapMarker id="modalMapMarkerLocation">
        <Dialog
          open={openVehicleMapModal}
          onClose={() => { setOpenVehicleMapModal(false); }}
          scroll="paper"
          container={document.getElementById("modalMapMarkerLocation")}
        >
          <DialogContent dividers className="mContent">
            <DialogContentText tabIndex={-1} component="div">
              <MapFixedPointCoord
                mapHeight={500}
                tooltip={{ title: `${latitude}, ${longitude}` }}
                latitude={latitude}
                longitude={longitude}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mFooter">
            <Button disableRipple onClick={() => setOpenVehicleMapModal(false)} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModalMapMarker>
      <DialogConfirmAction
        open={openDialogConfirmDelete}
        onClose={() => setOpenDialogConfirmDelete(false)}
        title={t(VehiclesModuleMessages.deleteDialogTitle)}
        actions={[
          { text: t(GlobalMessages.no).toUpperCase(), action: () => setOpenDialogConfirmDelete(false) },
          { text: t(GlobalMessages.yes).toUpperCase(), action: () => deleteVehicle(vehicleDetails) }
        ]}
      >
        {t(VehiclesModuleMessages.vehicleDeleteConfirmation)} <br /><br />
        {t(VehiclesModuleMessages.code)}: {vehicleDetails.code} <br />
        {t(VehiclesModuleMessages.description)}: {vehicleDetails.description}?
      </DialogConfirmAction>
      <ContainerModalFormVehicle id="modalFormVehicle">
        {openModalFormVehicle && (
          <Dialog
            open={openModalFormVehicle}
            onClose={handleCloseModalForm}
            scroll="paper"
            container={document.getElementById("modalFormVehicle")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">{defineValueAccordingModalType(vehicleDetails.code, vehicleDetails.code, t(VehiclesModuleMessages.vehicleRegistration))}</div>
                <div className="subtitle">{defineValueAccordingModalType(vehicleDetails.description, vehicleDetails.description, "")}</div>
              </div>
              <div className="actions">
                {modalType === "details"
                && (
                  <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                )}
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData, modalType)}>
                <DialogContentText tabIndex={-1} component="div">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} className="checkboxes">
                      <FormControlLabel
                        className="r-checkbox"
                        control={(
                          <Checkbox
                            checked={activeCheckbox}
                            onChange={(event) => setActiveCheckbox(event.target.checked)}
                            name="active"
                            disabled={modalType === "details"}
                          />
                        )}
                        label={t(VehiclesModuleMessages.activeVehicle)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="code"
                        label={t(VehiclesModuleMessages.code)}
                        margin="dense"
                        variant="outlined"
                        name="code"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("code").length > 0 ? true : undefined }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {loadingValidateFieldCode ? <CircularProgress color="inherit" size={20} /> : null}
                            </>
                          )
                        }}
                        disabled={modalType === "details" || (!user.super_admin && modalType === "update")}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(vehicleDetails.code, vehicleDetails.code, "")}
                        onChange={() => validations.validateFieldError("code")}
                        onBlur={() => validations.validateCode(formRef.current?.getFieldValue("code"))}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <InputMask
                        mask="aaa9*99"
                        maskChar=""
                        defaultValue={defineValueAccordingModalType(vehicleDetails.license_plate, vehicleDetails.license_plate, "")}
                        disabled={modalType === "details" || (!user.super_admin && modalType === "update")}
                        beforeMaskedValueChange={(newState) => {
                          // eslint-disable-next-line no-param-reassign
                          newState.value = newState.value.toUpperCase();

                          return newState;
                        }}
                        onChange={() => validations.validateFieldError("license_plate")}
                        onBlur={() => validations.validateLicensePlate(formRef.current?.getFieldValue("license_plate"))}
                      >
                        {(inputProps) => (
                          <TextField
                            {...inputProps}
                            className="license_plate"
                            label={t(VehiclesModuleMessages.licensePlate)}
                            margin="dense"
                            variant="outlined"
                            name="license_plate"
                            InputLabelProps={{ shrink: formRef.current?.getFieldValue("license_plate").length > 0 ? true : undefined }}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {loadingValidateFieldLicensePlate ? <CircularProgress color="inherit" size={20} /> : null}
                                </>
                              )
                            }}
                            helperText={t(GlobalMessages.required)}
                            disabled={modalType === "details" || (!user.super_admin && modalType === "update")}
                          />
                        )}
                      </InputMask>
                    </Grid>
                    {user.admin && (
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          className="external_id"
                          label={t(LocationsModuleMessages.externalId)}
                          margin="dense"
                          variant="outlined"
                          name="external_id"
                          InputLabelProps={{ shrink: formRef.current?.getFieldValue("description").length > 0 ? true : undefined }}
                          disabled={modalType === "details" || !user.super_admin}
                          defaultValue={defineValueAccordingModalType(vehicleDetails.external_id, vehicleDetails.external_id, "")}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        className="description"
                        label={t(VehiclesModuleMessages.description)}
                        margin="dense"
                        variant="outlined"
                        name="description"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("description").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(vehicleDetails.description, vehicleDetails.description, "")}
                        onChange={() => validations.validateFieldError("description")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Autocomplete
                        open={openOptionsTypesVehicle}
                        onOpen={() => setOpenOptionsTypesVehicle(true)}
                        onClose={() => setOpenOptionsTypesVehicle(false)}
                        getOptionSelected={(option, value) => option.id_vehicle_type === value.id_vehicle_type}
                        getOptionLabel={(option) => t(VehicleTypesMessages[option.id_vehicle_type])}
                        options={optionsTypesVehicle.sort((a, b) => -b.description.localeCompare(a.description))}
                        loading={loadingOptionsTypesVehicle}
                        disabled={modalType === "details"}
                        onChange={(event, value) => {
                          setActiveOptionTypeVehicle(value);
                          validations.validateFieldError("type");
                        }}
                        defaultValue={defineValueAccordingModalType(vehicleDetails.type, vehicleDetails.type, "")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="type"
                            label={t(VehiclesModuleMessages.vehicleType)}
                            margin="dense"
                            variant="outlined"
                            helperText={t(GlobalMessages.required)}
                            InputLabelProps={{ shrink: formRef.current?.getFieldValue("type").length > 0 ? true : undefined }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsTypesVehicle ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="maximum_speed_allowed"
                        label={t(VehiclesModuleMessages.maxSpeedAllowed)}
                        margin="dense"
                        type="number"
                        variant="outlined"
                        name="maximum_speed_allowed"
                        InputLabelProps={{ shrink: formRef.current?.getFieldValue("maximum_speed_allowed").length > 0 ? true : undefined }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(vehicleDetails.maximum_speed_allowed, vehicleDetails.maximum_speed_allowed, "")}
                        onChange={() => validations.validateFieldError("maximum_speed_allowed")}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        open={openOptionsLocationsVehicle}
                        onOpen={() => setOpenOptionsLocationsVehicle(true)}
                        onClose={() => setOpenOptionsLocationsVehicle(false)}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={optionsLocationsVehicle.sort((a, b) => -b.name.localeCompare(a.name))}
                        disabled={modalType === "details"}
                        loading={loadingOptionsLocationsVehicle}
                        onChange={(event, value) => setActiveOptionLocationVehicle(value)}
                        defaultValue={defineValueAccordingModalType(vehicleDetails.location, vehicleDetails.location, "")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="location"
                            label={t(VehiclesModuleMessages.allocationPlant)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ shrink: formRef.current?.getFieldValue("location").length > 0 ? true : undefined }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsLocationsVehicle ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        open={openOptionsDriversVehicle}
                        onOpen={() => setOpenOptionsDriversVehicle(true)}
                        onClose={() => setOpenOptionsDriversVehicle(false)}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={optionsDriversVehicle.sort((a, b) => -b.name.localeCompare(a.name))}
                        disabled={modalType === "details"}
                        loading={loadingOptionsDriversVehicle}
                        onChange={(event, value) => setActiveOptionDriverVehicle(value)}
                        defaultValue={defineValueAccordingModalType(vehicleDetails.driver, vehicleDetails.driver, "")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="driver"
                            label={t(VehiclesModuleMessages.defaultDriver)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{ shrink: formRef.current?.getFieldValue("driver").length > 0 ? true : undefined }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsDriversVehicle ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </Form>
              {(modalType === "details")
                && (
                  <div>
                    <TimeLineItemMap
                      latitude={Number(vehicleDetails.hardwares[0].last_latitude)}
                      longitude={Number(vehicleDetails.hardwares[0].last_longitude)}
                      vehicleTypeID={vehicleDetails.type.id_vehicle_type}
                      vehicleStateTypeID=""
                      lastSendTrackerDate={vehicleDetails.hardwares[0].last_send_date}
                    />
                  </div>
                )}

            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                  modalType === "details"
                    ? <></>
                    : modalType === "register"
                      ? <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.register)}</Button>
                      : <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.save)}</Button>
                }
              </div>
              <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        )}
      </ContainerModalFormVehicle>
    </Container>
  );
};

export default VehicleList;
