import { DataTableMessages, TranslationDataTableMessages } from "../interfaces";

export const messages: TranslationDataTableMessages = {
  [DataTableMessages.status]: "Status",
  [DataTableMessages.actions]: "Ações",
  [DataTableMessages.buttonsAddNew]: "Adicionar novo",
  [DataTableMessages.buttonsExport]: "Exportar",
  [DataTableMessages.buttonsPrint]: "Imprimir",
  [DataTableMessages.buttonsRefresh]: "Atualizar",
  [DataTableMessages.emptyTable]: "Nenhum registro encontrado",
  [DataTableMessages.info]: "Mostrando _START_ até _END_ de _TOTAL_ registros",
  [DataTableMessages.infoEmpty]: "Mostrando 0 até 0 de 0 registros",
  [DataTableMessages.infoFiltered]: "(Filtrados de _MAX_ registros)",
  [DataTableMessages.lengthMenu]: "_MENU_ registros por página",
  [DataTableMessages.loadingRecords]: "Carregando...",
  [DataTableMessages.processing]: "Processando...",
  [DataTableMessages.zeroRecords]: "Nenhum registro encontrado",
  [DataTableMessages.search]: "Buscar...",
  [DataTableMessages.searchPlaceholder]: "Filtrar dados em tela...",
  [DataTableMessages.filterTitle]: "Filtros",
  [DataTableMessages.filterApplied]: "Filtros aplicados a",
  [DataTableMessages.filterNoneApplied]: "Não há filtros aplicados",
  [DataTableMessages.filterCorrespondence]: "Correspondência de todos estes filtros",
  [DataTableMessages.filterAdd]: "Adicionar filtro",
  [DataTableMessages.filterRemoveAll]: "Remover todos",
  [DataTableMessages.filterApply]: "Aplicar filtro",
  [DataTableMessages.filterNewFilterField]: "Novo filtro de campo",
  [DataTableMessages.filterOperatorEquals]: "Igual a",
  [DataTableMessages.filterOperatorNotEquals]: "Diferente de",
  [DataTableMessages.filterOperatorStartsWith]: "Começa com",
  [DataTableMessages.filterOperatorContains]: "Contém",
  [DataTableMessages.filterOperatorNotContains]: "Não contém",
  [DataTableMessages.filterOperatorGreaterThan]: "Maior que",
  [DataTableMessages.filterOperatorLessThan]: "Menor que",
  [DataTableMessages.filterOperatorGreaterThanOrEqual]: "Maior ou igual a",
  [DataTableMessages.filterOperatorLessThanOrEqual]: "Menor ou igual a",
  [DataTableMessages.filterProperty]: "Propriedade",
  [DataTableMessages.filterValue]: "Valor",
  [DataTableMessages.filterOperator]: "Operador",
  [DataTableMessages.moreRecords]: "Carregar mais registros",
  [DataTableMessages.buttonsViewRoles]: "Cargos",
  [DataTableMessages.reportDriverRolesLabel]: "relatorio_motorista_cargos"
};
