/* eslint-disable import/prefer-default-export */
import {
  MenuMessages,
  TranslationMenuMessages
} from "../interfaces";

export const messages: TranslationMenuMessages = {
  [MenuMessages.production]: "Produção",
  [MenuMessages.pumps]: "Bombas",
  [MenuMessages.supportVehicles]: "Veículos de apoio",
  [MenuMessages.trucks]: "Carretas",
  [MenuMessages.dashboards]: "Dashboards",
  [MenuMessages.management]: "Gereciamento",
  [MenuMessages.users]: "Usuários",
  [MenuMessages.modules]: "Módulos",
  [MenuMessages.drivers]: "Motoristas",
  [MenuMessages.vehicles]: "Veículos",
  [MenuMessages.locations]: "Locais",
  [MenuMessages.hardwares]: "Hardwares",
  [MenuMessages.SIMCards]: "SIM Cards",
  [MenuMessages.reports]: "Relatórios",
  [MenuMessages.scheduledReports]: "Relatórios agendados",
  [MenuMessages.travelHistory]: "Histórico de viagens",
  [MenuMessages.alertHistory]: "Histórico de alertas",
  [MenuMessages.averageSpeed]: "Velocidade média",
  [MenuMessages.coordinateAnalysis]: "Análise de coordenadas",
  [MenuMessages.vehicleHistory]: "Histórico de veículos",
  [MenuMessages.vehicleStatesHistory]: "Histórico de estados de veículo",
  [MenuMessages.others]: "Outros",
  [MenuMessages.contactUs]: "Fale conosco",
  [MenuMessages.settings]: "Configurações",
  [MenuMessages.installApp]: "Instalar aplicativo",
  [MenuMessages.exit]: "Sair",
  [MenuMessages.generalLocation]: "Localização geral",
  [MenuMessages.vehiclesUnderMaintenance]: "Veículos em manutenção",
  [MenuMessages.alerts]: "Alertas",
  [MenuMessages.speedingAlerts]: "Alerta de limite de velocidade",
  [MenuMessages.concreteExpiration]: "Validade do concreto",
  [MenuMessages.improperDischarges]: "Descarregamentos indevidos",
  [MenuMessages.vehiclesInTrustedLocations]: "Veículos em locais confiáveis",
  [MenuMessages.blockedVehicles]: "Veículos bloqueados",
  [MenuMessages.regionals]: "Regionais",
  [MenuMessages.help]: "Ajuda",
  [MenuMessages.groups]: "Grupos",
  [MenuMessages.pumpProgramming]: "Programação de bombas",
  [MenuMessages.zones]: "Zonas de Restrição",
  [MenuMessages.driversRoles]: "Cargos dos motoristas"
};
