import React, { useMemo } from "react";

import { PrepareDataContent } from "@shared/interfaces/socket.interface";
import { CoordinateAnalysisMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";

import { useAuth } from "@hooks/useAuth";
import { TFunction } from "i18next";
import * as Styled from "./styles";

interface CoordinateAnalysisDetailsProps {
  data: PrepareDataContent;
}

/**
 *  * Formats the command title for display to administrators.
 * Adds descriptive prefixes related to commands, making them visible
 * to administrators with additional information
 * @param commandContentTitle
 * @param t
 */
const formatAdminCommandTitle = (commandContentTitle: string, t: TFunction) => {
  switch (commandContentTitle) {
  case "HDR": return `[HDR] ${t(CoordinateAnalysisMessages.command)}`;
  case "DEV_ID": return `[DEV_ID] ${t(CoordinateAnalysisMessages.identifier)}`;
  case "MODEL": return `[MODEL] ${t(CoordinateAnalysisMessages.model)}`;
  case "SW_VER": return `[SW_VER] ${t(CoordinateAnalysisMessages.softwareVersion)}`;
  case "DATE": return `[DATE] ${t(CoordinateAnalysisMessages.date)}`;
  case "TIME": return `[TIME] ${t(CoordinateAnalysisMessages.time)}`;
  case "CELL": return `[CELL] ${t(CoordinateAnalysisMessages.locationCode)}`;
  case "LAT": return `[LAT] ${t(CoordinateAnalysisMessages.latitude)}`;
  case "LON": return `[LON] ${t(CoordinateAnalysisMessages.longitude)}`;
  case "SPD": return `[SPD] ${t(CoordinateAnalysisMessages.speed)}`;
  case "CRS": return `[CRS] ${t(CoordinateAnalysisMessages.inclination)}`;
  case "SATT": return `[SATT] ${t(CoordinateAnalysisMessages.activeSatellites)}`;
  case "FIX": return `[FIX] ${t(CoordinateAnalysisMessages.validPosition)}`;
  case "DIST": return `[DIST] ${t(CoordinateAnalysisMessages.odometer)}`;
  case "PWR_VOLT": return `[PWR_VOLT] ${t(CoordinateAnalysisMessages.batteryVoltage)}`;
  case "I/O": return `[I/O] ${t(CoordinateAnalysisMessages.inAndOutInputs)}`;
  case "MODE": return `[MODE] ${t(CoordinateAnalysisMessages.activeMode)}`;
  case "MSG_NUM": return `[MSG_NUM] ${t(CoordinateAnalysisMessages.numberOfMessages)}`;
  case "H_METER": return `[H_METER] ${t(CoordinateAnalysisMessages.hourMeter)}`;
  case "BCK_VOLT": return `[BCK_VOLT] ${t(CoordinateAnalysisMessages.backupBatteryVoltage)}`;
  case "MSG_TYPE": return `[MSG_TYPE] ${t(CoordinateAnalysisMessages.messageType)}`;
  case "DID": return `[DID] ${t(CoordinateAnalysisMessages.driverIdentifier)}`;
  case "DID_REG": return `[DID_REG] ${t(CoordinateAnalysisMessages.registrationStatus)}`;
  case "CELL_ID": return `[CELL_ID] ${t(CoordinateAnalysisMessages.serviceIdentification)}`;
  case "MCC": return `[MCC] ${t(CoordinateAnalysisMessages.countryCode)}`;
  case "MNC": return `[MNC] ${t(CoordinateAnalysisMessages.networkCode)}`;
  case "RX_LVL": return `[RX_LVL] ${t(CoordinateAnalysisMessages.receptionLevel)}`;
  case "LAC": return `[LAC] ${t(CoordinateAnalysisMessages.locationAreaCode)}`;
  case "TM_ADV": return `[TM_ADV] ${t(CoordinateAnalysisMessages.temporalInfo)}`;
  case "GPS_ON_OFF": return `[GPS_ON_OFF] ${t(CoordinateAnalysisMessages.GPSStatus)}`;
  case "CAN_VIN": return `[CAN_VIN] ${t(CoordinateAnalysisMessages.vehicleIdentifier)}`;
  case "CAN_IGN": return `[CAN_IGN] ${t(CoordinateAnalysisMessages.ignitionKey)}`;
  case "CAN_ODOMETER": return `[CAN_ODOMETER] ${t(CoordinateAnalysisMessages.CANOdometer)}`;
  case "CAN_TOTAL_FUEL_USED": return `[CAN_TOTAL_FUEL_USED] ${t(CoordinateAnalysisMessages.totalFuelConsumption)}`;
  case "CAN_SPD": return `[CAN_SPD] ${t(CoordinateAnalysisMessages.CANSpeed)}`;
  case "CAN_ENGINE_RPM": return `[CAN_ENGINE_RPM] ${t(CoordinateAnalysisMessages.engineRPM)}`;
  case "CAN_ENGINE_COOLANT_TEMPERATURE": return `[CAN_ENGINE_COOLANT_TEMPERATURE] ${t(CoordinateAnalysisMessages.engineCoolantTemperature)}`;
  case "CAN_FUEL_CONSUMPTION": return `[CAN_FUEL_CONSUMPTION] ${t(CoordinateAnalysisMessages.fuelConsumption)}`;
  case "CAN_FUEL_LEVEL": return `[CAN_FUEL_LEVEL] ${t(CoordinateAnalysisMessages.fuelLevel)}`;
  case "CAN_TOTAL_ENGINE_HOURS": return `[CAN_TOTAL_ENGINE_HOURS] ${t(CoordinateAnalysisMessages.CANHourMeter)}`;
  case "CAN_TOTAL_DRIVING_TIME": return `[CAN_TOTAL_DRIVING_TIME] ${t(CoordinateAnalysisMessages.drivingTime)}`;
  case "CAN_TOTAL_IDLE_FUEL_USED": return `[CAN_TOTAL_IDLE_FUEL_USED] ${t(CoordinateAnalysisMessages.fuelConsumptionInIdle)}`;
  case "CAN_GROSS_COMBINATION_VEHICLE_WEIGHT": return `[CAN_GROSS_COMBINATION_VEHICLE_WEIGHT] ${t(CoordinateAnalysisMessages.vehicleWeight)}`;
  case "MIXER_DIAGNOSTIC": return `[MIXER_DIAGNOSTIC] ${t(CoordinateAnalysisMessages.sensorStatus)}`;
  case "MIXER_INCLINATION": return `[MIXER_INCLINATION] ${t(CoordinateAnalysisMessages.drumInclination)}`;
  case "MIXER_TOTAL_WATER_ADDED": return `[MIXER_TOTAL_WATER_ADDED] ${t(CoordinateAnalysisMessages.totalWaterAdded)}`;
  case "MIXER_PRESSURE": return `[MIXER_PRESSURE] ${t(CoordinateAnalysisMessages.drumPressure)}`;
  case "MIXER_RPM": return `[MIXER_RPM] ${t(CoordinateAnalysisMessages.mixerRPM)}`;
  case "MIXER_TEMPERATURE": return `[MIXER_TEMPERATURE] ${t(CoordinateAnalysisMessages.drumTemperature)}`;
  case "MIXER_BAT_VOLT": return `[MIXER_BAT_VOLT] ${t(CoordinateAnalysisMessages.mixerBatteryVoltage)}`;

  default: return commandContentTitle;
  }
};
/**
 *  * Formats the command title for display to the general public.
 * Adds descriptive prefixes related to commands, adapting them for
 * public display without sensitive information or specific details.
 * @param commandContentTitle
 * @param t
 */
const formatGeneralCommandTitle = (commandContentTitle: string, t: TFunction) => {
  switch (commandContentTitle) {
  case "LAT":
    return `${t(CoordinateAnalysisMessages.latitude)}`;
  case "LON":
    return `${t(CoordinateAnalysisMessages.longitude)}`;
  case "SPD":
    return `${t(CoordinateAnalysisMessages.speed)}`;
  case "DIST":
    return `${t(CoordinateAnalysisMessages.odometer)}`;
  case "H_METER":
    return `${t(CoordinateAnalysisMessages.hourMeter)}`;
  case "I/O":
    return `${t(CoordinateAnalysisMessages.ignition)}`;
  default:
    return undefined;
  }
};
const CoordinateAnalysisCommandDetails: React.FC<CoordinateAnalysisDetailsProps> = ({ data }) => {

  const { user } = useAuth();
  const { t } = useTranslation();

  const formatDataForDisplay = useMemo(() => {
    const dataFormatted = {};

    for (const key in data) {
      if (user?.super_admin) {
        const newKey = formatAdminCommandTitle(key, t);

        dataFormatted[newKey] = data[key];
      } else {
        const newKey = formatGeneralCommandTitle(key, t);

        if (newKey && newKey !== `${t(CoordinateAnalysisMessages.ignition)}`) {
          dataFormatted[newKey] = data[key];
        } else if (newKey) {
          if (data[key].content[0] === "1") {
            // eslint-disable-next-line no-param-reassign
            dataFormatted[newKey] = {
              content: `${t(CoordinateAnalysisMessages.ignitionOn)}`
            };
          } else {
            // eslint-disable-next-line no-param-reassign
            dataFormatted[newKey] = {
              content: `${t(CoordinateAnalysisMessages.ignitionOff)}`
            };
          }
        }
      }
    }

    return dataFormatted;
  }, [data, user, t]);

  return (
    <Styled.Container>
      {Object.keys(formatDataForDisplay).map((key) => (
        <Styled.DetailsItem key={`${data["DATE"]}-${key}`}>
          <div className="coordinate-analysis-details__first">
            {key}
          </div>
          <div className="coordinate-analysis-details__second">{formatDataForDisplay[key].content}</div>

        </Styled.DetailsItem>
      ))}
    </Styled.Container>
  );
};

export default CoordinateAnalysisCommandDetails;
