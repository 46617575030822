import React from "react";

// region Libraries
import { FormControlLabel } from "@material-ui/core";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { MapMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Components
import { MarkersVisualizationTypes } from "../Map";
// endregion Components
// region Styles
import { ContainerToggleSwitch, ToggleSwitch } from "./styles";
// endregion Styles

type MapMarkerChangersProps = {
  markersVisualizationType: MarkersVisualizationTypes;
  setMarkersVisualizationType: React.Dispatch<React.SetStateAction<MarkersVisualizationTypes>>;
}

const MapMarkerChangers: React.FC<MapMarkerChangersProps> = ({ markersVisualizationType, setMarkersVisualizationType }) => {

  const { t } = useTranslation();

  const handleToogleMarkerVisualization = (visualizationToToogle: keyof MarkersVisualizationTypes) => {
    if (visualizationToToogle === "name") {
      setMarkersVisualizationType(((prevState) => ({
        name: !prevState.name,
        licensePlate: prevState.licensePlate
      })));
    } else {
      setMarkersVisualizationType(((prevState) => ({
        name: prevState.name,
        licensePlate: !prevState.licensePlate
      })));
    }
  };

  return (
    <ContainerToggleSwitch>
      <FormControlLabel
        control={(
          <ToggleSwitch
            checked={markersVisualizationType.name}
            onChange={() => handleToogleMarkerVisualization("name")}
          />
        )}
        label={t(MapMessages.names)}
      />
      <FormControlLabel
        control={(
          <ToggleSwitch
            checked={markersVisualizationType.licensePlate}
            onChange={() => handleToogleMarkerVisualization("licensePlate")}
          />
        )}
        label={t(MapMessages.licensePlates)}
      />
    </ContainerToggleSwitch>
  );
};

export default MapMarkerChangers;
