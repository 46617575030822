import { Reducer } from "redux";
import { FiltersDashboardState, FiltersDashboardTypes } from "./filters-dashboard.type";

export const INITIAL_STATE: FiltersDashboardState = {
  active: false,
  vehiclesTypes: {
    betoneiras: true,
    bombas: true,
    paCarregadeira: true,
    veiculoApoio: true
  },
  constructions: [],
  travelDocs: [],
  plants: [],
  vehicles: [],
  regionals: [],
  groups: []
};

const reducer: Reducer<FiltersDashboardState> = (state = INITIAL_STATE, action) => {

  switch (action.type) {
  case FiltersDashboardTypes.SET_FILTERS_DASHBOARD:
    return { ...INITIAL_STATE, ...action.payload };
  default:
    return state;
  }
};

export default reducer;
