/* eslint-disable import/prefer-default-export */
import { TranslationFilterMessages, FilterMessages } from "../interfaces";

export const messages: TranslationFilterMessages = {
  [FilterMessages.filtersTitle]: "Filters",
  [FilterMessages.noVehicles]: "No vehicles",
  [FilterMessages.noNumDoc]: "Not Integrated",
  [FilterMessages.noVehiclesLinkedToAPlant]: "No vehicles linked to a plant",
  [FilterMessages.noVehiclesLinkedToARegional]: "No vehicles linked to a regional",
  [FilterMessages.noVehiclesLinkedToAGroup]: "No vehicles linked to a group",
  [FilterMessages.noConstruction]: "No construction",
  [FilterMessages.noTravelDocs]: "No documents",
  [FilterMessages.selectSomethingToFilter]: "Select something to filter",
  [FilterMessages.selectAtLeastOneVehicleType]: "Select at least one vehicle type",
  [FilterMessages.concreteMixers]: "Concrete mixers",
  [FilterMessages.pumpTrucks]: "Pump trucks",
  [FilterMessages.supportVehicles]: "Support vehicles",
  [FilterMessages.loaders]: "Loaders",
  [FilterMessages.clearFilters]: "Clear filters",
  [FilterMessages.applyFilters]: "Apply filters",
  [FilterMessages.filterInstructions]: "Filter the information for better results",
  [FilterMessages.optionUtcOffset]: "Time Zone Offset (UTC)",
  [FilterMessages.optionPumps]: "Pump trucks",

  [FilterMessages.optionVehicleTypes]: "Vehicle types",
  [FilterMessages.optionNumDoc]: "Document number",
  [FilterMessages.optionStartDate]: "Start date",
  [FilterMessages.optionEndDate]: "End date",
  [FilterMessages.optionVehicles]: "Vehicles",
  [FilterMessages.optionPlants]: "Plants",
  [FilterMessages.optionConstructions]: "Constructions",
  [FilterMessages.optionTravelDocs]: "Travel documents",
  [FilterMessages.optionClients]: "Clients",
  [FilterMessages.optionAlertTypes]: "Alert types",
  [FilterMessages.optionDrivers]: "Drivers",
  [FilterMessages.optionRegionals]: "Regionals",
  [FilterMessages.optionGroups]: "Groups",
  [FilterMessages.optionTravels]: "Travels",
  [FilterMessages.optionReportType]: "Report type",
  [FilterMessages.optionVehicleStateTypes]: "State types",

  [FilterMessages.applicationVehicleHistory]: "Vehicle history",
  [FilterMessages.applicationAlerts]: "Alert history",
  [FilterMessages.applicationTravelHistory]: "Travel history",
  [FilterMessages.applicationCoordinateHistory]: "Coordinate history",
  [FilterMessages.applicationVehicleStateHistory]: "Vehicle state history",
  [FilterMessages.applicationVehicleAnalyticReport]: "Vehicle analytic report",

  [FilterMessages.dateRangeLabel]: "Interval",
  [FilterMessages.filterOptions]: "Filter options",
  [FilterMessages.enterTextToFilter]: "Enter text to filter",
  [FilterMessages.selectAll]: "Select all",
  [FilterMessages.removeAll]: "Remove all",
  [FilterMessages.selectAllFiltered]: "Select all filtered options",
  [FilterMessages.removeAllFiltered]: "Remove all filtered options",
  [FilterMessages.savedFilters]: "Saved filters",
  [FilterMessages.chooseFilterName]: "Choose filter name",
  [FilterMessages.filterName]: "Filter name",
  [FilterMessages.saveNewFilter]: "Save selections as new filter",
  [FilterMessages.saveChanges]: "Save changes to selected filter",

  // Filter backend messages
  [FilterMessages.noFilterFound]: "No filter found",
  [FilterMessages.readSuccess]: "Filters returned successfully",
  [FilterMessages.readError]: "Error fetching filters",
  [FilterMessages.createSuccess]: "Filter created successfully",
  [FilterMessages.createError]: "Error creating filter",
  [FilterMessages.updateSuccess]: "Filter updated successfully",
  [FilterMessages.updateError]: "Error updating filter",
  [FilterMessages.deleteSuccess]: "Filter deleted successfully",
  [FilterMessages.deleteError]: "Error deleting filter"
};
