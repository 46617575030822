import { TranslationMailMessages, MailMessages } from "../interfaces";

export const messages: TranslationMailMessages = {

  // Password Reset
  [MailMessages.passwordReset]: "Password reset",
  [MailMessages.looksLikeYouForgotYourPassword]: "Looks like you forgot your password for",
  [MailMessages.clickHereToResetYourPassword]: "Click below to reset your password.",
  [MailMessages.setYourNewPassword]: "Set your new password.",
  [MailMessages.resetMyPassword]: "Reset my password",
  [MailMessages.ignoreEmailIfPasswordNotForgotten]: "If you didn't forget your password, you can ignore this email.",

  // Account confirmation
  [MailMessages.accountConfirmation]: "Account confirmation",
  [MailMessages.welcomeTo]: "Welcome to",
  [MailMessages.accessToPlatform]: "Access to platform",
  [MailMessages.confirmYourAccount]: "Confirm your account and set your password to access the platform.",
  [MailMessages.createMyPassword]: "Create my password",

  // General
  [MailMessages.hello]: "Hello",
  [MailMessages.needMoreInformation]: "Have any questions or need more information?",
  [MailMessages.sendUsAnEmail]: "Send us an email",
  [MailMessages.bestRegards]: "Best regards",
  [MailMessages.distributedBy]: "Distributed by",

  // Report
  [MailMessages.dailyReportSubject]: "Daily report - TopconFLEET",
  [MailMessages.weeklyReportSubject]: "Weekly report - TopconFLEET",
  [MailMessages.syntheticReportFileName]: "synthetic-report.pdf",
  [MailMessages.analyticalReportFileName]: "analytical-report.pdf",
  [MailMessages.belowYouCanSeeTheAlertGraphs]: "Below you can see the alert graphs",
  [MailMessages.ofTheLastTwoWeeks]: "of the last two weeks",
  [MailMessages.ofTheLastTwoDays]: "of the last two days",
  [MailMessages.moreInformationCanBeFoundAt]: "More detailed information can be found in the attached PDF reports.",

  // Report E-mail expirated license
  [MailMessages.textInformationCNH]: "Driver's License Information",
  [MailMessages.textCloserExpirationCNH]: "is close to expiration!",
  [MailMessages.textExpiredCNH]: "is already expired!",
  [MailMessages.textRegisterCNH]: "Register",
  [MailMessages.textEmissionDateCNH]: "Emission Date",
  [MailMessages.textDaysToExpireCNH]: "Days to Expire",
  [MailMessages.textDaysPassedExpirationCNH]: "Days after Expiration",
  [MailMessages.textExpirateTodayCNH]: "Expires today",
  [MailMessages.textExpirateDateCNH]: "Expiration Date",
  [MailMessages.textRestrictionCNH]: "Has Restrictions",
  [MailMessages.textBranchDriver]: "Branch",
  [MailMessages.textGroupDriver]: "Group",
  [MailMessages.textRegionalsDriver]: "Regional",
  [MailMessages.textCenterDriver]: "Center",

};
