import { RegionalsModuleMessages, TranslationRegionalsModuleMessages } from "../interfaces";

export const messages: TranslationRegionalsModuleMessages = {

  [RegionalsModuleMessages.name]: "Nome",
  [RegionalsModuleMessages.noNameRegistered]: "Sem nome cadastrado",
  [RegionalsModuleMessages.active]: "Ativo",
  [RegionalsModuleMessages.inactive]: "Inativo",
  [RegionalsModuleMessages.externalId]: "ID Externo",
  [RegionalsModuleMessages.registrationDate]: "Data de registro",
  [RegionalsModuleMessages.updatedDate]: "Data de Atualização",
  [RegionalsModuleMessages.code]: "Código",
  [RegionalsModuleMessages.deleteConfirmationText]: "Tem certeza de que deseja excluir a regional?",
  [RegionalsModuleMessages.deleteRegionalError]: "Falha ao excluir regional",

  [RegionalsModuleMessages.deleteRegionalSuccess]: "Regional excluída com sucesso",
  [RegionalsModuleMessages.updateRegionalSuccess]: "Regional atualizada com sucesso",
  [RegionalsModuleMessages.updateRegionalError]: "Não foi possivel atualizar a regional",
  [RegionalsModuleMessages.readRegionalSuccess]: "Regional(s) retornada(s) com sucesso",
  [RegionalsModuleMessages.noRegionalFound]: "Nenhuma regional encontrada",
  [RegionalsModuleMessages.createRegionalSuccess]: "Regional criada com sucesso",
  [RegionalsModuleMessages.createRegionalError]: "Não foi possivel criar a regional"

};
