import React, { useCallback, useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MapIcon from "@material-ui/icons/Map";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { MapConfig } from "@shared/constants/map.enum";

import { Location } from "@shared/interfaces/location.interface";
import { Regional } from "@shared/interfaces/regional.interface";
import { LocationType } from "@shared/interfaces/location-type.interface";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { Checkbox, TextField } from "unform-material-ui";

import * as _ from "lodash";
import * as Yup from "yup";

import useTranslation from "src/translations/useTranslation";
import {
  GlobalMessages,
  LocationsModuleMessages, MenuMessages,
  ToastMessages
} from "@shared/languages/interfaces";
import { useAuth } from "@hooks/useAuth";
import { GoogleApiRequestTypes } from "@shared/constants/google-api-request-types.enum";
import { LocationTypesID } from "@shared/constants/location-types.enum";
import api from "../../services/api";
import { useToast } from "../../hooks/useToast";
import { LocationsFormActions } from "../../store/ducks/Locations/LocationsForm/locations-form.action";
import {
  Coord,
  LocationsFormState,
  State,
  states
} from "../../store/ducks/Locations/LocationsForm/locations-form.type";

import getValidationErrors from "../../hooks/getValidationErrors";

import ButtonLoading from "../../components/Button/ButtonLoading";
import DialogConfirmAction from "../../components/Dialog/ConfirmAction";
import { MapAddressDataByMarker } from "../../components/Map";

import { ContainerModalFormLocation, ContainerModalMapMarker } from "./styles";

interface FormFieldsData { name: string; description: string, oldContent: string; newContent: string; abbreviation?: string }
interface ValidateFields { externalId: boolean; }

type ModalFormLocationProps = {
  readLocations?: () => void,
  locations?: Location[],
  table?: { reference: DataTables.Api, clickedRow: JQuery<HTMLTableRowElement> }
}
const ModalFormLocation: React.FC<ModalFormLocationProps> = (
  { readLocations, locations, table }
) => {

  /* region Hooks */
  const locationsForm = useSelector(({ locationsForm: state }) => state);
  const dispatch = useDispatch();

  const { addToast } = useToast();
  const { t } = useTranslation();
  const { user } = useAuth();
  /* endregion Hooks */
  /* region States */
  const [optionsTypesLocation, setOptionsTypesLocation] = useState<LocationType[]>([] as LocationType[]);
  const [optionsRegional, setOptionsRegional] = useState<Regional[]>([] as Regional[]);
  const [optionsUfLocation] = useState<State[]>(states as State[]);

  const [addressData, setAddressData] = useState<google.maps.GeocoderResult>({} as google.maps.GeocoderResult);
  const [addressConfirmChangeData, setAddressConfirmChangeData] = useState<FormFieldsData[]>([] as FormFieldsData[]);

  const [openModalMapMarker, setOpenModalMapMarker] = useState(false);
  const [openDialogConfirmChangeAddressData, setOpenDialogConfirmChangeAddressData] = useState(false);
  const [openOptionsTypesLocation, setOpenOptionsTypesLocation] = useState(false);
  const [openOptionsRegionals, setOpenOptionsRegionals] = useState(false);

  const loadingOptionsTypesLocation = openOptionsTypesLocation && optionsTypesLocation.length === 0;
  const loadingOptionsRegionals = openOptionsRegionals && optionsRegional.length === 0;
  const [loadingValidateFieldExternalId, setLoadingValidateFieldExternalId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGetAddressByCep, setLoadingGetAddressByCep] = useState(false);

  const [validateFields, setValidateFields] = useState<ValidateFields>({ externalId: false });
  const [radius, setRadius] = useState<string>(locationsForm?.data?.radius ? `${locationsForm?.data?.radius}` : `${MapConfig.RADIUS}`);

  const [disableRegionalField, setDisableRegionalField] = useState<boolean>(true);
  /* endregion States */
  /* region Forms */
  const formRef = useRef<FormHandles>(null);

  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateCep: (cep: string): boolean => {

      if (cep.trim().length < 9 && cep.trim().length > 0) {
        formRef.current?.setFieldError("cep", t(LocationsModuleMessages.formZipCodeIncomplete));

        return false;
      }

      formRef.current?.setFieldError("cep", "");

      return true;

    },
    validateExternalId: async (externalId: string) => {

      setValidateFields({ ...validateFields, externalId: false });

      // Validate re just have 1 or more characters in on blur
      if (externalId.trim().length >= 1 && externalId.trim() !== locationsForm.data.external_id) {

        try {

          setLoadingValidateFieldExternalId(true);

          // Verify if RE is already registered
          const { data } = await api.get(`locations/verify-unique-field/external_id/${externalId.trim()}`);

          if (data.status === "alert") formRef.current?.setFieldError("external_id", t(LocationsModuleMessages.externalIDAlreadyRegistered));
          else formRef.current?.setFieldError("re", "");

          setValidateFields({ ...validateFields, externalId: true });

        } catch (error: any) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
        } finally {
          setLoadingValidateFieldExternalId(false);
        }
      }
    },
    validateForm: async (formData: Location, action: "details" | "register" | "update") => {

      try {

        // Revalidate unique fields (If not validate yet)
        if (!validateFields.externalId && formData.external_id.trim().length) await validations.validateExternalId(formData.external_id);

        // Define the validation types
        const schema = Yup.object().shape({
          type: Yup.string().trim().required(t(LocationsModuleMessages.formTypeRequired)),
          address: Yup.string().trim().required(t(LocationsModuleMessages.formAddressRequired)),
          district: Yup.string().trim().required(t(LocationsModuleMessages.formDistrictRequired)),
          county: Yup.string().trim().required(t(LocationsModuleMessages.formCountyRequired)),
          name: Yup.string().trim().required(t(LocationsModuleMessages.formNameRequired)),
          uf: Yup.string().trim().required(t(LocationsModuleMessages.formStateRequired)),
          cep: Yup.string().trim().test("validateCep", formRef.current?.getFieldError("cep"),
            () => !(formRef.current?.getFieldError("cep")?.length)),
          external_id: Yup.string().trim().test("validateExternalId", formRef.current?.getFieldError("external_id"),
            () => !(formRef.current?.getFieldError("external_id")?.length))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // No coords defined
        if (_.isEmpty(locationsForm.fieldsValues.coord)) {

          addToast({
            type: "info",
            title: t(LocationsModuleMessages.toastCoordsNotDefinedTitle),
            description: t(LocationsModuleMessages.toastCoordsNotDefinedDescription)
          });

          return;
        }

        // Register or update locations (According action selected)
        if (action === "register") await createLocation(formData);
        else await updateLocation(formData);

      } catch (error: any) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Forms */

  /* region Functions */
  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setValidateFields({ externalId: false });

    dispatch(LocationsFormActions.setLocationsForm({
      ...locationsForm,
      open: false,
      externalComponent: false,
      googleAddressData: {} as google.maps.GeocoderResult,
      fieldsValues: {
        uf: {} as State,
        regional: {} as Regional,
        coord: {} as Coord,
        type: {} as LocationType,
        cep: ""
      }
    }));

    setDisableRegionalField(true);

  }, [dispatch, locationsForm]);

  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (locationsForm.type) {
    case "details": return detailValue;
    case "update": return updateValue;
    case "register": return registerValue;
    default: return "S/N";
    }
  }, [locationsForm.type]);

  /** Get address data by CEP (Google API - Geocode)
   * @param cep Cep to search data address
   */
  const getDataAddressByCep = useCallback(async (cep: string) => {

    // If cep completed and with content, we search the address data
    if (validations.validateCep(cep) && cep.trim().length) {

      try {

        setLoadingGetAddressByCep(true);
        const { data } = await api.post(
          "locations/google-api/get-data-by-google",
          { address: cep, description: GoogleApiRequestTypes.ADDRESS_FROM_ZIPCODE, user: { name: user.name, email: user.email } }
        );

        if (data.status === "success") {

          // Verify if google search any data with this zip code
          if (data.result.results.length) {
            dispatch(LocationsFormActions.setLocationsForm({ ...locationsForm, googleAddressData: data.result.results[0] }));
          } else {
            addToast({
              type: "info",
              title: t(ToastMessages.alert),
              description: `${t(LocationsModuleMessages.toastNoZipCodeDataFoundDescription)} ${cep}`
            });
          }

        } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      } catch (error: any) {
        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      } finally {
        setLoadingGetAddressByCep(false);
      }
    }

  }, [addToast, validations, locationsForm, dispatch, t, user.name, user.email]);

  /** Confirmation of the address set on the map
   * Change the referring fields on the form according to the data of the set point in question
   * (If there was a field change, a confirmation dialog opens showing the data that will be changed)
   */
  const confirmAddressDataByMap = useCallback(() => {

    if (_.isEmpty(locationsForm.googleAddressData)) {
      addToast({
        type: "info",
        title: t(ToastMessages.alert),
        description: t(LocationsModuleMessages.toastSelectLocationInMapDescription) });
    } else {

      const fieldsChanged: FormFieldsData[] = [] as FormFieldsData[];
      const stateReduxValues: LocationsFormState = { ...locationsForm };

      // Fill in the form fields with the requested address data
      // (Only the fields that came by request Google API)
      locationsForm.googleAddressData.address_components.forEach((addressComponent) => {
        addressComponent.types.forEach((type) => {

          // Fill the corresponding field if the same is empty, case the field
          // is already load, show a dialog to user confirm the change content
          switch (type) {
          case "route": // Logradouro

            if (_.isEmpty(formRef.current?.getFieldValue("address"))) {
              formRef.current?.setFieldValue("address", addressComponent.long_name);
              formRef.current?.setFieldError("address", "");
            } else if (formRef.current?.getFieldValue("address") !== addressComponent.long_name) {

              fieldsChanged.push({
                name: "address",
                description: "Logradouro",
                oldContent: formRef.current?.getFieldValue("address"),
                newContent: addressComponent.long_name
              });
            }

            break;
          case "administrative_area_level_1": // Estado

            if (_.isEmpty(formRef.current?.getFieldValue("uf"))) {
              stateReduxValues.fieldsValues.uf = { description: addressComponent.long_name, abbreviation: addressComponent.short_name };
              formRef.current?.setFieldError("uf", "");
            } else if (formRef.current?.getFieldValue("uf") !== addressComponent.long_name) {

              fieldsChanged.push({
                name: "uf",
                description: "Estado",
                oldContent: formRef.current?.getFieldValue("uf"),
                newContent: addressComponent.long_name,
                abbreviation: addressComponent.short_name
              });
            }

            break;
          case "administrative_area_level_2": // Município

            if (_.isEmpty(formRef.current?.getFieldValue("county"))) {
              formRef.current?.setFieldValue("county", addressComponent.long_name);
              formRef.current?.setFieldError("county", "");
            } else if (formRef.current?.getFieldValue("county") !== addressComponent.long_name) {

              fieldsChanged.push({
                name: "county",
                description: "Município",
                oldContent: formRef.current?.getFieldValue("county"),
                newContent: addressComponent.long_name
              });
            }

            break;
          case "sublocality": // Bairro

            if (_.isEmpty(formRef.current?.getFieldValue("district"))) formRef.current?.setFieldValue("district", addressComponent.long_name);
            else if (formRef.current?.getFieldValue("district") !== addressComponent.long_name) {

              fieldsChanged.push({
                name: "district",
                description: "Bairro",
                oldContent: formRef.current?.getFieldValue("district"),
                newContent: addressComponent.long_name
              });
            }

            break;
          case "street_number": // Número

            if (_.isEmpty(formRef.current?.getFieldValue("number"))) {
              formRef.current?.setFieldValue("number", addressComponent.long_name.replace(/[^\d].*/, ""));
            } else if (formRef.current?.getFieldValue("number") !== addressComponent.long_name) {

              fieldsChanged.push({
                name: "number",
                description: "Número",
                oldContent: formRef.current?.getFieldValue("number"),
                newContent: addressComponent.long_name.replace(/[^\d].*/, "")
              });
            }

            break;
          case "postal_code": // CEP

            if (_.isEmpty(formRef.current?.getFieldValue("cep"))) {
              stateReduxValues.fieldsValues.cep = addressComponent.long_name;
            } else if (formRef.current?.getFieldValue("cep") !== addressComponent.long_name) {

              fieldsChanged.push({
                name: "cep",
                description: "CEP",
                oldContent: formRef.current?.getFieldValue("cep"),
                newContent: addressComponent.long_name
              });
            }

            break;
          default: break;
          }
        });
      });

      // Verify if have any field value change
      if (_.isEmpty(fieldsChanged)) {

        stateReduxValues.fieldsValues.coord = {
          lat: _.isNumber(locationsForm.googleAddressData.geometry.location.lat)
            ? Number(locationsForm.googleAddressData.geometry.location.lat)
            : locationsForm.googleAddressData.geometry.location.lat(),
          lng: _.isNumber(locationsForm.googleAddressData.geometry.location.lng)
            ? Number(locationsForm.googleAddressData.geometry.location.lng)
            : locationsForm.googleAddressData.geometry.location.lng()
        };

        addToast({ type: "success", title: t(LocationsModuleMessages.toastDataLoadedDescription) });
        setOpenModalMapMarker(false);

      } else {
        setAddressConfirmChangeData(fieldsChanged);
        setOpenDialogConfirmChangeAddressData(true);
      }
    }

  }, [addToast, formRef, locationsForm, t]);

  /** Confirmation of changes in address data set on the map
   */
  const confirmChangeAddressDataByMap = useCallback((confirmChange: boolean) => {

    if (confirmChange) {

      const stateReduxValues: LocationsFormState = { ...locationsForm };

      addressConfirmChangeData.forEach((data) => {
        if (data.name === "uf") stateReduxValues.fieldsValues.uf = { description: data.newContent, abbreviation: data.abbreviation || "" };
        else if (data.name === "cep") stateReduxValues.fieldsValues.cep = data.newContent;
        else formRef.current?.setFieldValue(data.name, data.newContent);
      });

      stateReduxValues.fieldsValues.coord = {
        lat: _.isNumber(locationsForm.googleAddressData.geometry.location.lat)
          ? Number(locationsForm.googleAddressData.geometry.location.lat)
          : locationsForm.googleAddressData.geometry.location.lat(),
        lng: _.isNumber(locationsForm.googleAddressData.geometry.location.lng)
          ? Number(locationsForm.googleAddressData.geometry.location.lng)
          : locationsForm.googleAddressData.geometry.location.lng()
      };

      addToast({ type: "success", title: t(LocationsModuleMessages.toastDataLoadedDescription) });
      setOpenModalMapMarker(false);
    }

    setOpenDialogConfirmChangeAddressData(false);

  }, [addToast, addressConfirmChangeData, locationsForm, t]);

  /** Create a location
   * @param location Location to create
   */
  const createLocation = useCallback(async (location: Location) => {

    try {

      let insertLocation = { ...location };

      // Remove properties without value
      Object.keys(insertLocation).forEach((key) => {
        if (insertLocation[key].length <= 0) delete insertLocation[key];
      });

      if (insertLocation.uf) insertLocation.uf = locationsForm.fieldsValues.uf.abbreviation;
      if (insertLocation.type) insertLocation.type = locationsForm.fieldsValues.type;
      if (insertLocation.regional) insertLocation.regional = locationsForm.fieldsValues.regional;

      insertLocation.latitude = locationsForm.fieldsValues.coord.lat.toString();
      insertLocation.longitude = locationsForm.fieldsValues.coord.lng.toString();
      insertLocation.country = "Brazil";

      // Create a location
      setLoading(true);
      const { data } = await api.post("locations/create", insertLocation);

      if (data.status === "success") {

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertLocation = { ...insertLocation, id_location: data.result.id_location };

        // Update Data table when the create from original module
        if (locations && table && readLocations) {

          if (!locations.length) readLocations();
          else {

            // Add inserted location in the table
            table.reference.row.add(insertLocation);
            table.reference.draw();
          }

          // Search the inserted location and list
          table.reference.search(location.name).draw();
        }

        // Update the action State with created location
        dispatch(LocationsFormActions.setLocationsForm({
          ...locationsForm,
          actions: {
            type: "create",
            response: insertLocation
          }
        }));

        handleCloseModalForm(); // Close modal

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [addToast, table, locations, readLocations, dispatch, locationsForm, handleCloseModalForm, t]);

  /** Update a location
   * @param location Location to update
   */
  const updateLocation = useCallback(async (location: Location) => {

    try {

      const updateLocation: Location = { ...location };

      if (updateLocation.uf) updateLocation.uf = locationsForm.fieldsValues.uf.abbreviation;
      if (updateLocation.type) updateLocation.type = locationsForm.fieldsValues.type;

      updateLocation.latitude = locationsForm.fieldsValues.coord.lat.toString();
      updateLocation.longitude = locationsForm.fieldsValues.coord.lng.toString();
      updateLocation.country = "Brazil";
      updateLocation.regional = locationsForm.fieldsValues.regional;

      // Update a location
      setLoading(true);
      const { data } = await api.patch(`locations/update/${locationsForm.data.id_location}`, updateLocation);

      if (data.status === "success") {

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        // Update the action State with updated location
        dispatch(LocationsFormActions.setLocationsForm({
          ...locationsForm,
          data: Object.assign(locationsForm.data, updateLocation),
          actions: {
            type: "update",
            response: updateLocation
          }
        }));

        // Update Data table when the update from original module
        if (table) {

          // Update row in table with updated location data
          table.reference.row(table.clickedRow).data(locationsForm.data);
          table.reference.draw();

          // Search the inserted location and list
          table.reference.search(location.name).draw();
        }

        handleCloseModalForm(); // Close modal

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [addToast, table, dispatch, locationsForm, handleCloseModalForm, t]);

  /* endregion Functions */
  /* region Effects */
  // Type location options
  useEffect(() => {
    if (!openOptionsTypesLocation) setOptionsTypesLocation([]);
    if (!openOptionsRegionals) setOptionsRegional([]);
  }, [openOptionsTypesLocation, openOptionsRegionals]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsTypesLocation) return undefined;

    (async () => {

      try {

        // Get all vehicles types
        const { data } = await api.get("locations/types/read");

        if (data.status === "success") {
          if (active) setOptionsTypesLocation(data.result);
        } else {
          setOpenOptionsTypesLocation(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      } catch (error: any) {

        setOpenOptionsTypesLocation(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsTypesLocation, addToast, t]);

  useEffect(() => {
    let active = true;

    if (!loadingOptionsRegionals) return undefined;

    (async () => {
      try {
        const { data } = await api.get("regionals/read");

        if (data.status === "success") {
          if (active) {
            const result = data.result.filter((regional) => regional.active);

            setOptionsRegional(result);
          }
        } else {
          setOpenOptionsRegionals(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      } catch (error) {
        setOpenOptionsRegionals(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }
    })();

    return () => { active = false; };

  }, [loadingOptionsRegionals, addToast, t]);

  // Fill the form with data address (When changed)
  useEffect(() => {
    if (!_.isEmpty(locationsForm.googleAddressData)) setTimeout(() => { confirmAddressDataByMap(); }, 0);
  },
  // eslint-disable-next-line
    [locationsForm.googleAddressData]);

  useEffect(() => {
    setRadius(`${locationsForm.data.radius}`);
  }, [locationsForm.data.radius, setRadius]);
  /* endregion Effects */

  return (
    <>
      <Loading loading={loading} />
      <DialogConfirmAction
        open={openDialogConfirmChangeAddressData}
        onClose={() => setOpenDialogConfirmChangeAddressData(false)}
        title={t(LocationsModuleMessages.updateConfirmationTitle)}
        actions={[
          { text: t(GlobalMessages.no), action: () => confirmChangeAddressDataByMap(false) },
          { text: t(GlobalMessages.yes), action: () => confirmChangeAddressDataByMap(true) }
        ]}
        zIndex={2001}
      >
        {t(LocationsModuleMessages.updateConfirmationText)}<br /><br />
        {
          addressConfirmChangeData.map((data) => (
            <div key={data.name}>
              <b>{data.description}: </b>
              <span>{data.oldContent} para {data.newContent}</span>
            </div>
          ))
        }
      </DialogConfirmAction>
      <ContainerModalFormLocation id="modalFormLocation">
        <Dialog
          open={locationsForm.open}
          onClose={handleCloseModalForm}
          scroll="paper"
          container={document.getElementById("modalFormLocation")}
        >
          <DialogTitle className="mHeader">
            <div className="content">
              <div className="title">{
                defineValueAccordingModalType(
                  locationsForm.data.name || t(LocationsModuleMessages.noNameRegistered),
                  locationsForm.data.name || t(LocationsModuleMessages.noNameRegistered),
                  t(LocationsModuleMessages.detailsLocationRegisterTitle)
                )
              }
              </div>
              <div className="subtitle">{
                defineValueAccordingModalType(
                  `${locationsForm.data.address}, ${locationsForm.data.number || t(LocationsModuleMessages.noNumber)}`,
                  `${locationsForm.data.address}, ${locationsForm.data.number || t(LocationsModuleMessages.noNumber)}`,
                  ""
                )
              }
              </div>
            </div>
            <div className="actions">
              <Button
                disableRipple
                onClick={() => setOpenModalMapMarker(true)}
                title={t(LocationsModuleMessages.detailsMapPosition)}
              >
                <MapIcon />
              </Button>
              {
                locationsForm.type === "details"
                && (
                  <Button
                    disableRipple
                    onClick={() => {
                      dispatch(LocationsFormActions.setLocationsForm({ ...locationsForm, type: "update" }));
                      setDisableRegionalField(locationsForm.data.type?.id_location_type !== LocationTypesID.USINA);
                    }}
                    title={t(LocationsModuleMessages.detailsEditLabel)}
                    style={{ marginLeft: "10px" }}
                  >
                    <EditIcon />
                  </Button>
                )
              }
            </div>
          </DialogTitle>
          <DialogContent dividers className="mContent">
            <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData, locationsForm.type)}>
              <DialogContentText tabIndex={-1} component="div">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12} className="checkboxes">
                    <FormControlLabel
                      className="r-checkbox"
                      control={(
                        <Checkbox
                          onChange={(event) => {
                            dispatch(LocationsFormActions.setLocationsForm({
                              ...locationsForm,
                              data: { ...locationsForm.data, active: event.target.checked }
                            }));
                          }}
                          checked={locationsForm.data.active}
                          name="active"
                          disabled={locationsForm.type === "details"}
                        />
                      )}
                      label={t(LocationsModuleMessages.activeLocation)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <TextField
                      className="external_id"
                      label={t(LocationsModuleMessages.externalId)}
                      margin="dense"
                      variant="outlined"
                      name="external_id"
                      helperText={t(LocationsModuleMessages.detailsExternalIdHelper)}
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("external_id").length > 0 ? true : undefined }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {loadingValidateFieldExternalId ? <CircularProgress color="inherit" size={20} /> : null}
                          </>
                        )
                      }}
                      disabled={locationsForm.type === "details" || !user.super_admin}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.external_id, locationsForm.data.external_id, "")}
                      onBlur={() => validations.validateExternalId(formRef.current?.getFieldValue("external_id"))}
                      onChange={() => validations.validateFieldError("external_id")}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                    <Autocomplete
                      open={openOptionsTypesLocation}
                      onOpen={() => setOpenOptionsTypesLocation(true)}
                      onClose={() => setOpenOptionsTypesLocation(false)}
                      getOptionSelected={(option, value) => option.description === value.description}
                      getOptionLabel={(option) => option.description}
                      options={optionsTypesLocation.sort((a, b) => -b.description.localeCompare(a.description))}
                      loading={loadingOptionsTypesLocation}
                      disabled={locationsForm.type === "details"}
                      onChange={(event, value) => {
                        dispatch(LocationsFormActions.setLocationsForm({
                          ...locationsForm,
                          fieldsValues: { ...locationsForm.fieldsValues, type: value }
                        }));
                        validations.validateFieldError("type");
                        setDisableRegionalField(value?.id_location_type !== LocationTypesID.USINA);
                      }}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.type, locationsForm.data.type, "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="type"
                          label={t(LocationsModuleMessages.detailsType)}
                          margin="dense"
                          variant="outlined"
                          helperText={t(GlobalMessages.required)}
                          InputLabelProps={{ shrink: !_.isEmpty(locationsForm.fieldsValues.type) ? true : undefined }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingOptionsTypesLocation ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                    <Autocomplete
                      open={openOptionsRegionals}
                      onOpen={() => setOpenOptionsRegionals(true)}
                      onClose={() => setOpenOptionsRegionals(false)}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option.name}
                      options={optionsRegional.sort((a, b) => -b.name.localeCompare(a.name))}
                      loading={loadingOptionsRegionals}
                      disabled={disableRegionalField}
                      onChange={(event, value) => {
                        dispatch(LocationsFormActions.setLocationsForm({
                          ...locationsForm,
                          fieldsValues: { ...locationsForm.fieldsValues, regional: value }
                        }));
                      }}
                      defaultValue={defineValueAccordingModalType(locationsForm.fieldsValues.regional, locationsForm.fieldsValues.regional, "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="regional"
                          label={t(MenuMessages.regionals)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: !_.isEmpty(locationsForm.fieldsValues.regional) ? true : undefined }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingOptionsRegionals ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <TextField
                      className="radius"
                      label={t(LocationsModuleMessages.detailsRadius)}
                      margin="dense"
                      variant="outlined"
                      name="radius"
                      helperText={t(LocationsModuleMessages.detailsRadiusHelper)}
                      type="number"
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("radius").length > 0 ? true : undefined }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {loadingValidateFieldExternalId ? <CircularProgress color="inherit" size={20} /> : null}
                          </>
                        )
                      }}
                      disabled={locationsForm.type === "details"}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.radius, locationsForm.data.radius, "")}
                      onChange={(evt) => {
                        validations.validateFieldError("radius");
                        setRadius(evt.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      className="name"
                      label={t(LocationsModuleMessages.name)}
                      margin="dense"
                      variant="outlined"
                      name="name"
                      helperText={t(GlobalMessages.required)}
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("name").length > 0 ? true : undefined }}
                      disabled={locationsForm.type === "details"}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.name, locationsForm.data.name, "")}
                      onChange={() => validations.validateFieldError("name")}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className="blockCep">
                    <InputMask
                      mask="99999-999"
                      maskChar=""
                      value={
                        defineValueAccordingModalType(
                          locationsForm.fieldsValues.cep, locationsForm.fieldsValues.cep, locationsForm.fieldsValues.cep
                        )
                      }
                      onChange={(event, value) => {
                        dispatch(LocationsFormActions.setLocationsForm({
                          ...locationsForm,
                          fieldsValues: { ...locationsForm.fieldsValues, cep: value }
                        }));
                        validations.validateFieldError("cep");
                      }}
                      onBlur={() => validations.validateCep(formRef.current?.getFieldValue("cep"))}
                      disabled={locationsForm.type === "details"}
                    >
                      {() => (
                        <TextField
                          className="cep"
                          label={t(LocationsModuleMessages.zipCode)}
                          margin="dense"
                          variant="outlined"
                          name="cep"
                          InputLabelProps={{
                            shrink: !_.isEmpty(locationsForm.fieldsValues.cep) || formRef.current?.getFieldValue("cep").length > 0
                              ? true : undefined }}
                          disabled={locationsForm.type === "details"}
                        />
                      )}
                    </InputMask>
                    <ButtonLoading
                      onClick={() => getDataAddressByCep(formRef.current?.getFieldValue("cep"))}
                      loading={loadingGetAddressByCep}
                      loadingContent={t(LocationsModuleMessages.searching)}
                      disabled={locationsForm.type === "details"}
                    >
                      {t(LocationsModuleMessages.detailsSearchZipCode)}
                    </ButtonLoading>
                  </Grid>
                  <Grid item xs={12} md={9} lg={9}>
                    <TextField
                      className="address"
                      label={t(LocationsModuleMessages.address)}
                      margin="dense"
                      variant="outlined"
                      name="address"
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("address").length > 0 ? true : undefined }}
                      disabled={locationsForm.type === "details"}
                      helperText={t(GlobalMessages.required)}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.address, locationsForm.data.address, "")}
                      onChange={() => validations.validateFieldError("address")}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <TextField
                      className="number"
                      label={t(LocationsModuleMessages.number)}
                      type="number"
                      margin="dense"
                      variant="outlined"
                      name="number"
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("number").length > 0 ? true : undefined }}
                      disabled={locationsForm.type === "details"}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.number, locationsForm.data.number, "")}
                      onChange={() => validations.validateFieldError("number")}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      className="complement"
                      label={t(LocationsModuleMessages.complement)}
                      margin="dense"
                      variant="outlined"
                      name="complement"
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("complement").length > 0 ? true : undefined }}
                      disabled={locationsForm.type === "details"}
                      defaultValue={
                        defineValueAccordingModalType(locationsForm.data.complement, locationsForm.data.complement, "")
                      }
                      onChange={() => validations.validateFieldError("complement")}
                    />
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                    <TextField
                      className="district"
                      label={t(LocationsModuleMessages.district)}
                      margin="dense"
                      variant="outlined"
                      name="district"
                      helperText={t(GlobalMessages.required)}
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("district").length > 0 ? true : undefined }}
                      disabled={locationsForm.type === "details"}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.district, locationsForm.data.district, "")}
                      onChange={() => validations.validateFieldError("district")}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <Autocomplete
                      options={optionsUfLocation}
                      getOptionLabel={(option) => option.description}
                      disabled={locationsForm.type === "details"}
                      value={
                        defineValueAccordingModalType(
                          locationsForm.fieldsValues.uf,
                          locationsForm.fieldsValues.uf,
                          locationsForm.fieldsValues.uf
                        )
                      }
                      onChange={(event, value) => {
                        dispatch(LocationsFormActions.setLocationsForm({
                          ...locationsForm,
                          fieldsValues: { ...locationsForm.fieldsValues, uf: value }
                        }));
                        validations.validateFieldError("uf");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="uf"
                          label={t(LocationsModuleMessages.state)}
                          margin="dense"
                          variant="outlined"
                          helperText={t(GlobalMessages.required)}
                          name="uf"
                          InputLabelProps={{ shrink: !_.isEmpty(locationsForm.fieldsValues.uf) ? true : undefined }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      className="county"
                      label={t(LocationsModuleMessages.county)}
                      margin="dense"
                      variant="outlined"
                      name="county"
                      helperText={t(GlobalMessages.required)}
                      InputLabelProps={{ shrink: formRef.current?.getFieldValue("county").length > 0 ? true : undefined }}
                      disabled={locationsForm.type === "details"}
                      defaultValue={defineValueAccordingModalType(locationsForm.data.county, locationsForm.data.county, "")}
                      onChange={() => validations.validateFieldError("county")}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                      className="finishPoint"
                      control={(
                        <Checkbox
                          onChange={(event) => {
                            dispatch(LocationsFormActions.setLocationsForm({
                              ...locationsForm,
                              data: { ...locationsForm.data, finish_point: event.target.checked }
                            }));
                          }}
                          checked={locationsForm.data.finish_point}
                          name="finish_point"
                          disabled={locationsForm.type === "details"}
                        />
                      )}
                      label={t(LocationsModuleMessages.detailsAllowTravelClosure)}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </Form>
          </DialogContent>
          <DialogActions className="mFooter">
            <div className="actions">
              {
                locationsForm.type === "details"
                  ? <></>
                  : (
                    <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>
                      { locationsForm.type === "register" ? t(GlobalMessages.register) : t(GlobalMessages.save) }
                    </Button>
                  )
              }
            </div>
            <Button
              disableRipple
              onClick={() => { handleCloseModalForm(); setRadius(`${MapConfig.RADIUS}`); }}
              color="primary"
            >
              {t(GlobalMessages.close)}
            </Button>
          </DialogActions>
        </Dialog>
      </ContainerModalFormLocation>
      <ContainerModalMapMarker id="modalMapMarkerLocation">
        <Dialog
          open={openModalMapMarker}
          onClose={() => { setOpenModalMapMarker(false); }}
          scroll="paper"
          container={document.getElementById("modalMapMarkerLocation")}
        >
          <DialogContent dividers className="mContent">
            <DialogContentText tabIndex={-1} component="div">
              <MapAddressDataByMarker
                mapHeight={500}
                type={locationsForm.type}
                returnAddressData={(addressData) => { setAddressData(addressData); }}
                latitude={
                  !_.isEmpty(locationsForm.fieldsValues.coord) ? locationsForm.fieldsValues.coord.lat
                    : locationsForm.data ? Number(locationsForm.data.latitude) : undefined
                }
                longitude={
                  !_.isEmpty(locationsForm.fieldsValues.coord) ? locationsForm.fieldsValues.coord.lng
                    : locationsForm.data ? Number(locationsForm.data.longitude) : undefined
                }
                radius={_.isNaN(radius) ? 0 : Number.parseFloat(radius)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mFooter">
            <div className="actions">
              {
                locationsForm.type !== "details"
                && (
                  <Button
                    disableRipple
                    color="primary"
                    onClick={() => dispatch(LocationsFormActions.setLocationsForm({ ...locationsForm, googleAddressData: addressData }))}
                  >
                    {t(LocationsModuleMessages.mapButtonConfirmLocation)}
                  </Button>
                )
              }
            </div>
            <Button disableRipple onClick={() => setOpenModalMapMarker(false)} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModalMapMarker>
    </>
  );
};

export default ModalFormLocation;
