import { DriverModuleMessages, TranslationDriverModuleMessages } from "../interfaces";

export const messages: TranslationDriverModuleMessages = {

  /* region Frontend messages */
  [DriverModuleMessages.deleteConfirmation]: "Confirmação de exclusão",
  [DriverModuleMessages.deleteConfirmationText]: "Deseja realmente excluir o motorista",
  [DriverModuleMessages.deleteConfirmationHolder]: "Portador do",
  [DriverModuleMessages.detailsTitle]: "Cadastro de motoristas",
  [DriverModuleMessages.detailsActive]: "Motorista ativo",
  [DriverModuleMessages.document]: "CPF",
  [DriverModuleMessages.re]: "RE",
  [DriverModuleMessages.licenseNumber]: "CNH",
  [DriverModuleMessages.licenseExpiredDate]: "Data de expiração da carteira",
  [DriverModuleMessages.detailsDocument]: "Carteira de habilitação",
  [DriverModuleMessages.fullname]: "Nome completo",
  [DriverModuleMessages.detailsAvaiableGroup]: "Disponíveis",
  [DriverModuleMessages.detailsUsedGroup]: "Utilizados",
  [DriverModuleMessages.detailsIButtonCode]: "Código IButton",
  [DriverModuleMessages.iButton]: "IButton",
  [DriverModuleMessages.dataTableExpiredDocument]: "CNH",
  [DriverModuleMessages.invalidCPF]: "CPF inválido",
  [DriverModuleMessages.invalidUniqueCPF]: "CPF já cadastrado",
  [DriverModuleMessages.invalidUniqueRE]: "RE já cadastrado",
  [DriverModuleMessages.formRequiredName]: "O nome é obrigatório",
  [DriverModuleMessages.formIncompleteDocument]: "CPF incompleto",
  [DriverModuleMessages.formRequiredDocument]: "O CPF é obrigatório",
  [DriverModuleMessages.invalidUniqueDescription]: "Descrição é obrigatória",
  [DriverModuleMessages.roleDescription]: "Descrição",
  [DriverModuleMessages.detailsDriverRolesTitle]: "Cargos",
  [DriverModuleMessages.detailsDriverRoleActive]: "Ativo",
  [DriverModuleMessages.newDriversRoles]: "Novo cargo",
  [DriverModuleMessages.invalidUniqueRoleDescription]: "Descrição já cadastrada",
  [DriverModuleMessages.roleRegistrationDate]: "Data do cadastro",
  [DriverModuleMessages.detailsDriverRole]: "Detalhes cargo do motorista",
  [DriverModuleMessages.updateDriverRole]: "Alterar cargo do motorista",
  [DriverModuleMessages.driverRole]: "Cargo do motorista",
  [DriverModuleMessages.typeDriver]: "Tipo de motorista",
  [DriverModuleMessages.companyDriverLabel]: "Próprio",
  [DriverModuleMessages.thirdPartyDriverLabel]: "Terceiro",
  [DriverModuleMessages.driverGroup]: "Grupo do motorista",
  [DriverModuleMessages.driverRegional]: "Regional do motorista",
  [DriverModuleMessages.responsibleEmails]: "E-mail(s) do(s) responsável(eis)",
  [DriverModuleMessages.driverLicenseRestriction]: "Restrições do motorista",
  [DriverModuleMessages.cnhEmissionDate]: "Data emissão da CNH",
  /* endregion Frontend messages */
  /* region Backend messages */
  [DriverModuleMessages.createDriverSuccess]: "Motorista(s) cadastrado(s) com sucesso",
  [DriverModuleMessages.readDriverSuccess]: "Motorista(s) retornado(s) com sucesso",
  [DriverModuleMessages.updateDriverSuccess]: "Motorista alterado com sucesso",
  [DriverModuleMessages.deleteDriverSuccess]: "Motorista excluído com sucesso",
  [DriverModuleMessages.createDriverError]: "Falha ao cadastrar motorista",
  [DriverModuleMessages.readDriverError]: "Falha ao buscar motorista(s)",
  [DriverModuleMessages.noDriverFound]: "Nenhum motorista cadastrado",
  [DriverModuleMessages.driverNotFound]: "Motorista não cadastrado em nosso sistema",
  [DriverModuleMessages.updateDriverError]: "Falha ao editar motorista",
  [DriverModuleMessages.deleteDriverError]: "Falha ao excluir motorista",
  [DriverModuleMessages.createDriverRoleSuccess]: "Cargo dos Motorista cadastrado com sucesso",
  [DriverModuleMessages.createDriverRoleError]: "Falha ao cadastrar cargo de motorista",
  [DriverModuleMessages.updateDriverRoleSuccess]: "Cargo dos Motorista atualizado com sucesso",
  [DriverModuleMessages.updateDriverRoleError]: "Falha ao alterar cargo de motorista",
  [DriverModuleMessages.driverRoleNotFound]: "Cargo do motorista não cadastrado em nosso sistema",
  [DriverModuleMessages.deleteConfirmationDriverRoleText]: "Deseja realmente excluir o cargo de motorista?",
  [DriverModuleMessages.deleteDriverRoleSuccess]: "Cargo de motorista excluído com sucesso",
  [DriverModuleMessages.readDriverRoleSuccess]: "Cargo(s) de Motorista(s) retornado(s) com sucesso",

  /* region Integration messages */
  [DriverModuleMessages.externalIDAlreadyRegistered]: "ID(s) externo(s) já cadastrado(s)",
  [DriverModuleMessages.documentAlreadyRegistered]: "CPF(s) já cadastrado(s)",
  [DriverModuleMessages.REAlreadyRegistered]: "RE(s) já cadastrado(s)",
  [DriverModuleMessages.driverLinkedToATravel]: "Motorista vinculado a uma viagem"
  /* endregion */

  /* endregion Backend messages */
};
