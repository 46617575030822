import { FilterApplicationTypesID } from "../../constants/filter-application-types.enum";
import { ParamNameTypes } from "../../constants/param-name-types.enum";

export enum FilterMessages {
  filtersTitle = "filter.filters",
  noVehicles = "filter.noVehicles",
  noNumDoc = "filter.noNumDoc",
  noVehiclesLinkedToAPlant = "filter.noVehiclesLinkedToAPlant",
  noVehiclesLinkedToARegional = "filter.noVehiclesLinkedToARegional",
  noVehiclesLinkedToAGroup = "filter.noVehiclesLinkedToAGroup",
  noConstruction = "filter.noConstruction",
  noTravelDocs = "filter.noTravelDocs",
  selectSomethingToFilter = "filter.selectSomethingToFilter",
  selectAtLeastOneVehicleType = "filter.selectAtLeastOneVehicleType",
  concreteMixers = "filter.concreteMixers",
  pumpTrucks = "filter.pumpTrucks",
  supportVehicles = "filter.supportVehicles",
  loaders = "filter.loaders",
  clearFilters = "filter.clearFilters",
  applyFilters = "filter.applyFilters",
  filterInstructions = "filter.filterInstructions",

  optionVehicleTypes = "filter.option.vehicleTypes",
  optionNumDoc = "filter.option.numDoc",
  optionStartDate = "filter.option.startDate",
  optionEndDate = "filter.option.endDate",
  optionVehicles = "filter.option.vehicles",
  optionPlants = "filter.option.plants",
  optionConstructions = "filter.option.constructions",
  optionTravelDocs = "filter.option.travelDocs",
  optionClients = "filter.option.clients",
  optionDrivers = "filter.option.drivers",
  optionRegionals = "filter.option.regionals",
  optionGroups = "filter.option.groups",
  optionAlertTypes = "filter.option.alertTypes",
  optionTravels = "filter.option.travels",
  optionVehicleStateTypes = "filter.option.vehicleStateTypes",
  optionReportType = "filter.option.reportType",
  optionUtcOffset = "filter.option.utcOffset",
  optionPumps = "filter.option.pumps",

  applicationVehicleHistory = "filter.application.vehicleHistory",
  applicationAlerts = "filter.application.alerts",
  applicationTravelHistory = "filter.application.travelHistory",
  applicationCoordinateHistory = "filter.application.coordinateHistory",
  applicationVehicleStateHistory = "filter.application.vehicleStateHistory",
  applicationVehicleAnalyticReport = "filter.application.vehicleAnalyticReport",

  dateRangeLabel = "filter.dateRangeLabel",
  filterOptions = "filter.filterOptions",
  enterTextToFilter = "filter.enterTextToFilter",
  selectAll = "filter.selectAll",
  removeAll = "filter.removeAll",
  selectAllFiltered = "filter.selectAllFiltered",
  removeAllFiltered = "filter.removeAllFiltered",
  savedFilters = "filter.savedFilters",
  chooseFilterName = "filter.chooseFilterName",
  filterName = "filter.filterName",
  saveNewFilter = "filter.saveNewFilter",
  saveChanges = "filter.saveChanges",

  // Filter backend messages
  noFilterFound = "filter.noFilterFound",
  readSuccess = "filter.readSuccess",
  readError = "filter.readError",
  createSuccess = "filter.createSuccess",
  createError = "filter.createError",
  updateSuccess = "filter.updateSuccess",
  updateError = "filter.updateError",
  deleteSuccess = "filter.deleteSuccess",
  deleteError = "filter.deleteError"
}

export type TranslationFilterMessages = {
  [key in FilterMessages]: string;
}

export const FilterParamNameTypeMessages = {
  [ParamNameTypes.START_DATE]: FilterMessages.optionStartDate,
  [ParamNameTypes.FINISH_DATE]: FilterMessages.optionEndDate,
  [ParamNameTypes.END_DATE]: FilterMessages.optionEndDate,
  [ParamNameTypes.ID_VEHICLES]: FilterMessages.optionVehicles,
  [ParamNameTypes.ID_VEHICLE_TYPES]: FilterMessages.optionVehicleTypes,
  [ParamNameTypes.ID_DRIVERS]: FilterMessages.optionDrivers,
  [ParamNameTypes.ID_ALERT_TYPES]: FilterMessages.optionAlertTypes,
  [ParamNameTypes.ID_REGIONALS]: FilterMessages.optionRegionals,
  [ParamNameTypes.ID_GROUPS]: FilterMessages.optionGroups,
  [ParamNameTypes.ID_PLANTS]: FilterMessages.optionPlants,
  [ParamNameTypes.ID_TRAVELS]: FilterMessages.optionTravels,
  [ParamNameTypes.ID_VEHICLE_STATE_TYPES]: FilterMessages.optionVehicleStateTypes,
  [ParamNameTypes.REPORT_TYPE]: FilterMessages.optionReportType,
  [ParamNameTypes.CONSTRUCTION_NAMES]: FilterMessages.optionConstructions,
  [ParamNameTypes.CLIENTS]: FilterMessages.optionClients,
  [ParamNameTypes.UTC_OFFSET]: FilterMessages.optionUtcOffset
};

export const FilterApplicationTypeMessages = {
  [FilterApplicationTypesID.TRAVEL_HISTORY]: FilterMessages.applicationTravelHistory,
  [FilterApplicationTypesID.COORDINATE_TRAVEL_HISTORY]: FilterMessages.applicationCoordinateHistory,
  [FilterApplicationTypesID.ALERT_HISTORY]: FilterMessages.applicationAlerts,
  [FilterApplicationTypesID.VEHICLE_HISTORY]: FilterMessages.applicationVehicleHistory,
  [FilterApplicationTypesID.VEHICLE_STATE_HISTORY]: FilterMessages.applicationVehicleStateHistory,
  [FilterApplicationTypesID.VEHICLE_ANALYTIC_REPORT]: FilterMessages.applicationVehicleHistory
};
